import React from 'react';
import { Table, TableHead, TableHeadCell, TableRow, TableCell, TableEmpty } from './table/table';
import FormModalButton from './form_modal_button';
import { ApplicationTestFormModal } from './application_test_form_modal';

export default class SystemPhoneNumbersList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      filteredList: this.props.systemPhoneNumbers,
      loading: false
    };
  }

  onSearch(e) {
    let filteredList = this.props.systemPhoneNumbers.filter((model) => {
      let searchString = e.target.value.toLowerCase();
      return model.number.toLowerCase().indexOf(searchString) > -1
        || (model.carrier?.name || "").toLowerCase().indexOf(searchString) > -1
    });

    this.setState({search: e.target.value, filteredList: filteredList});
  }

  render() {

    return(
      <div>
        <input type="text" placeholder="Search" onChange={this.onSearch.bind(this)} value={this.state.search}
          style={{
            padding: "6px 12px",
            borderRadius: "4px",
            border: "1px solid #d2d6de",
          }}
        />
        <Table sortOn={0} sortDir={"asc"} loading={this.state.loading}>
          <TableHead>
            <TableHeadCell>Number</TableHeadCell>
            <TableHeadCell>Carrier</TableHeadCell>
            <TableHeadCell sortable={false}></TableHeadCell>
          </TableHead>

          <TableEmpty>
            <p>No numbers created yet.</p>
          </TableEmpty>

          {this.state.filteredList.map((phone, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{phone.number}</TableCell>
                <TableCell>{phone.carrier}</TableCell>
                <TableCell>
                <div>
                    {this.props.readOnly ?

                      <FormModalButton
                        type={"Phone Number"}
                        status={"show"}
                        action={`/system_phone_numbers/${phone.id}`}
                      >
                        <SystemPhoneNumberFormModal model={phone} {...this.props} />
                      </FormModalButton>

                    :

                      <div style={{display: "flex", gap: "5px"}}>
                        <FormModalButton
                          type={"Phone Number"}
                          status={"edit"}
                          action={`/system_phone_numbers/${phone.id}`}
                          modalSize="small"
                        >
                          <SystemPhoneNumberFormModal model={phone} {...this.props} />
                        </FormModalButton>


                        <a id={`delete-${phone.id}-system_phone_number-modal`}
                          className="btn btn-sm btn-danger"
                          rel="nofollow"
                          data-method="delete"
                          data-confirm={`Are you sure you want to delete phone number '${phone.number}'?`}
                          href={`/system_phone_numbers/${phone.id}`}
                        >
                          <i className="fa fa-trash"></i>
                        </a>
                      </div>
                    }

                  </div>
                </TableCell>
              </TableRow>
            );
          })}

        </Table>

      </div>
    )
  }
}

export class NewSystemPhoneNumberButton extends React.Component {

  render() {
    return (
      <FormModalButton {...this.props}
        type="Phone Number"
        label="New"
        form_title="New Phone Number"
        action={`/system_phone_numbers`}
        modalSize="small"
      >
        <SystemPhoneNumberFormModal {...this.props} />
      </FormModalButton>
    );
  }
}

export class SystemPhoneNumberFormModal extends React.Component {

  constructor(props) {
    super(props);

    let model = props.model || {}

    this.state = {
      showModal: true,
      number: model.number || "",
      carrier: model.carrier || ""
    }
  }

  handleChange(field, event){
    let update = {};
    update[field] = event.target.value;
    this.setState(update);
  }

  render(){
    let readOnly = this.props.status == "show";

    return (
      <div>
        <label htmlFor="system_phone_number[number]">Number</label>
        <br/>
        <input disabled={readOnly}
                type="text"
                name="system_phone_number[number]"
                className="form-control"
                value={this.state.number || ""}
                onChange={this.handleChange.bind(this, "number")}
                required/>

        <br/>

        <label htmlFor="system_phone_number[carrier]">Carrier</label>
        <br/>
        <input disabled={readOnly}
                type="text"
                name="system_phone_number[carrier]"
                className="form-control"
                value={this.state.carrier || ""}
                onChange={this.handleChange.bind(this, "carrier")}
                required/>

        <br/>

      </div>
    )
  }
}
