import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormLabel from "./components/form-label";
import LoginFlowWrapper from "./components/login-flow-wrapper";
import VigilusButton from "../button/vigilus-button";
import LoginFlowCard from "./components/login-flow-card";

const CheckEmail = ({ authenticity_token }) => {
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    $.ajax({
      method: "POST",
      url: "/users/continue/",
      data: {
        "user[email]": data.email.toLowerCase(),
        authenticity_token: authenticity_token,
        commit: "Continue to login",
      },
      success: function (response) {
        // send user to next step
        location.replace(response.redirect_url);
      },
      error: function (err) {
        console.log("error: ", err);
        if (err.status === 422) {
          location.replace("/");
        } else {
          setError(err.responseText);
        }
      },
    });
  });

  return (
    <LoginFlowWrapper>
      <LoginFlowCard error={error}>
        <form onSubmit={onSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-0">
            <FormLabel label="Email" />
            <input
              type="email"
              name="email"
              className="form-control rounded-md"
              placeholder="Email"
              {...register("email")}
            />
          </div>

          <VigilusButton type="submit" className="py-3 px-4 border-none">
            <span className="w-full text-xl">Continue to login</span>
          </VigilusButton>
        </form>
      </LoginFlowCard>
    </LoginFlowWrapper>
  );
};
export default CheckEmail;
