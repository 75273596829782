import React from "react";
import FormModal from "./form_modal";
import VigilusButton from "./button/vigilus-button";

export const FormContext = React.createContext();

export default class FormModalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.formRef = React.createRef();
  }

  openModal() {
    this.setState({
      open: true,
    });
  }

  closeModal() {
    this.setState({
      open: false,
    });
  }

  render() {
    if (this.props.displayAs === "link") {
      return this.renderAsLink();
    }

    return this.renderAsButton();
  }

  renderAsButton() {
    let buttonText = "New";
    let buttonStyle = "info";
    let status = "new";
    if (this.props.status) {
      status = this.props.status;
    }
    if (this.props.label) {
      buttonText = this.props.label;
    } else if (status === "edit") {
      buttonText = (
        <span>
          Edit
          {this.props.buttonTextAppend ? (
            <span style={{ marginLeft: "5px" }}>
              {this.props.buttonTextAppend}
            </span>
          ) : null}
        </span>
      );
    } else if (status === "show") {
      buttonText = <i className="fa fa-eye"></i>;
    } else if (status === "copy") {
      buttonStyle = "info";
      buttonText = (
        <span>
          {/*<i className="fa fa-copy"></i>*/}
          <span>Copy</span>
        </span>
      );
    }

    return (
      <div style={{ display: "inline-block", ...this.props.style }}>
        <FormModal
          {...this.props}
          status={status}
          open={this.state.open}
          closeFunction={this.closeModal.bind(this)}
          ref={this.formRef}
        >
          <FormContext.Provider value={this.formRef.current}>
            {this.props.children}
          </FormContext.Provider>
        </FormModal>

        <VigilusButton secondary={this.props.secondary || false} id={this.props.id} onClick={this.openModal.bind(this)} className={this.props.className || ""}>{buttonText}</VigilusButton>
        {/*<Button
          className={this.props.className || ""}
          id={this.props.id}
          style={
            this.props.style || {
              backgroundColor: "transparent",
              color: "#405071",
              borderColor: "#405071",
            }
          }
          bsStyle={buttonStyle}
          bsSize="small"
          onClick={this.openModal.bind(this)}
        >
          {buttonText}
        </Button>*/}
      </div>
    );
  }

  renderAsLink() {
    let text = this.props.label || "New";
    return (
      <div style={{ display: "inline-block" }}>
        <FormModal
          {...this.props}
          open={this.state.open}
          closeFunction={this.closeModal.bind(this)}
          ref={this.formRef}
        >
          <FormContext.Provider value={this.formRef.current}>
            {this.props.children}
          </FormContext.Provider>
        </FormModal>

        <a
          style={{ cursor: "pointer" }}
          id={this.props.id}
          onClick={this.openModal.bind(this)}
          href="#"
        >
          {text}
        </a>
      </div>
    );
  }
}
