import React from 'react'
import FormModalButton, { FormContext } from './form_modal_button';
import Select from 'react-select';
import 'daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-daterangepicker-cloverhound';
import "moment";
import moment from "moment-timezone";
import "./billing_subscriptions_form_modal.scss";

export default class NewBillingSubscriptionModalButton extends React.Component {

  render() {
    return (
    <FormModalButton
        type="Subscription"
        status="new"
        label="Add Subscription"
        action={`/billing/subscriptions`}
        modalSize="small"
    >
        <SubscriptionFormModal {...this.props} />
    </FormModalButton>
    );
  }
}

export class SubscriptionItemsCard extends React.Component {
  static contextType = FormContext;

  constructor(props) {
    super(props);
    this.state = {
      items: this.props.subscription && this.props.subscription.items,
      itemsLoading: true,
      usageSummaries: this.props.subscription && this.props.subscription.usage_summaries,
      usageSummariesLoading: true
    }
  }

  componentDidMount() {
    this.getItems();
    this.getUsageSummaries();
  }

  getItems() {
    return $.ajax({
      method: 'GET',
      url: `/billing/subscriptions/${this.props.subscription.id}/items`,
      dataType: 'json',
      success: (items) => {
        this.setState({
          items: items,
          itemsLoading: false
        });
      },
      error: (err) => {
        console.log("Failed to retrieve subscription items:", err);
      }
    });
  }

  getUsageSummaries() {
    return $.ajax({
      method: 'GET',
      url: `/billing/subscriptions/${this.props.subscription.id}/usage`,
      dataType: 'json',
      success: (usageSummaries) => {
        this.setState({
          usageSummaries: usageSummaries,
          usageSummariesLoading: false
        });
      },
      error: (err) => {
        console.log("Failed to retrieve subscription usage summaries:", err);
      }
    });
  }

  render() {

    return (
      <div>
        {this.state.itemsLoading || this.state.usageSummariesLoading ? <div>Loading...</div> :
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Product</th>
                <th>Included</th>
                <th>Added</th>
                <th>Usage</th>
                <th>Remaining</th>
              </tr>
            </thead>
            <tbody>
              {this.state.items.map(item => {
                const usageSummary = this.state.usageSummaries.find(summary => summary.billing_product_id === item.billing_product_id);

                // Rounds up or down to 2 decimal places
                const usedRounded = usageSummary && Math.ceil(usageSummary.quantity_used / 60.0 * 100.0) / 100;
                const remainingRounded = usageSummary && Math.floor(usageSummary.quantity_remaining / 60.0 * 100.0) / 100;

                return (
                  <tr key={item.id}>
                    <td>{item.product.name}</td>
                    <td>{usageSummary ? item.recurring_quantity_included / 60.0 : item.recurring_quantity_included }</td>
                    <td>{usageSummary ? (usageSummary.non_recurring_quantity_added + usageSummary.recurring_quantity_added) / 60.0 : "-"}</td>
                    <td>{usageSummary ? usedRounded : "-"}</td>
                    <td>{usageSummary ? remainingRounded : "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
      </div>
    );
  }
}

export class SubscriptionFormModal extends React.Component {
  static contextType = FormContext;

  constructor(props) {
    super(props);

    this.state = {
      isEditForm: this.props.model && this.props.model.id,
      selectedPlan: null,
      planName: this.props.model && this.props.model.plan && this.props.model.plan.name,
      startsAt: (this.props.model && moment(this.props.model.starts_at).utc()) || moment(),
      expiresAt: (this.props.model && moment(this.props.model.expires_at).utc()) || moment().add(1, "year"),

      availablePlans: [],
      availablePlansLoading: true
    }
  }

  componentDidMount() {
    if (!this.state.selectedPlan && !this.state.isEditForm) {
        this.context && this.context.disable();
    } else {
        this.context && this.context.enable();
    }
    if (!this.state.isEditForm) {
      this.getAvailablePlans();
    }
  }

  getAvailablePlans() {
      return $.ajax({
          method: 'GET',
          url: '/billing/plans',
          dataType: 'json',
          success: (plans) => {
            this.setState({
              availablePlans: plans,
              availablePlansLoading: false
            });
          },
          error: (err) => {
              console.log("Failed to retrieve available plans:", err);
          }
      });
  }

  planDisplay(plan) {
    if (!plan) {
      return "";
    }

    let cycle = "";
    if (plan.pay_as_you_go) {
      cycle = "Pay-as-you-go";
    } else if (plan.billing_interval === "month") {
      cycle = "Monthly";
    } else if (plan.billing_interval === "year") {
      cycle = "Annual";
    }

    return `${plan.display_name} - ${cycle}`;
  }

  render() {
    let selectedPlanOption = this.state.selectedPlan;
    if (this.state.isEditForm) {
      selectedPlanOption = {
        label: this.planDisplay(this.props.model.plan),
        value: this.props.model.plan.name
      }
    }

    return (
      <div>

        {/* Plan */}
        <label htmlFor="plan[name]">Plan</label>
        <br/>
        <Select name="plan[name]"
                isLoading={!this.state.isEditForm && this.state.availablePlansLoading}
                isDisabled={this.state.isEditForm || this.state.availablePlansLoading}
                onChange={(plan) => {
                  this.setState({selectedPlan: plan});
                  this.context.enable();
                }}
                value={selectedPlanOption}
                components={{ IndicatorSeparator: null }} // don't show border next to dropdown arrow
                options={this.state.availablePlans.map(plan => {
                    return {label: this.planDisplay(plan), value: plan.name}
                })}
        />

        <br/>

        {/* Start Date */}
        <div  style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "end"
        }}>
          <label htmlFor="subscription[starts_at]" style={{flexGrow: 1}}>Starts On</label>
          <DateRangePicker
              containerStyles={{float: "none", marginLeft: "30px", }}
              onApply={(event, picker) => this.setState({startsAt: picker.startDate})}
              startDate={this.state.startsAt}
              singleDatePicker={true}
              opens="center"
              locale={{
                  "monthNames": [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December"
                  ],
                  "firstDay": 0
              }}

          >
            <div className="subscription-form__datepicker__range">
                <i style={{verticalAlign: "text-top"}} className="glyphicon glyphicon-calendar fa fa-calendar" />
                <span className="subscription-form__datepicker__text">
                    {this.state.startsAt.format('MM/DD/YYYY')}
                </span>
            </div>
          </DateRangePicker>
          <input type="hidden" name="subscription[starts_at]" value={this.state.startsAt.format('YYYY/MM/DD')} />
        </div>

        <br/>

        {/* End Date */}
        <div  style={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "end"
        }}>
          <label htmlFor="subscription[expires_at]" style={{flexGrow: 1}}>Expires On</label>
          <DateRangePicker
              containerStyles={{float: "none", marginLeft: "30px", }}
              onApply={(event, picker) => this.setState({expiresAt: picker.startDate})}
              startDate={this.state.expiresAt}
              singleDatePicker={true}
              opens="center"
              locale={{
                  "monthNames": [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December"
                  ],
                  "firstDay": 0
              }}
          >
            <div className="subscription-form__datepicker__range">
                <i style={{verticalAlign: "text-top"}} className="glyphicon glyphicon-calendar fa fa-calendar" />
                <span className="subscription-form__datepicker__text">
                    {this.state.expiresAt.format('MM/DD/YYYY')}
                </span>
            </div>
          </DateRangePicker>
          <input type="hidden" name="subscription[expires_at]" value={this.state.expiresAt.format('YYYY/MM/DD')} />

        </div>
      </div>
    );
  }
}

