import React from 'react'

class ApplicationTestBillingModal extends React.Component {

    render(){
        return(
            <div>
                {this.props.billInfo ? <pre>{JSON.stringify(this.props.billInfo, null, 2)}</pre> : "-"}
            </div>
        )
    }
}

export default ApplicationTestBillingModal
