import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormLabel from "./components/form-label";
import LoginFlowLinks from "./components/login-flow-links";
import LoginFlowCard from "./components/login-flow-card";
import LoginFlowWrapper from "./components/login-flow-wrapper";
import VigilusButton from "../button/vigilus-button";

const NewSession = ({ authenticity_token }) => {
  const emailFromUrlParams = new URLSearchParams(window.location.search).get(
    "user[email]"
  );

  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    $.ajax({
      method: "POST",
      url: "/users/sign_in/",
      data: {
        "user[email]": data.email.toLowerCase(),
        "user[password]": data.password,
        "user[remember_me]": data.rememberMe ? 1 : 0,
        authenticity_token: authenticity_token,
        commit: "Login",
      },
      success: function () {
        // send user to root on successful login
        location.replace(`/`);
      },
      error: function (err) {
        setError(err.responseText);
        resetField("password");
      },
    });
  });

  return (
    <LoginFlowWrapper>
      <LoginFlowCard error={error}>
        <form onSubmit={onSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-0">
            <FormLabel label="Email" />
            <input
              type="email"
              name="email"
              className="form-control rounded-md"
              placeholder="email"
              {...register("email", { value: emailFromUrlParams || "" })}
              required
            />
          </div>

          <div className="flex flex-col gap-0">
            <FormLabel label="Password" />
            <input
              type="password"
              name="password"
              className="form-control rounded-md"
              placeholder="password"
              {...register("password")}
              required
              onClick={() => setError(null)}
            />
          </div>
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              name="rememberMe"
              className="rounded-sm p-2"
              placeholder="rememberMe"
              {...register("rememberMe")}
            />
            <FormLabel label="Remember Me" />
          </div>

          <VigilusButton type="submit" className="py-3 px-4 border-none">
            <span className="w-full text-xl">Login</span>
          </VigilusButton>

          <LoginFlowLinks currentView="login" />
        </form>
      </LoginFlowCard>
    </LoginFlowWrapper>
  );
};
export default NewSession;
