import React, { useState } from "react";
import FormLabel from "../../form-label/form-label";

const CallFlowTesterNewTestFormStep1 = ({ sendTo }) => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    sendTo: sendTo || "",
    alarm_severity: "error",
    dead_air_timeout: 12000,
    step_silence_timeout: 12000,
  });

  const handleChange = (field, event) => {
    const value =
      event.target.type === "number" ? +event.target.value : event.target.value;
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-1">
        <FormLabel label="Name" htmlFor="application_test[name]" />
        <input
          type="text"
          name="application_test[name]"
          className="form-control"
          value={formState.name}
          onChange={(e) => handleChange("name", e)}
          placeholder="My new test"
          required
        />
      </div>

      <div className="flex flex-col gap-1">
        <div>
          <FormLabel
            label="Send To Email Address(es)"
            htmlFor="application_test[send_to]"
          />
          <div className="text-gray-600">
            You will receive a notification at this email when your test has
            failed. You can enter up to 5 emails, separated by commas.
          </div>
        </div>
        <input
          type="text"
          name="application_test[send_to]"
          className="form-control"
          value={formState.sendTo}
          onChange={(e) => handleChange("sendTo", e)}
          placeholder="test@example.com"
          required
        />
      </div>

      <div className="flex flex-col gap-1">
        <FormLabel
          label="Severity"
          htmlFor="application_test[alarm_severity]"
        />
        <select
          name="application_test[alarm_severity]"
          value={formState.alarm_severity}
          className="form-control"
          onChange={(e) => handleChange("alarm_severity", e)}
        >
          <option value="error">Error</option>
          <option value="warning">Warning</option>
          <option value="info">Info</option>
        </select>
      </div>

      <div className="flex justify-end">
        <a
          onClick={() => setShowAdvanced(!showAdvanced)}
          className="cursor-pointer"
        >
          {showAdvanced ? "hide advanced options" : "show advanced options"}
        </a>
      </div>

      {showAdvanced && (
        <div className="bg-gray-100 p-4 rounded-md">
          <div className="flex flex-col gap-1">
            <FormLabel
              label="Dead Air Timeout (ms)"
              htmlFor="application_test[dead_air_timeout]"
            />
            <input
              type="number"
              name="application_test[dead_air_timeout]"
              className="form-control"
              value={formState.dead_air_timeout}
              onChange={(e) => handleChange("dead_air_timeout", e)}
              min="3000"
              max="60000"
              required
            />
          </div>

          <div className="flex flex-col gap-1">
            <FormLabel
              label="Step Silence Timeout (ms)"
              htmlFor="application_test[step_silence_timeout]"
            />
            <input
              type="number"
              name="application_test[step_silence_timeout]"
              className="form-control"
              value={formState.step_silence_timeout}
              onChange={(e) => handleChange("step_silence_timeout", e)}
              min="3000"
              max="60000"
              required
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CallFlowTesterNewTestFormStep1;
