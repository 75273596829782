import React, { useState } from 'react';
import Select, { components } from 'react-select';
import { Add, CloseOutlined } from '@material-ui/icons';
import './search_filter_replay_sessions.scss';
import PersonIcon from '../../assets/images/materialicons/person_logo.svg';
import BrowserIcon from '../../assets/images/materialicons/browser_logo.svg';
import KeyIcon from '../../assets/images/materialicons/key_logo.svg';
import LocationIcon from '../../assets/images/materialicons/location_logo.svg';
import MailIcon from '../../assets/images/materialicons/mail_logo.svg';
import TVIcon from '../../assets/images/materialicons/tv_logo.svg';
import WifiIcon from '../../assets/images/materialicons/wifi_logo.svg';

const SearchFilterReplaySessions = ({ onSearchResults }) => {
  const [searchFilters, setSearchFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const handleAddSearchFilter = () => {
    const newFilter = { term: '', operator: 'contains', value: '' };
    setSearchFilters((prevFilters) => [...prevFilters, newFilter]);
    setShowFilters(true);
  };

  const searchTerms = [
    { label: 'Agent', id: 'name', value: 'name', icon: PersonIcon },
    { label: 'Email', id: 'email', value: 'email', icon: MailIcon },
    { label: 'Session ID', id: 'replay_session_id', value: 'replay_session_id', icon: KeyIcon },
    { label: 'Location', id: 'location', value: 'location', icon: LocationIcon },
    { label: 'Browser', id: 'browser', value: 'browser', icon: BrowserIcon },
    { label: 'OS', id: 'os', value: 'os', icon: TVIcon },
    { label: 'ISP', id: 'isp', value: 'isp', icon: WifiIcon },
    // Add more options as needed
  ];

  const Option = (props) => (
    <components.Option {
      ...props}
      data-testid="option">
      <img src={props.data.icon} alt="logo" style={{ width: '40px', height: '18px' }} />
      <span id={`filter-option-${props.data.id}`}>{props.data.label}</span>
    </components.Option>
  );

  const handleSearch = () => {
    let results = performSearchLogic(searchFilters);
    onSearchResults(results);
  };

  const performSearchLogic = (search_filters) => {
    return [search_filters];
  };

  const handleRemoveSearchFilter = (index) => {
    setSearchFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
    setShowFilters(false); // Reset filter visibility
  };

  const handleFilterChange = (event, index, field) => {
    const { value } = field === 'term' ? event : event.target;

    setSearchFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index][field] = value;
      return updatedFilters;
    });
  };

  return (
    <div>
      <div>
        <button id="add-filters" className="btn-add iconButtonStyle" onClick={handleAddSearchFilter}>
          <Add fontSize="large" />
        </button>
        {searchFilters.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th></th>
                <th data-testid="category-header">Category</th>
                <th>Operand</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {searchFilters.map((filter, index) => (
                <tr key={index}>
                  <td>
                    <button
                      className="btn-delete iconButtonStyle"
                      onClick={() => handleRemoveSearchFilter(index)}
                    >
                      <CloseOutlined fontSize="large" />
                    </button>
                  </td>
                  <td>
                    <Select
                      id="category"
                      className="modal-category"
                      options={searchTerms}
                      onChange={(e) => handleFilterChange(e, index, 'term')}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: 'black',
                          height: 32,
                          minHeight: 32,
                          marginTop: -3,
                        }),
                        option: (base) => ({
                          ...base,
                          height: 40,
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }),
                        clearIndicator: (base) => ({
                          ...base,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }),
                      }}
                      components={{
                        IndicatorSeparator: null,
                        Option,
                      }}
                    />
                  </td>
                  <td>
                    <select
                      data-testid={`modal-operand`}
                      className="modal-operand"
                      value={filter.operator}
                      onChange={(e) => handleFilterChange(e, index, 'operator')}
                    >
                      <option value="contains">Contains</option>
                      <option value="equals">Equals</option>
                      <option value="starts-with">Starts With</option>
                      <option value="ends-with">Ends With</option>
                    </select>
                  </td>
                  <td>
                    <input
                      id={`filter-value-${index}`}
                      className="value-layout"
                      type="text"
                      placeholder="Set a value"
                      value={filter.value}
                      onChange={(e) => handleFilterChange(e, index, 'value')}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Press the + button to add new filters</p>
        )}
        {searchFilters.length !== 0 ? (
          <button className="btn-search" onClick={handleSearch}>
            {' '}
            <i className="search">Search</i>
          </button>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};

export default SearchFilterReplaySessions;
