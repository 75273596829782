import React from "react";

const VigilusButton = ({
  children,
  secondary = false,
  type = "button",
  onClick,
  id,
  className,
  disabled,
}) => {
  return (
    <button
      id={id}
      type={type}
      onClick={onClick}
      className={`py-2 px-4 text-lg rounded-md text-primary flex items-center gap-2 text-center font-medium ${
        secondary
          ? "bg-white hover:bg-gray-200 border border-primary"
          : "text-white bg-button border-none"
      } ${className ? className : ""} ${
        disabled
          ? "opacity-50 cursor-not-allowed"
          : " hover:bg-button-600 active:outline active:outline-offset-1 active:outline-2 active:outline-button"
      }`}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
export default VigilusButton;
