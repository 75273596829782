import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { PoweredByStripeSubtext } from "../../../stripe/powered-by-stripe-subtext";
import { StripeFormLoader } from "../../../stripe/stripe-form-loader";
import VigilusButton from "../../../button/vigilus-button";

const apiKey =
  window.STRIPE_PUBLISHABLE_KEY ||
  `${window.RAILS_ENV_VARIABLES.STRIPE_PUBLISHABLE_KEY}`;

const stripePromise = loadStripe(apiKey);

const AddPaymentMethodForm = ({ authenticity_token, onSuccess, customer }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const paymentElementOptions = {
    layout: "tabs",
    business: {
      name: "Cloverhound, Inc.",
    },
  };

  // step 1
  const handleCreateStripeSetupIntent = async () => {
    try {
      const response = await fetch("/create-payment-intent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          authenticity_token: authenticity_token,
        }),
      });
      const { client_secret } = await response.json();
      setClientSecret(client_secret);
    } catch (error) {
      console.error("Error creating setup intent:", error);
    }
  };

  // step 2
  const handleSavePaymentMethod = async (stripe, elements, event) => {
    setDisabled(true);
    try {
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location}?success`,
        },
        redirect: "if_required",
      });
      handleCreateCustomer(result.setupIntent.payment_method);
      console.log(
        "Successfully added payment method and created customer and user!"
      );
    } catch (error) {
      console.error(error.message);
      setDisabled(false);
    }
  };

  // step 3
  const handleCreateCustomer = async (paymentMethod) => {
    try {
      const response = await $.ajax({
        method: "POST",
        url: "/create-customer", // TODO show success/confirmation whatever
        contentType: "application/json",
        data: JSON.stringify({
          user: {
            first_name: customer.firstName,
            last_name: customer.lastName,
            email: customer.email,
            organization_name: customer.orgName,
          },
          payment_method: paymentMethod,
          authenticity_token: authenticity_token,
        }),
      });
      const customerResponse = response.customer;
      console.log(customerResponse);
      onSuccess()
    } catch (error) {
      console.error("Error creating customer:", error);
    }
  };

  useEffect(() => {
    if (!clientSecret) {
      handleCreateStripeSetupIntent();
    }
  }, [authenticity_token]);

  return (
    <div className="w-full" style={{ minHeight: "400px" }}>
      {clientSecret && (
        <div
          style={{
            visibility: loading ? "hidden" : "visible",
            height: loading ? "0px" : "auto",
          }}
        >
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <ElementsConsumer>
              {({ stripe, elements }) => (
                <div className="flex flex-col gap-2 w-full">
                  <PaymentElement
                    id="payment-element"
                    options={paymentElementOptions}
                    onReady={() => {
                      setLoading(false);
                    }}
                  />
                  <div
                    className="w-full"
                    style={{
                      opacity: loading ? 0 : disabled ? 0.5 : 1,
                    }}
                  >
                    <VigilusButton
                      type="button"
                      className="py-3 px-4 border-none w-full"
                      onClick={() => handleSavePaymentMethod(stripe, elements)}
                      disabled={disabled}
                    >
                      <span className="w-full text-xl">
                        Complete Verification and Create Account
                      </span>
                    </VigilusButton>

                    <PoweredByStripeSubtext />
                  </div>
                </div>
              )}
            </ElementsConsumer>
          </Elements>
        </div>
      )}

      {loading && <StripeFormLoader />}
    </div>
  );
};

export default AddPaymentMethodForm;
