import React from "react";
import { BeatLoader } from "react-spinners";

export function StripeFormLoader() {
  return (
    <div style={{ display: "flex", height: "400px" }}>
      <BeatLoader
        style={{ margin: "120px auto" }}
        color="#0470de"
        width="30px"
        size={10}
        margin="4px"
      />
    </div>
  );
}
