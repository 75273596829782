import React from "react";

const Toast = ({ duration = 1000, onClose, children }) => {
  const closeToast = () => {
    setTimeout(() => {
      onClose();
    }, duration);
  };

  closeToast();

  return (
    <div className="absolute left-0 top-32 z-[1060] w-screen flex items-center justify-center">
      <div className="bg-black text-white py-1 px-2 rounded-md flex items-center gap-4 justify-between">
        {children}
        <button
          className="bg-transparent border-none p-0"
          onClick={onClose}
          title="close"
        >
          <i className="fas fa-times text-gray-300 hover:text-white transition-all duration-100"></i>
        </button>
      </div>
    </div>
  );
};

export default Toast;
