import React, { useState } from "react";
import LoginFlowWrapper from "../components/login-flow-wrapper";
import UnlockSuccess from "./unlock-success";
import UnlockForm from "./unlock-form";

const UnlockContainer = ({ authenticity_token }) => {
  const [view, setView] = useState("initial"); // initial || success || error

  const handleSuccessfulUnlock = () => {
    setView("success");
  };

  const handleResetView = () => {
    setView("initial");
  };

  return (
    <LoginFlowWrapper>
      {view === "initial" && (
        <UnlockForm
          authenticity_token={authenticity_token}
          onSuccess={handleSuccessfulUnlock}
        />
      )}
      {view === "success" && <UnlockSuccess onResend={handleResetView} />}
    </LoginFlowWrapper>
  );
};
export default UnlockContainer;
