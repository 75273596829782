import React from 'react';
import FormModalButton from './form_modal_button';
import { SubscriptionItemsCard, SubscriptionFormModal } from './billing_subscriptions_form_modal';
import BillingPaymentMethodsList from './billing_payment_methods_list';
import BillingTransactionsList from './billing_transactions_list';
import BillingPurchaseMinutesFormModal from './billing_purchase_minutes_form_modal';
import Card from './card';
import "./billing_subscriptions.scss";

export default class BillingSubscriptionsList extends React.Component {

  render() {
    return(
      <div className="subscriptions__container">
        {this.props.subscription == null ?
          <div>
            <p>No subscriptions found</p>
          </div>
        :
          <div className="subscriptions__card-container">

            <Card className="subscriptions__card" id="current-balance-card">
              <CurrentBalanceInfo {...this.props} />
            </Card>

            <Card className="subscriptions__card" id="current-plan-card">
              <CurrentPlanInfo {...this.props} />
            </Card>

            {this.props.can_manage_payment_methods && this.props.account?.self_serve &&
              <Card className="subscriptions__card" id="payment-methods-card">
                <BillingPaymentMethodsList {...this.props} readOnly={!this.props.can_manage_payment_methods} />
              </Card>
            }

            {this.props.can_view_transactions && this.props.account?.self_serve &&
              <Card className="subscriptions__card" id="transactions-card">
                <BillingTransactionsList {...this.props} />
              </Card>
            }

          </div>
        }
      </div>
    );
  }
}

class CurrentPlanInfo extends React.Component {
  render() {
    const subscription = this.props.subscription;
    let cycle = ""
    if (subscription.plan.pay_as_you_go) {
      cycle = "Pay-as-you-go"
    } else if (subscription.plan.billing_interval == "month") {
      cycle = "Monthly"
    } else if (subscription.plan.billing_interval == "year") {
      cycle = "Annual"
    }

    return (
      <div className="" style={{display: "flex", flexDirection: "column", gap: "20px"}}>
        <div>
          <h4>Current Plan</h4>
          <div style={{fontSize: "3rem"}}>{subscription.plan.display_name} - {cycle}</div>
          <div>{subscription.plan.description}</div>
        </div>

        <div style={{
          display: "grid",
          columnGap: "10px",
          gridTemplateColumns: "auto 1fr"
        }}>
            <label>Started On:</label>
            <span>{subscription.starts_at_date}</span>

            <label>Current Period:</label>
            <span>{subscription.period_start_date} — {subscription.period_end_date}</span>

            {!subscription.plan.pay_as_you_go &&
              <>
                <label>Next Bill Date:</label>
                <span>{subscription.next_bill_date}</span>

                <label>Expires On:</label>
                <span>{subscription.expires_at_date}</span>
              </>
            }
        </div>

        {this.props.master_admin &&
          <div style={{
            display: "flex",
            gap: "8px"
          }}>
            <FormModalButton
              type="Subscription"
              status="edit"
              label="Edit"
              action={`/billing/subscriptions/${subscription.id}}`}
              displayAs="link"
              modalSize="small"
            >
              <SubscriptionFormModal model={subscription} />
            </FormModalButton>

            <span>
              |
            </span>

            <a href={`/billing/subscriptions/${subscription.id}`}
                data-confirm={`Are you sure you want to delete the subscription to "${subscription.plan.display_name}"?`}
                data-method="delete"
                rel="nofollow"
                style={{cursor: "pointer"}}
            >
              Delete
            </a>
          </div>
        }
      </div>
    );
  }
}

class CurrentBalanceInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: this.props.subscription && this.props.subscription.items,
      itemsLoading: true,
      usageSummaries: this.props.subscription && this.props.subscription.usage_summaries,
      usageSummariesLoading: true
    }
  }

  componentDidMount() {
    this.getItems();
    this.getUsageSummaries();
  }

  getItems() {
    return $.ajax({
      method: 'GET',
      url: `/billing/subscriptions/${this.props.subscription.id}/items`,
      dataType: 'json',
      success: (items) => {
        this.setState({
          items: items,
          itemsLoading: false
        });
      },
      error: (err) => {
        console.log("Failed to retrieve subscription items:", err);
      }
    });
  }

  refresh() {
    this.setState({
      itemsLoading: true,
      usageSummariesLoading: true
    }, () => {
      this.getItems();
      this.getUsageSummaries();
    });
  }

  getUsageSummaries() {
    return $.ajax({
      method: 'GET',
      url: `/billing/subscriptions/${this.props.subscription.id}/usage`,
      dataType: 'json',
      success: (usageSummaries) => {
        this.setState({
          usageSummaries: usageSummaries,
          usageSummariesLoading: false
        });
      },
      error: (err) => {
        console.log("Failed to retrieve subscription usage summaries:", err);
      }
    });
  }

  calculateMinutesBalance() {
    if (!this.state.items || !this.state.usageSummaries) {
      return null;
    }

    let minimumBalance = null;
    this.state.items?.forEach(item => {
      const usageSummary = this.state.usageSummaries.find(summary => summary.billing_product_id === item.billing_product_id);
      if (usageSummary) {
        if (usageSummary.quantity_remaining < minimumBalance || minimumBalance === null) {
          minimumBalance = usageSummary.quantity_remaining;
        }
      }
    });

    return Math.floor(minimumBalance / 60.0);
  }

  calculateMinutesUsed() {
    if (!this.state.items || !this.state.usageSummaries) {
      return null;
    }

    let maximumUsed = null;
    this.state.items?.forEach(item => {
      const usageSummary = this.state.usageSummaries.find(summary => summary.billing_product_id === item.billing_product_id);
      if (usageSummary) {
        if (usageSummary.quantity_used > maximumUsed || maximumUsed === null) {
          maximumUsed = usageSummary.quantity_used;
        }
      }
    });

    return Math.ceil(maximumUsed / 60.0);
  }

  render() {
    const balance = this.calculateMinutesBalance();
    const used = this.calculateMinutesUsed();
    const cycle = this.props.subscription.plan.billing_interval == "month" ? "Monthly" : "Annual";

    return (
      <div className="balance-card__container">
        {this.state.itemsLoading || this.state.usageSummariesLoading ?
          <div>Loading...</div>
          :
          <div className="balance-card__content">
            <div>
              <div>
                <h4 style={{display: "inline-block", verticalAlign: "middle"}}>Current Balance</h4>
                <button className="header-button balance-card__header-button" onClick={() => { this.refresh() }}>
                  <i className="fa fa-sync" />
                </button>
              </div>
              {balance > 0 ?
                <span style={{fontSize: "3rem"}}>+{balance} Minute{balance > 1 && "s"}</span>
                :
                <span style={{fontSize: "3rem", color: "red"}}>{balance} Minute{balance != -1 && "s"}</span>
              }

              {!this.props.readOnly && this.props.account?.self_serve &&
                <FormModalButton
                      className="balance-card__cta"
                      style={{
                        marginLeft: "15px",
                        verticalAlign: "bottom"
                      }}
                      type={`Purchase Minutes`}
                      status="new"
                      action={`/billing/subscriptions/${this.props.subscription.id}/purchase_minutes`}
                      label="Add Minutes"
                      form_title="Purchase Minutes"
                      form_button_label="Complete Purchase"
                      modalSize="small"
                  >
                    <BillingPurchaseMinutesFormModal {...this.props} />
                </FormModalButton>
              }

            </div>

            <div>
              <h4>Used This Period</h4>
              <span style={{fontSize: "2rem"}}>{used} Minute{used != 1 && "s"}</span>
            </div>

            {this.props.master_admin &&
              <div className="balance-card__admin-controls">
                <FormModalButton
                    type={`Credit Minutes`}
                    status="new"
                    action={`/billing/subscriptions/${this.props.subscription.id}/admin_add_minutes`}
                    label="Credit Minutes"
                    form_title="Credit Minutes"
                    form_button_label="Submit"
                    displayAs="link"
                    modalSize="small"
                    id="credit-minutes-link"
                >
                  <div>
                    <label for="minutes">Minutes</label>
                    <input className="form-control" type="number" name="minutes" required={true} min="1"></input>
                  </div>
                </FormModalButton>

                <span>
                  |
                </span>

                <FormModalButton
                    type={`Subscription Items for: ${this.props.subscription.plan.display_name} - ${cycle}`}
                    status="show"
                    label="Itemized Usage"
                    readOnly={true}
                    displayAs="link"
                    modalSize="medium"
                >
                    <SubscriptionItemsCard subscription={this.props.subscription} />
                </FormModalButton>

              </div>
            }
          </div>
        }
      </div>
    );
  }
}
