import React from "react"
import FormModalButton from "./form_modal_button";
import ApplicationTestBillingModal from "../packs/modals/application_test_billing_modal";

class ApplicationTestBillingModalButton extends React.Component {
  render () {
    return (
      <FormModalButton
        label="Billing Info"
        readOnly={true}
        status={"show"}
        form_title={"Total Usage Info"}
        billInfoModal={true}
        displayAs={"link"}
      >
        <ApplicationTestBillingModal {...this.props}/>
      </FormModalButton>
    );
  }
}

export default ApplicationTestBillingModalButton
