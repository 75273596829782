// from:
// https://heroicons.dev/

import React from 'react'

export class AdjustmentsIcon extends React.Component {

    render() {
        return <IconWrapper>
                <svg style={this.props.style} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
            </svg>
        </IconWrapper>
    }
}

export class DocumentDownloadIcon extends React.Component {
    render() {
        return <IconWrapper>
            <svg style={this.props.style} className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" /></svg>
        </IconWrapper>
    }
}

export class CalendarIcon extends React.Component {
    render() {
        return <IconWrapper>
            <svg style={this.props.style} className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>
        </IconWrapper>
    }
}

export class EarIcon extends React.Component {
    render() {
        return <IconWrapper>
            <svg style={this.props.style} viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="m18 30v-2a10.0114 10.0114 0 0 0 10-10h2a12.0134 12.0134 0 0 1 -12 12z"/>
                <path d="m18 26v-2a6.0066 6.0066 0 0 0 6-6h2a8.0092 8.0092 0 0 1 -8 8z"/>
                <path d="m18 22v-2a2.0023 2.0023 0 0 0 2-2h2a4.0042 4.0042 0 0 1 -4 4z"/>
                <path d="m10 2a9.01 9.01 0 0 0 -9 9h2a7 7 0 0 1 14 0 7.09 7.09 0 0 1 -3.501 6.1348l-.499.2881v3.0732a2.9354 2.9354 0 0 1 -.9009 2.1514 4.1824 4.1824 0 0 1 -4.6318 1.03 4.0918 4.0918 0 0 1 -2.4673-3.6775h-2a6.1156 6.1156 0 0 0 3.6694 5.5117 5.7822 5.7822 0 0 0 2.3145.4863 6.5854 6.5854 0 0 0 4.4785-1.888 4.94 4.94 0 0 0 1.5376-3.6139v-1.9424a9.1077 9.1077 0 0 0 4-7.5537 9.01 9.01 0 0 0 -9-9z"/>
                <path d="m9.28 8.0825a3.0061 3.0061 0 0 1 3.72 2.9175h2a4.9786 4.9786 0 0 0 -1.8843-3.9111 5.0414 5.0414 0 0 0 -4.2807-.9566 4.95 4.95 0 0 0 -3.7027 3.7027 5.0318 5.0318 0 0 0 2.3037 5.4585 3.0777 3.0777 0 0 1 1.564 2.6294v2.0771h2v-2.0771a5.0608 5.0608 0 0 0 -2.5371-4.3458 3.0016 3.0016 0 0 1 .8171-5.4946z"/>
                <path d="m0 0h32v32h-32z" fill="none"/>
            </svg>
        </IconWrapper>
    }
}

export class TouchIcon extends React.Component {
    render() {
        return <IconWrapper>
            <svg style={this.props.style} preserveAspectRatio="none" fill="currentColor" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path xmlns="http://www.w3.org/2000/svg" d="M11.525 2q1.85 0 3.175 1.312 1.325 1.313 1.325 3.188 0 1.375-.762 2.488Q14.5 10.1 13.225 10.6h-.2V9.575q.9-.4 1.45-1.212.55-.813.55-1.863 0-1.425-1.037-2.463Q12.95 3 11.525 3T9.062 4.037Q8.025 5.075 8.025 6.5q0 1.05.563 1.887.562.838 1.437 1.213v1.125q-1.3-.45-2.15-1.588Q7.025 8 7.025 6.5q0-1.875 1.325-3.188Q9.675 2 11.525 2Zm-.95 19q-.2 0-.4-.087-.2-.088-.35-.238L5.9 16.8q-.175-.2-.187-.512-.013-.313.187-.613.225-.3.475-.4.25-.1.55-.025l3.1.625V6.5q0-.625.438-1.062Q10.9 5 11.525 5t1.063.438q.437.437.437 1.062v5.075h.95q.125 0 .213.025.087.025.162.075l3.2 1.575q.7.325 1.012 1.075.313.75.163 1.525l-.75 4.35q-.075.35-.35.575-.275.225-.65.225Zm0-1h6.4l.75-4.325q.1-.425-.025-.863-.125-.437-.575-.637l-3.35-1.675h-1.75v-6q0-.225-.138-.363Q11.75 6 11.525 6q-.225 0-.362.137-.138.138-.138.363v10.6l-4.25-.85Zm6.4 0h-6.4 6.4Z"></path>
            </svg>
        </IconWrapper>
    }
}

export class SpeakIcon extends React.Component {
    render() {
        return <IconWrapper>
            <svg style={this.props.style} preserveAspectRatio="none" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.25 14.875q-.225-.225-.225-.538 0-.312.2-.562.825-.975 1.3-2.2Q21 10.35 21 9t-.475-2.563q-.475-1.212-1.3-2.162-.2-.275-.212-.587-.013-.313.237-.538.25-.275.613-.275.362 0 .562.275 1.05 1.2 1.637 2.687.588 1.488.588 3.163 0 1.675-.588 3.162-.587 1.488-1.637 2.688-.225.275-.575.275t-.6-.25ZM15.975 11.6q-.225-.225-.25-.538-.025-.312.175-.637.2-.325.312-.687.113-.363.113-.738 0-.4-.113-.75-.112-.35-.312-.675-.2-.325-.175-.65.025-.325.25-.525.25-.25.612-.25.363 0 .563.3.4.525.638 1.175.237.65.237 1.375t-.237 1.375q-.238.65-.638 1.175-.2.3-.563.3-.362 0-.612-.25Zm-7.125.9q-1.45 0-2.475-1.025Q5.35 10.45 5.35 9q0-1.45 1.025-2.475Q7.4 5.5 8.85 5.5q1.45 0 2.475 1.025Q12.35 7.55 12.35 9q0 1.45-1.025 2.475Q10.3 12.5 8.85 12.5Zm-6.6 7.625q-.375 0-.637-.262-.263-.263-.263-.663v-1.3q0-.775.388-1.388.387-.612 1.087-.937 1.25-.625 2.788-1.038 1.537-.412 3.237-.412 1.7 0 3.25.412 1.55.413 2.8 1.038.675.325 1.063.937.387.613.387 1.388v1.3q0 .4-.262.663-.263.262-.638.262Zm.6-1.5h12V17.9q0-.35-.187-.6-.188-.25-.463-.4-.925-.45-2.337-.862-1.413-.413-3.013-.413-1.6 0-3.012.413-1.413.412-2.338.862-.275.15-.462.4-.188.25-.188.6Zm6-7.625q.825 0 1.413-.588.587-.587.587-1.412t-.587-1.413Q9.675 7 8.85 7q-.825 0-1.412.587Q6.85 8.175 6.85 9q0 .825.588 1.412Q8.025 11 8.85 11Zm0-2Zm0 9.625Z"/>
            </svg>
        </IconWrapper>
    }
}

export class HangUpIcon extends React.Component {
    render() {
        return <IconWrapper>
            <svg style={this.props.style} preserveAspectRatio="none" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 8q2.95 0 5.812 1.188 2.863 1.187 5.088 3.562.3.3.3.7 0 .4-.3.7l-2.3 2.25q-.275.275-.637.3-.363.025-.663-.2l-2.9-2.2q-.2-.15-.3-.35-.1-.2-.1-.45v-2.85q-.95-.3-1.95-.475T12 10q-1.05 0-2.05.175-1 .175-1.95.475v2.85q0 .25-.1.45t-.3.35l-2.9 2.2q-.3.225-.662.2-.363-.025-.638-.3l-2.3-2.25q-.3-.3-.3-.7 0-.4.3-.7 2.2-2.375 5.075-3.562Q9.05 8 12 8Z"/>
            </svg>
        </IconWrapper>
    }
}

export class MoveDownIcon extends React.Component {
    render() {
        return <IconWrapper>
            <svg style={this.props.style} preserveAspectRatio="none" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="m7 21-1.4-1.4 1.575-1.65q-2.625-.3-4.4-2.275Q1 13.7 1 11q0-2.925 2.038-4.963Q5.075 4 8 4h3v2H8Q5.925 6 4.463 7.462 3 8.925 3 11q0 1.85 1.162 3.2 1.163 1.35 2.913 1.7L5.6 14.425 7 13l4 4Zm6-1v-7h9v7Zm0-9V4h9v7Zm2-2h5V6h-5Z"/>
            </svg>
        </IconWrapper>
    }
}

export class DropDownArrowIcon extends React.Component {
    render() {
        return <IconWrapper style={{display: "flex"}}>
            <svg height="20" width="20" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
        </IconWrapper>
    }
}

export class IconWrapper extends React.Component {
    render() {
        return <span style={{
            marginTop: "-1px",
            display: "inline-block",
            verticalAlign: "text-top",
            ...this.props.style
        }}>
            {this.props.children}
        </span>
    }
}
