import React from "react";

const LoginFlowLinks = ({ currentView }) => {
  return (
    <div className="flex flex-col gap-0">
      {currentView !== "login" && (
        <a href="/users/sign_in" className="text-primary">
          Login
        </a>
      )}

      {currentView !== "new-password" && (
        <a href="/users/password/new" className="text-primary">
          Forgot your password?
        </a>
      )}
      {currentView !== "unlock" && (
        <a href="/users/unlock" className="text-primary">
          Didn't receive unlock instructions?
        </a>
      )}
      {currentView !== "new-confirmation" && (
        <a href="/users/confirmation/new" className="text-primary">
          Didn't receive confirmation instructions?
        </a>
      )}
    </div>
  );
};
export default LoginFlowLinks;
