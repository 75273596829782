import React, { useRef } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import VigilusChartContainer from './vigilus_chart_container';
import DatePickerManager from './date_picker_manager';
import "moment";
import moment from "moment-timezone";

class MetricsCharts extends React.Component {

    constructor(props) {
        super(props);

        let now = moment();

        this.state = {
            now: now,
            startDate: now.clone().startOf('day'),
            endDate: now.clone().endOf('day'),
            showThresholdIndicators: true
        }

        this.reports = [];
    }

    syncState(childState) {
        this.setState({
            now: childState.now,
            startDate: childState.startDate,
            endDate: childState.endDate
        });
    }

    callUpdate(childState) {
        this.setState({
            now: childState.now,
            startDate: childState.startDate,
            endDate: childState.endDate
        }, () => {
            let reports_proxy = [];
            for (let i=0; i<this.reports.length; i++) {
                if (this.reports[i]._isMounted) {
                    this.reports[i].update();
                    reports_proxy.push(this.reports[i]);
                }
            }

            this.reports = reports_proxy;
        });
    }

    subscribeToUpdate(containerObj) {
        this.reports.push(containerObj);
    }

    render() {
        var charts = this.props.device_metrics.map(chart => {
            return <VigilusChartContainer subscribe={this.subscribeToUpdate.bind(this)} key={chart.containerId}  {...chart} {...this.state} />
        });

        return (
            <div>
                {FeatureFlags.thresholds_in_charts &&
                    <label style={{ paddingLeft: "15px", marginTop: "-20px", display: "block" }}>
                        <input
                            type="checkbox"
                            style={{ marginRight: "5px", verticalAlign: "top" }}
                            checked={this.state.showThresholdIndicators}
                            onChange={event => {
                                this.setState({
                                    showThresholdIndicators: event.target.checked
                                });
                            }}
                        />
                        Show Threshold Indicators
                    </label>
                }   
                <DatePickerManager callUpdate={this.callUpdate.bind(this)} syncState={this.syncState.bind(this)} />
                <button
                    className="btn btn-sm btn-info"
                    style={{
                        float: "right",
                        paddingBottom: "7px",
                        paddingTop: "6px",
                        marginRight: "12px",
                        marginTop: "-30px"
                    }}
                    onClick={() => window.exportDeviceData(this)}
                >
                    <i className="fas fa-download" style={{marginRight: "6px"}}></i>
                    Export
                </button>
                {charts}
            </div>
        )
    }
}

export default MetricsCharts;
