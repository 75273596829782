import React from "react";

const SimpleSearchBar = ({ query, onChange }) => {
  return (
    <div className="w-[32rem] flex items-center gap-4 border-bottom border-border-700">
      <i className="fas fa-search"></i>
      <input
        value={query}
        onChange={onChange}
        className="border-none outline-none text-xl w-full py-2"
        style={{ border: "none" }}
        placeholder="Search"
      />
    </div>
  );
};

export default SimpleSearchBar;
