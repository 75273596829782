import React from "react";
import ToggleButton from 'react-toggle-button'

class ShowGroupToggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show
    };
  }

  render() {
    let width = "90px";
    let height = 30;
    let thumbHeight = 30 - 6;
    let activeColor = "#0073b7";

    return (<div id={this.props.id} style={{ display: "inline-block" }}>
      <ToggleButton
        id={this.props.id}

        value={this.state.show || false}

        activeLabel={this.props.activeLabel ? this.props.activeLabel : ''}
        inactiveLabel={this.props.inactiveLabel ? this.props.inactiveLabel : ''}

        colors={{
          active: {
            base: activeColor
          }
        }}

        containerStyle={{
          display: 'inline-block',
          width: width
        }}

        thumbStyle={{
          borderRadius: 20,
          width: '40px',
          height: thumbHeight,
          borderColor: 'transparent',
          boxShadow: 'none'
        }}
        trackStyle={{
          width: width,
          height: height,
          borderRadius: 20
        }}
        thumbAnimateRange={[3, 47]}

        activeLabelStyle={{ width: '50px', fontWeight: 'bold' }}
        inactiveLabelStyle={{ width: '50px', fontWeight: 'bold' }}

        onToggle={(value) => {
          this.setState({
            show: !value
          })

          if (this.props.onChanged) {
            this.props.onChanged(!value);
          }
        }} />
    </div>)
  }
}
export default ShowGroupToggle;
