import React from "react";
import LoginFlowCard from "../components/login-flow-card";
import VigilusButton from "../../button/vigilus-button";
import loginFlowMessages from "../utils/login-flow-messages.json";

const ForgotPasswordSuccess = ({ onResend }) => {
  const successMessage =
    loginFlowMessages.en.devise.passwords.send_instructions;
  return (
    <LoginFlowCard
      icon={
        <i className="fa fa-envelope text-primary text-[8rem] text-center"></i>
      }
    >
      <div className="flex flex-col gap-4 items-center text-center">
        <h1 className="text-3xl font-bold">Check your email</h1>
        <p>{successMessage}</p>

        <div className="flex flex-col gap-2 w-full">
          <VigilusButton
            primary
            onClick={() => location.replace(`/users/sign_in`)}
            type="submit"
            className="py-3 px-4 border-none"
          >
            <span className="w-full text-xl">Continue to login</span>
          </VigilusButton>

          <VigilusButton
            secondary
            onClick={onResend}
            className="py-3 px-4 border-none"
          >
            <span className="w-full text-xl">
              Resend password reset instructions
            </span>
          </VigilusButton>
        </div>
      </div>
    </LoginFlowCard>
  );
};
export default ForgotPasswordSuccess;
