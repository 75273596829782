import React from "react";
import PageLayout from "../../../../layouts/page-layout";
import ApplicationTestBillingModalButton from "./application_test_billing_modal_button";
import {
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../table/table";
import StatusTag from "../../components/status-tag";

const CallFlowTesterIndividualTestRun = ({
  applicationTestRun,
  backButtonPath,
  started,
  lastUpdated,

  isMasterAdmin,
  billingInfoMinutes,
}) => {
  console.log(applicationTestRun);

  const sortedSteps = applicationTestRun.application_test_run_steps.sort(
    (a, b) => a.number - b.number
  );

  return (
    <PageLayout
      title={`Test Run ${
        applicationTestRun.session_id || applicationTestRun.id
      }`}
      backButton={{
        path: backButtonPath,
        text: `Back to ${applicationTestRun.application_test.name}`,
      }}
    >
      <div
        className="flex flex-col gap-4 rounded-md bg-white p-4"
        style={{ border: `1px solid #d2d6de` }}
      >
        <div className="flex items-center gap-2">
          <strong>Status:</strong>
          <span>{applicationTestRun.status}</span>
        </div>

        {!applicationTestRun.passed && (
          <>
            <div className="flex items-center gap-2">
              <strong>Reason:</strong>
              {applicationTestRun.failed_reason_text}
            </div>
            <div className="flex items-center gap-2">
              <strong>Reason Code:</strong>
              {applicationTestRun.failed_reason}
            </div>
          </>
        )}

        <div className="flex items-center gap-2">
          <strong>Test:</strong>
          {applicationTestRun.application_test?.name}
        </div>

        <div className="flex items-center gap-2">
          <strong>Number Dialed:</strong>
          {applicationTestRun.endpoint}
        </div>

        {applicationTestRun.source && (
          <div className="flex items-center gap-2">
            <strong>Caller ID:</strong>
            {applicationTestRun.source}
          </div>
        )}

        <div className="flex items-center gap-2">
          <strong>Started:</strong>
          {started}
        </div>

        <div className="flex items-center gap-2">
          <strong>Last updated:</strong>
          {lastUpdated}
        </div>

        <div className="flex items-center gap-2">
          <strong>Billed Minutes:</strong>
          {billingInfoMinutes}
          {isMasterAdmin && (
            <ApplicationTestBillingModalButton
              billInfo={applicationTestRun.billing_info}
            />
          )}
        </div>

        {applicationTestRun.recording_url && (
          <div className="flex flex-col gap-2">
            <strong>Recording:</strong>
            <audio controls className="h-12">
              <source src={applicationTestRun.recording_url} />
            </audio>
          </div>
        )}

        {applicationTestRun.application_test_run_steps.length <= 0 && (
          <>
            <h3>No steps executed</h3>
          </>
        )}
        {applicationTestRun.application_test_run_steps.length > 0 && (
          <>
            <h3>Steps</h3>

            <Table sortOn={0} sortDir={"asc"} loading={false}>
              <TableHead>
                <TableHeadCell
                  className="rounded-t-lg"
                  style={{ borderBottom: "1px solid #083B87" }}
                ></TableHeadCell>
                <TableHeadCell
                  style={{ borderBottom: "1px solid #083B87" }}
                ></TableHeadCell>
                <TableHeadCell style={{ borderBottom: "1px solid #083B87" }}>
                  Expected
                </TableHeadCell>
                <TableHeadCell style={{ borderBottom: "1px solid #083B87" }}>
                  Actual
                </TableHeadCell>
                <TableHeadCell
                  className="rounded-t-lg"
                  style={{ borderBottom: "1px solid #083B87" }}
                >
                  Input
                </TableHeadCell>
              </TableHead>

              {applicationTestRun.application_test_run_steps.map((step) => (
                <TableRow key={step.id}>
                  <TableCell>{step.number}</TableCell>

                  <TableCell>
                    <StatusTag status={step.passed ? "passed" : "failed"} />
                  </TableCell>
                  <TableCell>
                    {step.expected ? (
                      step.expected
                    ) : step.expected === null && step.passed ? (
                      <em>Any</em>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell>
                    {step.actual ? (
                      step.actual
                    ) : step.actual === null && step.expected ? (
                      <em>Silence</em>
                    ) : (
                      <em>None</em>
                    )}
                  </TableCell>
                  <TableCell>{step.input}</TableCell>
                </TableRow>
              ))}
            </Table>
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default CallFlowTesterIndividualTestRun;
