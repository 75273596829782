import React from 'react'
import ReactDOM from 'react-dom'
import Notification from './notification'
import UserControls from './user_controls'
import VigilusButton from './button/vigilus-button';

class OrganizationUsersList extends React.Component {
  constructor(props) {
    super(props);
  }

  renderNewOrgUserForm() {
    window.renderForm({
      type: "User",
      status: "new",
      action: "/organizations/users",
      containerId: "new-org-user",
      organizationId: this.props.organization.id,
      isOrgUser: this.props.isOrgUser
    }, true);
  }

  deleteUser(e, id) {
    e.preventDefault()
    let url = `/organizations/users/${id}`

    $.ajax({
      type: "DELETE",
      url,
      dataType: "json",
      context: this,
      success: function(response) {
        window.showNotification(response.message, "info")
        this.updateTables()
      },
      error: function(error) {
        window.showNotification(error.responseJSON.error, "info")
      }
    })
  }

  updateTables() {
    this.usersTable.ajax.reload()
  }

  componentDidMount() {
    let that = this;
    this.usersTable = $('.org-users-table').DataTable({
      order: [[0, "desc"]],
      processing: false,
      serverSide: true,
      searchDelay: 750,
      ajax: {
        "url": `/organizations/data_table_users`,
        "dataSrc": function(json) {
          for (var i=0; i < json.data.length; i++) {
            console.log(json.data[i][4])
          }
          return json.data
        }
      },
      paging: true,
      pageLength: 100,
      ordering: true,
      searching: true,
      autoWidth: false,
      columnDefs: [{
        targets: 4,
        orderable: false,
        "createdCell": function (td, cellData, rowdata, col) {
          let dataObj = JSON.parse(cellData)
          ReactDOM.render(
            <React.Fragment>
              { that.props.canManageOrganization &&
                  <UserControls
                    user={dataObj}
                    action={`/organizations/users/${dataObj.id}`}
                    updateTables={that.updateTables.bind(that)}
                    deleteUser={that.deleteUser.bind(that, event, dataObj.id)}
                    isOrgUser={true}
                  />
              }
            </React.Fragment>
           , td)
        }
      }],
    });
  }

  render() {
    let addUserButton = <div></div>;
    if (this.props.canManageOrganization) {
      addUserButton =
        <VigilusButton onClick={this.renderNewOrgUserForm.bind(this)}>
          Add User
        </VigilusButton>
    }

    return(
      <div className="table-responsive">
        <div className="content body">
          {addUserButton}
          <br/>
          <table className="table no-margin org-users-table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default OrganizationUsersList;
