import React from 'react'
import ReactDOM from 'react-dom'
import Notification from './notification'
import UserControls from './user_controls'

class UsersList extends React.Component {
  constructor(props) {
    super(props);
  }

  renderNewUserForm() {
    window.renderForm({
      type: "User",
      status: "new",
      action: "/users/admin",
      master_admin: this.props.master_admin,
      containerId: "new-user"
    }, true);
  }

  deleteUser(e, id) {
    e.preventDefault()
    let url = `/users/${id}`

   $.ajax({
      type: "DELETE",
      url,
      dataType: "json",
      context: this,
      success: function(response) {
        window.showNotification(response.message, "info")
        this.updateTables()
      },
      error: function(error) {
        window.showNotification(error.responseJSON.error, "info")
      }
    })
  }

  updateTables() {
    this.usersTable.ajax.reload()

  }

  componentDidMount() {
    let that = this;
    this.usersTable = $('.users-table').DataTable({
      order: [[0, "desc"]],
      processing: false,
      serverSide: true,
      searchDelay: 750,
      ajax: {
        "url": `users/data_table_users`,
        "dataSrc": function(json) {
          for (var i=0; i<json.data.length; i++) {
            console.log(json.data[i][6])

          }

          return json.data
        }
      },
      paging: true,
      pageLength: 100,
      ordering: true,
      searching: true,
      autoWidth: false,
      columnDefs: [{
        targets: [3, 6],
        orderable: false,
        "createdCell": function (td, cellData, rowdata, row, col) {
          if (typeof cellData === "object") {
            ReactDOM.render(
              <React.Fragment>
                { (!(!cellData.master_admin && !cellData["safe_orgs?"].length)) &&
                  <UserControls
                    user={cellData}
                    action={`/users/${cellData.id}`}
                    updateTables={that.updateTables.bind(that)}
                    deleteUser={that.deleteUser.bind(that, event, cellData.id)}
                    master_admin={true}
                  />
                }
              </React.Fragment>
            , td)
          }
        }
      }]
    });
  }

  render() {
    return(
      <div className="table-responsive">
        <div className="content body">
          <div>
            <button type="button" className="btn btn-sm btn-info" onClick={this.renderNewUserForm.bind(this)}>Add Master User</button>
          </div>
          <br/>
          <table className="table no-margin users-table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Domain</th>
                <th>Organizations</th>
                <th>Master</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default UsersList;