import React from 'react';
import './phone_keypad.scss';

export default class PhoneKeypad extends React.Component {

  render() {
    return (<div className="phone-keypad">
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("1")}>1</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("2")}>2</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("3")}>3</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("4")}>4</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("5")}>5</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("6")}>6</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("7")}>7</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("8")}>8</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("9")}>9</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("*")}>*</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("0")}>0</button>
        <button type="button" className="phone-keypad__key" disabled={this.props.disabled} onClick={() => this.props.onPress("#")}>#</button>
    </div>)
  }
}
