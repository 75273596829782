import React from "react";

const LoginFlowWrapper = ({ children }) => {
  return (
    <div className="h-screen flex flex-col justify-center gap-4 bg-vigilus-purple">
      {children}
    </div>
  );
};
export default LoginFlowWrapper;
