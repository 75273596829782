import React from "react";

const FormStepper = ({ steps, currentStepIndex }) => {
  const getBgColor = (isCurrentStep, stepHasBeenCompleted) => {
    if (stepHasBeenCompleted) return "bg-[#EDECF5]";
    switch (isCurrentStep) {
      case true:
        return "bg-[#EDECF5]";
      case false: // future steps
        return "bg-[#A4A2CD]";
      default:
        return null;
    }
  };

  const getTextColor = (isCurrentStep, stepHasBeenCompleted) => {
    if (stepHasBeenCompleted) return "text-[#EDECF5]";
    switch (isCurrentStep) {
      case true:
        return "text-[#EDECF5]";
      case false: // future steps
        return "text-[#A4A2CD]";
      default:
        return null;
    }
  };

  const StepProgressLabel = (isCurrentStep, stepHasBeenCompleted) => {
    if (stepHasBeenCompleted) return "Completed";
    switch (isCurrentStep) {
      case true:
        return "In Progress";
      case false: // future steps
        return "Pending";
      default:
        return null;
    }
  };

  const Step = ({ step, stepIndex }) => {
    const isCurrentStep = stepIndex === currentStepIndex;
    const stepHasBeenCompleted = stepIndex < currentStepIndex;

    return (
      <div className="flex flex-col gap-2 items-center text-sm text-[#EDECF5] w-32">
        <div
          className={`w-12 h-12 rounded-full flex items-center justify-center text-md font-medium text-[#3F3C70] ${getBgColor(
            isCurrentStep,
            stepHasBeenCompleted
          )}`}
        >
          {stepIndex}
        </div>
        <div
          className={`flex flex-col tems-center ${getTextColor(
            isCurrentStep,
            stepHasBeenCompleted
          )}`}
        >
          <div className={`text-2xl ${isCurrentStep ? `font-bold` : ``}`}>
            {isCurrentStep || stepHasBeenCompleted ? step.label : <>&nbsp;</>}
          </div>
          <div className="font-medium text-sm">
            {isCurrentStep || stepHasBeenCompleted ? (
              StepProgressLabel(isCurrentStep, stepHasBeenCompleted)
            ) : (
              <>&nbsp;</>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-center justify-center gap-8 w-full text-center text-white">
      {steps.map((step, index) => {
        return <Step key={index} step={step} stepIndex={index + 1} />;
      })}
    </div>
  );
};
export default FormStepper;
