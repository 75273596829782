import React from 'react';

class OrgStats extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      queried: false,
      has_error: false,
      total_components: 0,
      total_components_error: 0,
      total_components_warning: 0,
      latest_update: 0,
      oldest_update: 0,
      latest_update_display: "Never",
      oldest_update_display: "Never",
      latest_event_display: "Never",
      event_timeout: false,
      query_timeout: false,
    };

    this.retrieveStats();
    setInterval(() => {
      this.retrieveStats();
    }, 30000);
  }

  retrieveStats() {
    let self = this;

    return $.ajax({
      method: "GET",
      url: "/organizations/" + self.props.id + "/stats",
      success: function(data) {
        self.setState({
          queried: true,
          has_error: false,
          total_components: data["total_components"],
          total_components_error: data["total_components_error"],
          total_components_warning: data["total_components_warning"],
          latest_update_display: data["latest_update_display"],
          oldest_update_display: data["oldest_update_display"],
          latest_event_display: data["latest_event_display"],
          event_timeout: data["event_timeout"],
          query_timeout: data["query_timeout"],
        });
      },
      error: function(err) {
        console.log("Failed to retrieve org stats: ", err);
        self.setState({
          has_error: true,
          queried: true
        });
      }
    });
  }

  handleDelete() {
    let self = this;

    if(confirm("Are you sure you want to delete this Organization?") == true) {
      $.ajax({
        method: 'POST',
        url: '/organizations/' + this.props.id,
        data: {_method: "delete"},
        success: function(data){
          location.reload();
        },
        error: function(err){
          console.log("Failed to delete organization: ", err);
        }
      });
    }
  }

  handleEdit() {
    window.renderForm({
      type: "Organization",
      status: "edit",
      action: "/organizations/" + this.props.id,
      model: { ...this.props.org, secret_access_key: this.props.secret_access_key },
      us_time_zones: this.props.current_us_time_zones
    }, true);
  }

  render() {
    let anchor_link = "/organizations/set_organization?organization_id=" + this.props.id;

    let maintenance = "";
    if(this.props.maintenance == true){
      maintenance ="In Maintenance"
    }

    let card_status_class = "org_card";
    if (this.state.total_components === 0) {
      card_status_class += " org_card-state_invalid";
    } else if (this.state.total_components_error > 0) {
      card_status_class += " org_card-state_error";
    } else if (this.state.total_components_warning > 0) {
      card_status_class += " org_card-state_warning";
    } else {
      card_status_class += " org_card-state_good";
    }

  return(
    <div className={card_status_class}>
    <a className="org_card-link" href={anchor_link}></a>
    <div className="org_card-title">{this.props.name}</div>
    {this.props.read_only === false &&
      <div className="org_card-actions">
          <div className="edit-delete-holder">
              <div>
                <a className="btn btn-sm btn-info" onClick={this.handleEdit.bind(this)}>
                  <i className="fa fa-pencil-alt"></i>
                </a>
              </div>
              <div>
                <a className='btn btn-sm btn-danger' method="delete" onClick={this.handleDelete.bind(this)}>
                  <i className="fa fa-trash"></i>
                </a>
              </div>
            </div>
        </div>
    }
      <div className="org_card-stats">
        {this.state.queried === false ?
          <div className="org_card-loading">Retrieving organization stats...</div>
        :
          this.state.has_error === true ?
            <div className="org_card-error">Error retrieving organization stats.</div>
          :
            <div>
              <div className="org_card-total_down">Errors / Warnings / Total</div>
              <div className="org_card-stat">
                <span style={{marginRight: '5px', verticalAlign: 'middle'}}>Components: </span>
                <span className={"badge" + (this.state.total_components_error > 0 ? " badge-danger" : "")}>
                  {this.state.total_components_error}
                </span>
                <span style={{color: '#999'}}> / </span>
                <span className={"badge" + (this.state.total_components_warning > 0 ? " badge-warning" : "")}>
                  {this.state.total_components_warning}</span>
                <span style={{color: '#999'}}> / </span>
                <span className="badge">{this.state.total_components}</span>
              </div>

              <br/>
              <div className="org_card-timestamp">
                <div>Latest Update: {this.state.latest_update_display}</div>
                <div>Oldest Update: {this.state.oldest_update_display}</div>
                <div>Latest Event: {this.state.latest_event_display}</div>

                {this.state.query_timeout &&
                  <span style={{ color: "red", marginRight: "12px" }}><i style={{ marginRight: "4px" }} className="fas fa-bolt"></i>Query Timeout</span>
                }
                {this.state.event_timeout &&
                  <span style={{ color: "red", marginRight: "12px" }}><i style={{ marginRight: "4px" }} className="fas fa-bolt"></i>Event Timeout</span>
                }
              </div>
              <div  className='org_card-maintenance '>{maintenance}</div>
            </div>
        }
      </div>
    </div>
    );
  }
}

export default OrgStats;
