import React from "react";

const SelfServeFlowWrapper = ({ children }) => {
  // TODO move out to common file, share with other login form?
  return (
    <div className="h-screen flex flex-col justify-center gap-8 bg-vigilus-purple">
      {children}
    </div>
  );
};
export default SelfServeFlowWrapper;
