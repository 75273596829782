import React from 'react'
import FormModalButton from './form_modal_button';
import AssignCredentialFormModal from './assign_credential_form_modal';
import { CredentialFormModal } from './credentials_form_modal';
import { Table, TableHead, TableHeadCell, TableRow, TableCell, TableEmpty } from './table/table';
import { objectMap } from '../utils';
import { object } from 'prop-types';

class CredentialsList extends React.Component {
  constructor(props) {
    super(props);

    let collectors = {};

    for (let collector of this.props.collectors) {
      collectors[collector.id] = collector;
    }

    this.state = {
      collectors: collectors
    };
  }

  getAssignmentsForCredential(name, allAssignments) {
    let assignments = {}
    for (let key of Object.keys(allAssignments)) {
      let assignment = allAssignments[key];
      if (assignment.name == name) {
        assignments[key] = assignment;
      }
    }

    return assignments;
  }

  render() {
    let noCollectors = Object.keys(this.state.collectors).length == 0;
    let allErrored = this.props.collector_results.every((result) => result.status == "error");
    let noCredentials = Object.keys(this.props.credentials).length == 0;

    return(
      <Table>
        <TableHead>
          <TableHeadCell>Credential ID</TableHeadCell>
          <TableHeadCell>Username</TableHeadCell>
          <TableHeadCell sortable={false}>Assigned to</TableHeadCell>
          <TableHeadCell sortable={false}>Deploy Status</TableHeadCell>
          <TableHeadCell sortable={false}></TableHeadCell>
        </TableHead>

        {noCredentials &&
          <TableEmpty>
            <p>No credentials found. Click "Add Credential" above to create a new credential.</p>
          </TableEmpty>
        }
        {allErrored &&
          <TableEmpty>
            <p>Couldn't fetch credentials from any collectors. Make sure that at least one collector is running and connected.</p>
          </TableEmpty>
        }
        {noCollectors &&
          <TableEmpty>
            <p>No collectors found. Please add a collector to assign credentials.</p>
          </TableEmpty>
        }

        {/* Print a row for each credential */}
        {objectMap(this.props.credentials, (name, credential, i) => {
          // Retrieve just the assignments for the current credential
          let currentAssignments = this.getAssignmentsForCredential(name, this.props.credential_assignments);

          return (
            <TableRow key={i}>
              <TableCell>{name}</TableCell>
              <TableCell>{credential.username}</TableCell>
              <TableCell>
                {Object.keys(currentAssignments).length == 0 ?
                  <i>None</i>
                :
                  <ul className="no-bullets">
                    {/* Print a section for each assignment for the current credential */}
                    {objectMap(currentAssignments, (assignmentId, assignment) => {
                      return (
                        <CredentialAssignments key={`${i}-${assignmentId}`}  {...this.props} {...this.state} id={assignmentId} {...assignment} />
                      );
                    })}
                  </ul>
                }
              </TableCell>
              <TableCell>
                <CredentialDeployStatusSummary {...this.props} {...this.state} name={name} assignmentsForCurrentCredential={currentAssignments} />
              </TableCell>
              <TableCell style={{paddingLeft: "20px"}}>
                <FormModalButton
                    type="Credential"
                    status="edit"
                    label="Update"
                    form_title="Update Credential"
                    action={`/credentials/${encodeURIComponent(name)}`}
                    displayAs="link"
                    modalSize="small"
                    form_button_label="Update"
                >
                    <CredentialFormModal name={name} username={credential.username} status="edit" />
                </FormModalButton>

                <div style={{display: "inline-block", padding: "0px 8px"}}>
                  |
                </div>

                <FormModalButton
                    type="Credential"
                    status="assign"
                    label="Assign"
                    action={`/credentials/${encodeURIComponent(name)}/assignments`}
                    displayAs="link"
                    modalSize="small"
                    form_button_label="Assign"
                >
                    <AssignCredentialFormModal {...this.props} name={name}/>
                </FormModalButton>

                <div style={{display: "inline-block", padding: "0px 8px"}}>
                  |
                </div>

                <div style={{display: "inline-block"}}>
                  <a href={`/credentials/${encodeURIComponent(name)}`}
                      data-confirm={`Are you sure you want to delete the credential "${name}"?`}
                      data-method="delete"
                      rel="nofollow"
                      style={{cursor: "pointer"}}
                  >
                    Delete
                  </a>
                </div>
              </TableCell>

            </TableRow>
          );
        })}

      </Table>
    )
  }
}

class CredentialAssignments extends React.Component {
  render() {
    return (
        <div className="creds__assignment">
          <div className="creds__assignment__list">
            {this.props.id == '.' ?
                <i>Default</i>
              : <div>
                  {/* <div>{this.props.id}</div> */}
                  <CredentialAssignmentsEntry label="Hostname" value={this.props.hostname} />
                  <CredentialAssignmentsEntry label="Instance" value={this.props.instance} />
                  <CredentialAssignmentsEntry label="Application" value={this.props.application} />
                  <CredentialAssignmentsEntry label="Type" value={this.props.type} />
                  <CredentialAssignmentsEntry label="Plugin" value={this.props.plugin} />
                </div>
              }
          </div>
          <div className="creds__assignment_unassign">
            <a href={`/credentials/${encodeURIComponent(this.props.name)}/assignments/${encodeURIComponent(this.props.id)}`}
                data-confirm={`Are you sure you want to unassign "${this.props.name}" from "${this.props.id}"?`}
                data-method="delete"
                rel="nofollow"
                style={{cursor: "pointer"}}
            >
              Unassign
            </a>
          </div>
        </div>
    )
  }
}

class CredentialAssignmentsEntry extends React.Component {
  render() {
    return (
        <div style={{marginBottom: "6px"}}>
          <div style={{display: "inline-block", minWidth: "50%", marginRight: "5px"}}>{this.props.label} </div>
          <div style={{display: "inline-block"}}>{this.props.value ? <b>{this.props.value}</b> : <i>Any</i> }</div>
        </div>
    );
  }
}

class CredentialDeployStatusSummary extends React.Component {

  getCredentialDeployStatusSummary() {
    let statusByCollector = {};

    // Check the base credential deploy status
    for (let [collectorId, info] of Object.entries(this.props.collector_credential_status || {})) {
      let status = info[this.props.name].status;

      if (status != "found") {
        statusByCollector[collectorId] = "error";
      } else {
        statusByCollector[collectorId] = "ok";
      }
    }

    // Check the assigned credential deploy status
    for (let assignmentId of Object.keys(this.props.assignmentsForCurrentCredential)) {
      // let assignment = this.props.assignments[assignmentId];

      for (let [collectorId, info] of Object.entries(this.props.collector_credential_assignments_status)) {
        let status = info[assignmentId].status;

        if (status != "found") {
          statusByCollector[collectorId] = "error";
        } else if (statusByCollector[collectorId] != "error") {
          statusByCollector[collectorId] = "ok";
        }
      }

    }

    return statusByCollector;
  }

  render() {
    let deployStatusSummary = this.getCredentialDeployStatusSummary();
    let hasErrors = deployStatusSummary && Object.values(deployStatusSummary).includes("error");

    return <div>
      {objectMap(deployStatusSummary, (collectorId, status) => {
      // {Object.entries(deployStatusSummary).map(([collectorId, status], i) => {
        let collector = this.props.collectors[collectorId];

        return (
          <div key={collectorId}
          // style={{borderTop: i > 0 && "1px solid whitesmoke", paddingTop: i > 0 && "5px"}}
          >
            <div style={{ padding: "0px 10px 5px 0px", display: "inline-block", minWidth: "calc(100% - 50px)" }}>
              {collector.name}:
            </div>
            <div style={{ padding: "0px 0px 5px 0px", display: "inline-block", maxWidth: "50px" }}>
              <div className={`creds__deploy-status creds__deploy-status--${status}`}>{status}</div>
            </div>
          </div>
        );
      })}
      {hasErrors &&
        <div style={{ padding: "0px 0px 5px 0px", display: "inline-block" }}>
          <FormModalButton
              type="Credential"
              status="show"
              label="Details"
              form_title="Deploy Status Details"
              displayAs="link"
              form_button_label="Details"
          >
              <CredentialDeployStatus {...this.props} />
          </FormModalButton>
        </div>
      }
    </div>
  }
}

class CredentialDeployStatus extends React.Component {

  syncAssignments() {
    let toPost = objectMap(this.props.assignmentsForCurrentCredential, (id, assignment) => {
      return assignment;
    });

    $.ajax({
      url: `/credentials/${encodeURIComponent(this.props.name)}/assignments`,
      type: "POST",
      data: JSON.stringify({
        assignments: toPost
      }),
      contentType: "application/json",
      dataType: "json",
      success: (data) => {
        window.location.reload();
      }
    });
  }

  render() {
    return (
      <div>
        <div>Credentials:</div>
        {objectMap(this.props.collector_credential_status, (collectorId, info) => {
          let collector = this.props.collectors[collectorId];
          let status = info[this.props.name].status;

          return (
            <div key={collectorId}>
              <div style={{ padding: "0px 10px 5px 0px", display: "inline-block", width: "50%" }}>
                {collector.name}
              </div>
              <div style={{ padding: "0px 0px 5px 0px", display: "inline-block" }}>
                <div className={`creds__deploy-status creds__deploy-status--${status}`}>{status}</div>
              </div>
            </div>
          );
        })}

        <div style={{marginTop: "10px", marginBottom: "5px"}}>Assignments:</div>
        {Object.keys(this.props.assignmentsForCurrentCredential).length == 0 ?
          <div style={{marginTop: "5px"}}><i>None</i></div>
        : <div>
            {objectMap(this.props.assignmentsForCurrentCredential, (id, assignment) => {
              return (
                <div key={id} className="creds__assignment creds__assignment__list">
                  {id == '.' ?
                    <i>Default</i>
                  : <div style={{marginBottom: "5px", fontWeight: "bold"}}>{id}</div>}
                  {objectMap(this.props.collector_credential_assignments_status, (collectorId, info) => {
                    let collector = this.props.collectors[collectorId];
                    let status = info[id].status;

                    return (
                      <div key={collectorId}>
                        <div style={{ padding: "0px 10px 5px 0px", display: "inline-block", width: "50%" }}>
                          {collector.name}
                        </div>
                        <div style={{ padding: "0px 0px 5px 0px", display: "inline-block" }}>
                          <div className={`creds__deploy-status creds__deploy-status--${status}`}>{status}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <div style={{display: "inline-block"}}>
              <a onClick={this.syncAssignments.bind(this)} rel="nofollow"
                  style={{cursor: "pointer"}}
              >
                Sync Assignments
              </a>
            </div>

          </div>
        }
      </div>
    );
  }
}

export default CredentialsList;
