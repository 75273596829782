import React from "react";
import vigilusLogo from "../../../../assets/images/vigilus-logo-transparent-new.png";

const LoginFlowCard = ({ children, error, icon }) => {
  return (
    <div className="flex flex-col gap-4 bg-white rounded-2xl m-auto p-16 flex w-5/6 sm:w-[48rem]">
      {icon || (
        <img src={vigilusLogo} className="img-responsive m-auto w-1/2" />
      )}
      {error && (
        <div className="flex items-center text-red-500 h-12">{error}</div>
      )}
      <div className="flex flex-col gap-8">{children}</div>
    </div>
  );
};
export default LoginFlowCard;
