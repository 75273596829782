import React from 'react'
import FormModalButton from '../../../../form_modal_button'
import FormModal from '../../../../form_modal'
import ApplicationTestConfiguration from '../../../../application_test_configuration'
import ApplicationTestLiveConfig from '../../../../application_test_live_config'
import DropdownMenu from '../../../../dropdown_menu'
import UploadTestFormModal from '../../../../../packs/modals/upload_test_form_modal'


export class ApplicationTestConfigFormModalButton extends React.Component {

    render() {
        return (
            <FormModalButton {...this.props}
                type="Test Case"
                form_title={"Edit Test Case: " + this.props.model.name}
                action={`/application_tests/${this.props.application_test_id}/test_cases/${this.props.model.id}`}
                backdrop="static"
            >
                <ApplicationTestConfiguration {...this.props} />
            </FormModalButton>
        )
    }
}

export class ApplicationTestConfigCopyFormModalButton extends React.Component {

    render() {
        return (
            <FormModalButton {...this.props}
                type="Test Case"
                form_title={"New Test Case"}
                action={`/application_tests/${this.props.application_test_id}/test_cases`}
                backdrop="static"
            >
                <ApplicationTestConfiguration {...this.props} />
            </FormModalButton>
        )
    }
}

export class ApplicationTestConfigNewFormModalButton extends React.Component {

    render() {
        return (
            <FormModalButton {...this.props}
                type="Test Case"
                form_title={"New Test Case"}
                action={`/application_tests/${this.props.application_test_id}/test_cases`}
                backdrop="static"
            >
                <ApplicationTestConfiguration {...this.props} />
            </FormModalButton>
        )
    }
}


export class ApplicationTestConfigNewFormDropdownButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            manualConfigOpen: false,
            liveConfigOpen: false
        }

        this.liveConfigRef = React.createRef();
    }

    itemClickHandler(index) {
        console.log("itemClickHandler", index);
        if (index === 0) {
            this.setState({manualConfigOpen: true});
        } else if (index === 1) {
            this.setState({liveConfigOpen: true});
        }
    }

    render() {
        return (
            <div>
                <FormModal {...this.props}
                        open={this.state.manualConfigOpen}
                        closeFunction={() => this.setState({manualConfigOpen: false})}
                        status="new"
                        type="Test Case"
                        form_title={"New Test Case"}
                        action={`/application_tests/${this.props.application_test_id}/test_cases`}
                        backdrop="static">

                    <ApplicationTestConfiguration {...this.props} />
                </FormModal>

                <FormModal {...this.props}
                    open={this.state.liveConfigOpen}
                    closeFunction={() => {
                        // The live tester needs time to make sure any active call is hung up
                        if (this.liveConfigRef.current) {
                            this.liveConfigRef.current.close.call(this.liveConfigRef.current, () => {
                                this.setState({liveConfigOpen: false});
                            });
                            return;
                        }

                        this.setState({liveConfigOpen: false});
                    }}
                    type="Test Case"
                    form_title={"New Test Case"}
                    label="New Live Config"
                    action={`/application_tests/${this.props.application_test_id}/test_cases`}
                    backdrop="static"
                    readonly={true}
                    status="show"
                >
                    <ApplicationTestLiveConfig {...this.props} ref={this.liveConfigRef} />
                </FormModal>

                <DropdownMenu button="New" onItemClicked={this.itemClickHandler.bind(this)}>
                    <div>
                        Configure Manually
                    </div>
                    <div>
                        Configure via Live Call
                    </div>
                </DropdownMenu>
            </div>
        )
    }
}