import React, { useState } from "react";
import {
  ApplicationTestConfigNewFormDropdownButton,
  ApplicationTestConfigNewFormModalButton,
} from "./application_test_config_modal_button";
import {
  Table,
  TableCell,
  TableEmpty,
  TableHead,
  TableHeadCell,
  TableRow,
} from "../../../../table/table";
import FormModalButton from "../../../../form_modal_button";
import ApplicationTestConfiguration from "../../../../application_test_configuration";
import SimpleSearchBar from "../../../../simple-search-bar/simple-search-bar";
import UploadTestFormModal from "../../../../../packs/modals/upload_test_form_modal";

const CallFlowTesterConfiguration = (props) => {
  const { application_test_configuration, application_test_id, readonly } =
    props;

  const [search, setSearch] = useState("");

  const filteredConfigurations =
    application_test_configuration?.test_definition?.filter((test) => {
      return (
        test.name && test.name.toLowerCase().includes(search.toLowerCase()) ||
        test.description && test.description.toLowerCase().includes(search.toLowerCase())
      );
    });

  const rows =
    filteredConfigurations &&
    filteredConfigurations.map((testCase) => {
      const copy = { ...testCase };
      copy.name = "";
      copy.id = "";
      return (
        <TableRow key={testCase.id}>
          <TableCell>{testCase.name}</TableCell>
          <TableCell>{testCase.description}</TableCell>
          <TableCell
            className="flex items-center gap-2"
            style={{ minWidth: "250px" }}
          >
            <FormModalButton
              type="Test Case"
              form_title={"Edit Test Case: " + testCase.name}
              status={readonly ? "show" : "edit"}
              action={`/application_tests/${application_test_id}/test_cases/${testCase.id}`}
              backdrop="static"
              label={"Edit"}
              model={testCase}
              secondary={true}
            >
              <ApplicationTestConfiguration {...props} model={testCase} />
            </FormModalButton>

            <FormModalButton
              type="Test Case"
              form_title={"New Test Case"}
              action={`/application_tests/${application_test_id}/test_cases`}
              backdrop="static"
              status="copy"
              model={copy}
              secondary={true}
              {...props}
            >
              <ApplicationTestConfiguration {...props} model={copy} />
            </FormModalButton>

            <a
              className="bg-transparent rounded-md p-2 flex items-center gap-2 border border-primary text-primary hover:bg-gray-200 text-lg"
              data-method="get"
              href={`/application_tests/${application_test_id}/application_test_configuration/${application_test_configuration.id}/test_cases/${testCase.id}`}
            >
              Export
            </a>

            {!readonly && (
              <a // TODO - this should be using <VigilusButton />
                className="bg-transparent rounded-md p-2 h-full text-red-700 hover:text-red-700 hover:bg-red-100 border border-[#b91c1c] text-lg"
                data-confirm="Are you sure you want to delete this test case?"
                rel="nofollow"
                data-method="delete"
                href={`/application_tests/${application_test_id}/test_cases/${testCase.id}`}
              >
                Delete
              </a>
            )}
          </TableCell>
        </TableRow>
      );
    });

  return (
    <div className="flex flex-col gap-4 py-4">
      {/* top bar */}
      <div className="flex sm:items-center flex-col gap-4 sm:gap-0 sm:flex-row sm:justify-between px-8">
        <SimpleSearchBar onChange={(e) => setSearch(e.target.value)} />

        <div className="flex items-center gap-2">
          {!readonly ? (
            <>
              {props.live_config_enabled ? (
                <ApplicationTestConfigNewFormDropdownButton {...props} />
              ) : (
                <ApplicationTestConfigNewFormModalButton
                  status="new"
                  style={{}}
                  className="px-4"
                  {...props}
                />
              )}
            </>
          ) : null}

          <UploadTestFormModal
            url={`/application_tests/${application_test_id}/upload`}
          />
        </div>
      </div>
      {/* end top bar */}

      <Table sortOn={0} sortDir={"desc"} loading={false}>
        <TableHead>
          <TableHeadCell style={{ borderBottom: "1px solid #083B87" }}>
            Name
          </TableHeadCell>
          <TableHeadCell style={{ borderBottom: "1px solid #083B87" }}>
            Description
          </TableHeadCell>
          <TableHeadCell style={{ borderBottom: "1px solid #083B87" }}>
            Actions
          </TableHeadCell>
        </TableHead>

        <TableEmpty>
          <p>No sessions found</p>
        </TableEmpty>

        {rows}
      </Table>
    </div>
  );
};

export default CallFlowTesterConfiguration;
