import React from 'react';
import { Table, TableHead, TableHeadCell, TableRow, TableCell, TableEmpty } from './table/table';
import { Pagination } from './table/pagination';
import FormModalButton from './form_modal_button';
import { SubscriptionItemsModal, SubscriptionFormModal } from './billing_subscriptions_form_modal';
import "moment";
import moment from "moment-timezone";
import SearchFilterReplaySessions from './search_filter_replay_sessions';
import DateRangePicker from 'react-daterangepicker-cloverhound';
import 'daterangepicker/daterangepicker.css';

export default class ReplaySessionsList extends React.Component {

  constructor(props) {
    super(props);

    let org_tz = this.props.timezone|| "America/Denver";
    moment.tz.setDefault(org_tz);
    let now = moment().tz(org_tz);

    this.state = {
      search: "",
      now: now,
      startDate: now.clone().startOf("week"),
      endDate: now.clone().endOf("week"),
      queriedStartDate: "",
      queriedEndDate: "",
      org_tz,
      showDateRange: false,
      allSessions: [],
      filteredSessions: this.props.sessions,
      currentPage: 1,
      loading: true
    };

    this.loadSessions();
  }



  async loadSessions() {
    let url = "/replay/sessions.json"

    if (this.state.queriedStartDate) {
      let dateRange = new URLSearchParams({startRange: this.state.queriedStartDate, endRange: this.state.queriedEndDate})
      url+=`?${dateRange}`
    }

      try {
        let response = await fetch(url);

        if (response?.ok) {
          let sessions = await response.json();
          console.log(sessions);
          this.setState({
            filteredSessions: sessions
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.setState({loading: false});
      }

  }

  rangeUpdated(event, picker) {
    console.log('Range updated:', picker);
    this.setState({
      startDate: picker.startDate.clone(),
      endDate: picker.endDate.clone(),
      queriedStartDate: picker.startDate.clone().format('YYYY-MM-DDTHH:mm:ss:SSS'),
      queriedEndDate: picker.endDate.clone().format('YYYY-MM-DDTHH:mm:ss:SSS'),
      showDateRange: true,
    }, () => {
      this.loadSessions();
    });
  }

  onSearch(e) {
    let filteredSessions = this.props.sessions.filter((session) => {
      let searchString = e.target.value.toLowerCase();
      return session.id.toLowerCase().indexOf(searchString) > -1
        || (session.session_detail.end_user?.name || "Unknown").toLowerCase().indexOf(searchString) > -1
        || (session.session_detail.end_user?.email || "").toLowerCase().indexOf(searchString) > -1
        || (session.session_detail.city || "").toLowerCase().indexOf(searchString) > -1
        || (session.session_detail.state || "").toLowerCase().indexOf(searchString) > -1
        || (session.session_detail.browser || "").toLowerCase().indexOf(searchString) > -1
        || (session.session_detail.os || "").toLowerCase().indexOf(searchString) > -1
        || (session.session_detail.isp || "").toLowerCase().indexOf(searchString) > -1
    });

    this.setState({search: e.target.value, filteredSessions: filteredSessions});
  }

  async handleSearchResults (results) {

    let searchquery = results[0]
    // Create the URL with the search parameters
     const url = `/replay/sessions.json?search_query=${JSON.stringify(searchquery)}`;
     // Send the request to the Rails controller
     try {
      let response = await fetch(url);

      if (response?.ok) {
        let sessions = await response.json();
        this.setState({
          filteredSessions: sessions
        });
      }
    } catch (e) {
      console.error(e);
    }

}

  render() {
    let isEmpty = this.state.filteredSessions.length == 0;

    let sessionCount = this.state.filteredSessions.length;
    let pageCount = Math.ceil(sessionCount / 10);
    let currentPage = this.state.currentPage;
    if (currentPage > pageCount) {
      currentPage = pageCount;
    }
    let pageStart = (this.state.currentPage - 1) * 10;
    let pageEnd = pageStart + 10;
    let pageSessions = this.state.filteredSessions.slice(pageStart, pageEnd);

    let now = this.state.now;
    let dateRangeFormat = "MM/DD/YYYY h:mm A z";
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let currTimeZone = startDate.format('z')

    let style = {

      range: {
        display: "inline-block",
        backgroundcolor: "#FFF",
        padding: "7px 10px",
        marginLeft: "15px",
        border: "1px solid #ddd",

        text: {
          marginLeft: "5px"
        }
      }
    }

    return(
      <div>
        {/* <input type="text" placeholder="Search" onChange={this.onSearch.bind(this)} value={this.state.search}
          style={{
            padding: "6px 12px",
            borderRadius: "4px",
            border: "1px solid #d2d6de",
          }}
        /> */}
         <div className='table-search'>

        <DateRangePicker
          onApply={this.rangeUpdated.bind(this)}
          startDate={startDate}
          endDate={endDate}
          ranges={{
            "Today": [
              now.clone().startOf("day"),
              now.clone().endOf("day")],
            "Yesterday": [
              now.clone().subtract(1, "days").startOf("day"),
              now.clone().subtract(1, "days").endOf("day")],
            "This Week": [
              now.clone().startOf("week"),
              now.clone().endOf("week")],
            "This Month": [
              now.clone().startOf("month"),
              now.clone().endOf("month")],
            "Previous Month": [
              now.clone().subtract(1, "month").startOf("month"),
              now.clone().subtract(1, "month").endOf("month")],
            "Previous Year": [
              now.clone().subtract(1, "year").startOf("year"),
              now.clone().subtract(1, "year").endOf("year")],
            "This Year": [
              now.clone().startOf("year"),
              now.clone().endOf("year")]
          }}
          timePicker={true}
          timePickerSeconds={true}
          alwaysShowCalendars={true}
          opens="right"

          locale={{
            format: "MM/DD/YYYY h:mm A",
            "separator": " - ",
            "cancelLabel": "Cancel",
            "fromLabel": "From",
            "toLabel": "To",
            "CustomRangeLabel": "Custom",
            "weekLabel": "W",
            "daysOfWeek": [
              "Su",
              "Mo",
              "Tu",
              "We",
              "Th",
              "Fr",
              "Sa"
            ],
            "monthNames": [
              "January",
              "Feburary",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December"
            ],
            "firstDay": 0
          }}
          data-testid="date-range-picker"
        >
          <div style={style.range}>
          <i className="glyphicon glyphicon-calendar fa fa-calendar" data-testid="calendar-icon"/>
          <span style={style.range.text} data-testid="date-range-text">
      {this.state.showDateRange ? startDate.format(dateRangeFormat) + " - " +
        endDate.format(dateRangeFormat) : `All times 12:00:00 AM ${currTimeZone} - 11:59:59 PM ${currTimeZone}`}
    </span>
            </div>
        </DateRangePicker>

        <SearchFilterReplaySessions onSearchResults={this.handleSearchResults.bind(this)} />
        </div>


        <Table sortOn={0} sortDir={"desc"} loading={this.state.loading}>
          <TableHead>
            <TableHeadCell>Date/Time</TableHeadCell>
            <TableHeadCell>Agent</TableHeadCell>
            <TableHeadCell>Email</TableHeadCell>
            <TableHeadCell>Session ID</TableHeadCell>
            <TableHeadCell sortable={false}>Tabs</TableHeadCell>
            <TableHeadCell>Location</TableHeadCell>
            <TableHeadCell>Browser</TableHeadCell>
            <TableHeadCell>OS</TableHeadCell>
            <TableHeadCell>ISP</TableHeadCell>
          </TableHead>

          <TableEmpty>
            <p>No sessions found</p>
          </TableEmpty>

          {this.state.filteredSessions.map((session, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{session.started_at}</TableCell>
                <TableCell>{session.end_user?.name || "Unknown"}</TableCell>
                <TableCell>{session.end_user?.email || ""}</TableCell>
                <TableCell>{session.id}</TableCell>
                <TableCell>
                  {session.tabs.map((tab, i) => {
                    return (
                      <div key={i}>
                        <a href={`${this.props.player_base_url}/${session.organization_id}/play?sessionId=${session.id}-${tab.id}`} target="_blank">Tab {i + 1}</a>
                      </div>
                    );
                  })}
                </TableCell>
                <TableCell>{session.session_detail?.city ? `${session.session_detail.city}, ${session.session_detail.state}` : ""}</TableCell>
                <TableCell>{session.session_detail?.browser || ""}</TableCell>
                <TableCell>{session.session_detail?.os || ""}</TableCell>
                <TableCell>{session.session_detail?.isp || ""}</TableCell>

              </TableRow>
            );
          })}

        </Table>


      </div>
    )
  }
}

window.renderDateTimePicker = function(settings) {
  var container = document.getElementById(settings.containerId);
  ReactDom.render(<ReplaySessionsList {...settings}/>, container)
};
