import React from 'react'

import {Modal} from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import {FormControl} from 'react-bootstrap'


class NotesModal extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = { newNotes: props.notes }
  }

  handleSubmit() {
    console.log('Handling submit', this.state)
    this.props.submitMethod(this.state.newNotes)
  }

  handleChangeNotes(event) {
    console.log('Handling change notes')
    if (this.props.canManageOrganization) {
      this.setState({newNotes: event.target.value})
    }
  }

  render() {
    return (
        <Modal show={this.props.show} onHide={this.props.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormControl 
              type='text'
              id="notesText" 
              disabled={!this.props.canManageOrganization}
              value={this.state.newNotes}
              onChange={this.handleChangeNotes.bind(this)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.handleClose}>
              Close
            </Button>
            {this.props.canManageOrganization &&
              <Button variant="primary" onClick={this.handleSubmit.bind(this)} disabled={this.state.newNotes == this.props.notes && !this.props.isMuting}>
                Submit
              </Button>
            }
          </Modal.Footer>
        </Modal>
    )
  }
}

export default NotesModal




