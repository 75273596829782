import React from 'react'
import ReactDOM from 'react-dom'

class TestQuery extends React.Component {

  constructor() {
    super();

    this.state = {
      type: "SnmpMetric",
      query: "",
      results: []
    }
  }

  runTestQuery() {
    var deviceId = this.props.device_id;
    var collectorId = this.props.collector_id;
    var query = this.state.query;

    var vigUrl = this.props.engine_url;
    $.ajax({
      method: 'POST',
      url: '//' + vigUrl + '/v1/device_metrics/test',
      data: {
        device_id: deviceId,
        collector_id: collectorId,
        query: query,
        type: this.state.type
      },
      success: (data) => {
        var results = [];
        var jsonResponse = JSON.parse(data);

        if (this.state.type == "WmiMetric") {
          for(var i = 0; i < jsonResponse.length; i++) {
            results.push(<div key={i + "-result"}><span>{i+1}</span>:</div>);
            for (var key in jsonResponse[i]) {
              var value = jsonResponse[i][key];
              results.push(<div key={i + "-" + String(key)}><span>{String(key)}</span>: <span>{String(value)}</span></div>);
            }
          }
        } else if (this.state.type == "SnmpMetric") {
          for (var key in jsonResponse) {
            var valueList = jsonResponse[key];
            for (var value of valueList) {
              results.push(<div key={value.oid}><span>{value.oid + " - " + value.name}</span>: <span>{value.value}</span></div>);
            }
          }
        }

        this.setState({ results });
      }
    });
  }

  typeChanged(event) {
    this.setState({ type: event.target.value });
  }

  queryChanged(query) {
    this.setState({ query });
  }

  submit() {
    this.runTestQuery();
  }

  render() {
    let queryField;

    switch (this.state.type) {
      case "SnmpMetric":
        queryField = <SnmpQuery parent={this} />
        break;
      case "WmiMetric":
        queryField = <WmiQuery parent={this} />
        break;
    }


    return(
      <div className="modal fade in" id={this.props.element_id}>
        <div className="modal-dialog">
          <div className="modal-content">

            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">x</span>
              </button>
              <h4 className="modal-title">Test Query</h4>
            </div>

            <div className="modal-body">
              <div className="form-group">
                <label>Type</label>
                <select onChange={this.typeChanged.bind(this)} className="form-control">
                  <option key="SnmpMetric" value="SnmpMetric">SNMP</option>
                  <option key="WmiMetric" value="WmiMetric">WMI</option>
                </select>
              </div>

              {queryField}
              
              <input id="submit_test_query" name=""
                type="submit" value="Submit" className="btn btn-primary"
                onClick={this.submit.bind(this)}
                
              />
              <div id="test_result">
                {this.state.results}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class WmiQuery extends React.Component {

  constructor() {
    super();

    this.state = {
      query: ""
    }
  }

  queryChanged(event) {
    let query = event.target.value;
    this.setState({ query });

    let parentQuery =  JSON.stringify({"value": query});
    this.props.parent.queryChanged(parentQuery);
  }

  render() {
    return <div className="form-group">
      <label>Query</label>
      <input id="test_snmp_query_input" className="form-control"
        onChange={this.queryChanged.bind(this)}
        value={this.state.query}
      />
    </div>
  }
}

class SnmpQuery extends React.Component {

  constructor() {
    super();

    this.state = {
      query: ""
    }
  }

  queryChanged(event) {
    let query = event.target.value;
    this.setState({ query });

    let parentQuery =  JSON.stringify({"OIDs": query.split(',')});
    this.props.parent.queryChanged(parentQuery);
  }

  render() {
    return <div className="form-group">
      <label>Query</label>
      <input id="test_snmp_query_input" className="form-control"
        onChange={this.queryChanged.bind(this)}
        value={this.state.query}
      />
    </div>
  }
}

export default TestQuery;
