import React from "react";
import {
  Table,
  TableHead,
  TableHeadCell,
  TableRow,
  TableCell,
  TableEmpty,
} from "../../../../table/table";
import moment from "moment-timezone";
import DateRangePicker from "react-daterangepicker-cloverhound";
import SimpleSearchBar from "../../../../simple-search-bar/simple-search-bar";
import StatusTag from "../../../components/status-tag";
import "daterangepicker/daterangepicker.css";
import VigilusButton from "../../../../button/vigilus-button";

const SORT_BY_COLUMNS = [
  "session_id",
  "status",
  "updated_at",
  "endpoint",
  "status_reason"
];

export default class CallFlowTesterTestRuns extends React.Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    const searchParam = urlParams.get("search") || "";
    const startDateParam = urlParams.get("startRange");
    const endDateParam = urlParams.get("endRange");

    let org_tz = this.props.timezone || "America/Denver";
    moment.tz.setDefault(org_tz);
    let now = moment().tz(org_tz);

    this.state = {
      search: searchParam,
      now: now,
      startDate: startDateParam
        ? moment(startDateParam)
        : now.clone().startOf("month"),
      endDate: endDateParam ? moment(endDateParam) : now.clone().endOf("month"),
      queriedStartDate: startDateParam || "",
      queriedEndDate: endDateParam || "",
      org_tz,
      showDateRange: false,
      filteredTest: this.props.application_test,
      currentPage: 1,
      sortOn: 2,
      sortDir: "desc",
      pageCount: this.props.page_count || 1,
      pageSize: this.props.page_size || 50,
      loading: true,
      showDateRange: startDateParam && endDateParam,
    };

    this.loadTest();
  }

  async loadTest() {
    let url = `/application_tests/${this.props.application_test_id}/test_runs_table`;

    let searchParam = "";
    if (this.state.search.trim() !== "") {
      searchParam = `search=${encodeURIComponent(this.state.search.trim())}`;
    }

    let dateRangeParam = "";
    if (this.state.queriedStartDate) {
      let dateRange = new URLSearchParams({
        startRange: this.state.queriedStartDate,
        endRange: this.state.queriedEndDate,
      });
      dateRangeParam = `&${dateRange}`;
    }

    let paginationParam = `page=${this.state.currentPage}`;
    let sortParam = `sortBy=${SORT_BY_COLUMNS[this.state.sortOn]}&sortDir=${
      this.state.sortDir
    }`;

    if (searchParam || dateRangeParam) {
      url += `?${searchParam}${dateRangeParam}&${paginationParam}&${sortParam}`;
    } else {
      url += `?${paginationParam}&${sortParam}`;
    }

    try {
      let response = await fetch(url);

      if (response?.ok) {
        let test = await response.json();
        this.setState({
          filteredTest: test.application_test_runs,
          pageCount: test.page_count,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  exportToCSV() {
    const { search, startDate, endDate } = this.state;
    const { application_test_id } = this.props;

    let updatedUrl = `/application_tests/${application_test_id}/export_csv`;
    updatedUrl += `?search=${search}`;
    updatedUrl += `&startRange=${encodeURIComponent(startDate.format())}`;
    updatedUrl += `&endRange=${encodeURIComponent(endDate.format())}`;

    window.location.href = updatedUrl;
  }

  rangeUpdated(event, picker) {
    console.log("Range updated:", picker);
    this.setState(
      {
        startDate: picker.startDate.clone(),
        endDate: picker.endDate.clone(),
        queriedStartDate: picker.startDate
          .clone()
          .format("YYYY-MM-DDTHH:mm:ss:SSS"),
        queriedEndDate: picker.endDate
          .clone()
          .format("YYYY-MM-DDTHH:mm:ss:SSS"),
        showDateRange: true,
      },
      () => {
        const { search, startDate, endDate } = this.state;
        const { application_test_id, onUpdateUrl } = this.props;

        let updatedUrl = `/application_tests/${encodeURIComponent(
          application_test_id
        )}`;
        updatedUrl += `?search=${encodeURIComponent(search)}`;
        updatedUrl += `&startRange=${encodeURIComponent(startDate.format())}`;
        updatedUrl += `&endRange=${encodeURIComponent(endDate.format())}`;

        onUpdateUrl(updatedUrl);

        this.loadTest();
      }
    );
  }

  onSearch(e) {
    console.log("Search updated:", e.target.value);
    const search = e.target.value;
    this.setState({ search }, () => {
      const { startDate, endDate } = this.state;
      const { application_test_id, onUpdateUrl } = this.props;

      let updatedUrl = `/application_tests/${encodeURIComponent(
        application_test_id
      )}`;
      updatedUrl += `?search=${encodeURIComponent(search)}`;
      updatedUrl += `&startRange=${encodeURIComponent(startDate.format())}`;
      updatedUrl += `&endRange=${encodeURIComponent(endDate.format())}`;

      onUpdateUrl(updatedUrl);

      this.loadTest();
    });
  }

  handlePageChange(page) {
    console.log("Page changed:", page);
    this.setState({ currentPage: page, loading: true }, () => {
      this.loadTest();
    });
  }

  handleSort(sortOn, sortDir) {
    console.log("Sort changed:", sortOn, sortDir);
    this.setState({ sortOn, sortDir, loading: true }, () => {
      this.loadTest();
    });
  }

  render() {
    let now = this.state.now;
    let dateRangeFormat = "MM/DD/YYYY h:mm A z";
    let startDate = this.state.startDate;
    let endDate = this.state.endDate;
    let currTimeZone = startDate.format("z");

    return (
      <div className="flex flex-col gap-4 py-4">
        <div className="flex sm:items-start flex-col gap-4 sm:gap-0 sm:flex-row sm:justify-between px-8">
          <div className="flex flex-col gap-4">
            <DateRangePicker
              onApply={this.rangeUpdated.bind(this)}
              startDate={startDate}
              endDate={endDate}
              ranges={{
                Today: [now.clone().startOf("day"), now.clone().endOf("day")],
                Yesterday: [
                  now.clone().subtract(1, "days").startOf("day"),
                  now.clone().subtract(1, "days").endOf("day"),
                ],
                "This Week": [
                  now.clone().startOf("week"),
                  now.clone().endOf("week"),
                ],
                "This Month": [
                  now.clone().startOf("month"),
                  now.clone().endOf("month"),
                ],
                "Previous Month": [
                  now.clone().subtract(1, "month").startOf("month"),
                  now.clone().subtract(1, "month").endOf("month"),
                ],
                "Previous Year": [
                  now.clone().subtract(1, "year").startOf("year"),
                  now.clone().subtract(1, "year").endOf("year"),
                ],
                "This Year": [
                  now.clone().startOf("year"),
                  now.clone().endOf("year"),
                ],
              }}
              timePicker={true}
              timePickerSeconds={true}
              alwaysShowCalendars={true}
              opens="right"
              locale={{
                format: "MM/DD/YYYY h:mm A",
                separator: " - ",
                cancelLabel: "Cancel",
                fromLabel: "From",
                toLabel: "To",
                CustomRangeLabel: "Custom",
                weekLabel: "W",
                daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
                monthNames: [
                  "January",
                  "Feburary",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ],
                firstDay: 0,
              }}
              data-testid="date-range-picker"
            >
              <div className="flex items-center gap-4 rounded-md py-2 cursor-pointer">
                <i
                  className="glyphicon glyphicon-calendar fa fa-calendar"
                  data-testid="calendar-icon"
                />
                <span data-testid="date-range-text">
                  {this.state.showDateRange
                    ? startDate.format(dateRangeFormat) +
                      " - " +
                      endDate.format(dateRangeFormat)
                    : `All times 12:00:00 AM ${currTimeZone} - 11:59:59 PM ${currTimeZone}`}
                </span>
              </div>
            </DateRangePicker>
            <SimpleSearchBar
              query={this.state.search}
              onChange={this.onSearch.bind(this)}
            />
          </div>

          <VigilusButton onClick={() => this.exportToCSV()} className="px-4">
            <i className="fas fa-file-export"></i> Export to CSV
          </VigilusButton>
        </div>

        <Table
          ajax={true}
          sortOn={this.state.sortOn}
          sortDir={this.state.sortDir}
          onSort={this.handleSort.bind(this)}
          loading={this.state.loading}
          onPageChange={this.handlePageChange.bind(this)}
          currentPage={this.state.currentPage}
          pageCount={this.state.pageCount}
          pageSize={this.state.pageSize}
        >
          <TableHead>
            <TableHeadCell style={{ borderBottom: "1px solid #083B87" }}>
              ID
            </TableHeadCell>
            <TableHeadCell style={{ borderBottom: "1px solid #083B87" }}>
              Status
            </TableHeadCell>
            <TableHeadCell style={{ borderBottom: "1px solid #083B87" }}>
              Date
            </TableHeadCell>
            <TableHeadCell style={{ borderBottom: "1px solid #083B87" }}>
              Number
            </TableHeadCell>
            <TableHeadCell style={{ borderBottom: "1px solid #083B87" }}>
              Reason
            </TableHeadCell>
          </TableHead>

          <TableEmpty>
            <p className="px-4">No Test Runs found</p>
          </TableEmpty>

          {this.state.filteredTest &&
            this.state.filteredTest.map((test, i) => {
              let tagStatus = test.status;
              if (test.status === "failed" && test.will_retry === true) {
                tagStatus = "retry";
              }

              return (
                <TableRow key={i}>
                  <TableCell className="pl-4">
                    <a
                      href={`/application_test_runs/${encodeURIComponent(
                        test.id
                      )}`}
                    >
                      {test.session_id}
                    </a>
                  </TableCell>
                  <TableCell>
                    <StatusTag status={tagStatus} />
                  </TableCell>
                  <TableCell>
                    {moment(test.updated_at)
                      .tz(this.state.org_tz)
                      .format("MM-DD-YYYY HH:mm:ss ZZ")}
                  </TableCell>
                  <TableCell>{test.endpoint}</TableCell>
                  <TableCell className="pr-4">
                    {getFailedReasonTextShort(
                      test.passed,
                      test.completed,
                      test.failed_reason
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </Table>
      </div>
    );
  }
}

function getFailedReasonTextShort(passed, completed, failedReason) {
  if (passed || !completed) {
    return null;
  }

  if (failedReason && failedReason.startsWith("call_failed")) {
    return "Invalid Number";
  }

  switch (failedReason) {
    case "call_busy":
      return "Busy";
    case "call_no_answer":
      return "No Answer";
    case "early_hangup":
      return "Early Disconnect";
    case "wrong_message":
      return "Wrong Message";
    case "message_timeout":
      return "Dead Air";
    case "cancelled":
      return "Error in Tester";
    default:
      return "Unknown";
  }
}
