import React from 'react'

export class ScheduleCalendar extends React.Component {
	constructor(props) {
		super(props);
	}

	daysInMonth(month, year) {
		return new Date(year, month, 0).getDate();
	}

	getEmptyDay(key) {
		let className = "calendar-day";
		if (this.props.readOnly) {
			className += " calendar-readonly"
		}
		return <div key={key} className={className}>&nbsp;</div>;
	}

	getNumberedDay(dayNum, key, isActive) {
		let className = isActive ? "calendar-day calendar-day-active" : "calendar-day";
		if (this.props.readOnly) {
			className += " calendar-readonly"
		}
		return <div key={key} className={className}>{dayNum}</div>;
	}

	getFrontFill(dayArray, firstDayOfWeek) {
		for (let i=0; i<firstDayOfWeek; i++) {
			dayArray.push(this.getEmptyDay(i));
		}

		return dayArray;
	}

	getBackFill(dayArray, offset) {
		if (offset%7 === 0) {
			return dayArray;
		}

		for (let i=0; i<7-(offset%7); i++) {
			dayArray.push(this.getEmptyDay(offset+i));
		}

		return dayArray;
	}

	dateInSelectedDays(month, day) {
		for (let i=0; i<this.props.selectedDays.length; i++) {
			if (this.props.selectedDays[i][0] === month && this.props.selectedDays[i][1] === day) {
				return true;
			}
		}

		return false;
	}

	populateMonthDays(dayArray, count, firstDayOfWeek, month) {
		for (let i=0; i<count; i++) {
			dayArray.push(this.getNumberedDay(i+1, firstDayOfWeek+i, this.dateInSelectedDays(month, i+1)));
			if ((firstDayOfWeek+i+1) % 7 === 0) {
				dayArray.push(<br key={firstDayOfWeek+i+10000} />);
			}
		}

		return dayArray;
	}

	getCalendar(month, year) {
		while (month > 12) {
			month -= 12;
			year += 1;
		}

		let daysThisMonth = this.daysInMonth(month, year);
		let firstDayOfWeek = new Date(year, month-1, 1).getDay();

		let currentMonthDays = []
		currentMonthDays = this.getFrontFill(currentMonthDays, firstDayOfWeek);
		currentMonthDays = this.populateMonthDays(currentMonthDays, daysThisMonth, firstDayOfWeek, month);
		currentMonthDays = this.getBackFill(currentMonthDays, firstDayOfWeek+daysThisMonth);

		return currentMonthDays;
	}

	getMonthName(month) {
		const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

		while (month > 12) {
			month -= 12;
		}

		return monthNames[month-1];
	}

	render() {
		let newDate = new Date();

		let currentMonthDays = this.getCalendar(newDate.getMonth()+1, newDate.getFullYear());
		let nextMonthDays = this.getCalendar(newDate.getMonth()+2, newDate.getFullYear());
		let nextNextMonthDays = this.getCalendar(newDate.getMonth()+3, newDate.getFullYear());

		return (
			<div>
				<div className="calendar-month-small">
					<div className="calendar-month-heading">
						{this.getMonthName(newDate.getMonth()+1)}
					</div>
					{currentMonthDays.map((e, i) => e)}
				</div>
				<div className="calendar-month-small">
					<div className="calendar-month-heading">
						{this.getMonthName(newDate.getMonth()+2)}
					</div>
					{nextMonthDays.map((e, i) => e)}
				</div>
				<div className="calendar-month-small">
					<div className="calendar-month-heading">
						{this.getMonthName(newDate.getMonth()+3)}
					</div>
					{nextNextMonthDays.map((e, i) => e)}
				</div>
			</div>
		)
	}
}

export default ScheduleCalendar;
