import React from 'react';
import { Table, TableHead, TableHeadCell, TableRow, TableCell, TableEmpty } from '../table/table';
import "moment";
import moment from "moment-timezone";

export default class LoadCallResultsList extends React.Component {
  render() {
    return(
      <div>
        <Table sortOn={0} sortDir={"asc"}>
          <TableHead>
            <TableHeadCell>Date/Time</TableHeadCell>
            <TableHeadCell>Called Number</TableHeadCell>
            <TableHeadCell>SIP Response</TableHeadCell>
            <TableHeadCell>Call SID</TableHeadCell>
            <TableHeadCell>Status</TableHeadCell>
            <TableHeadCell>Duration</TableHeadCell>
            <TableHeadCell sortable={false}>Recording</TableHeadCell>
          </TableHead>

          {this.props.call_results.map((result, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{moment(result.created_at).tz(this.props.timeZone).format('MM/DD/YYYY HH:mm:ss.SSS z')}</TableCell>
                <TableCell>{result.to_number}</TableCell>
                <TableCell>{result.sip_response_code}</TableCell>
                <TableCell>{result.call_sid}</TableCell>
                <TableCell>{result.call_status}</TableCell>
                <TableCell>{result.call_duration}</TableCell>
                <TableCell>
                  {result.recording_url &&
                    <audio key={result.id} controls style={{height: "30px"}}>
                      <source src={result.recording_url} />
                    </audio>
                  }
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </div>
    )
  }
}

