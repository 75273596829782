import React from 'react'
import FormModalButton from './form_modal_button';

class NewCredentialsModalButton extends React.Component {

  render() {
    return (
    <FormModalButton
        type="Credential"
        status="new"
        label="Add Credential"
        action={`/credentials`}
        // displayAs="link"
        modalSize="small"
        // form_button_label="Assign"
    >
        <CredentialFormModal {...this.props} />
    </FormModalButton>
    );
  }
}

export class CredentialFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name || "",
      username: this.props.username || "",
      password: this.props.password || ""
    }
  }


  render() {
    return (
      <div>
        <label htmlFor="name">Credential ID</label>
        <br/>
        <input type="text"
                readOnly={this.props.status === "edit"}
                name="credential[name]"
                className="form-control"
                value={this.state.name}
                onChange={(e) => this.setState({name: e.target.value})}
                required={true}
        />

        <br/>

        <label htmlFor="username">Username</label>
        <br/>
        <input type="text"
                name="credential[username]"
                className="form-control"
                value={this.state.username}
                onChange={(e) => this.setState({username: e.target.value})}
                required={true}
        />

        <br/>

        <label htmlFor="password">Password</label>
        <br/>
        <input type="password"
                name="credential[password]"
                className="form-control"
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
        />

      </div>
    );
  }
}

export default NewCredentialsModalButton;