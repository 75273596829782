import React from 'react'
import ReactDOM from 'react-dom'
import Alarm from './alarm'
import VigilusButton from './button/vigilus-button';

class ActiveAlarmsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "All",
      selected: []
    }

    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }

  handleChangeStatus(event) {
    var timeZone  = this.props.timeZone;
    var status    = event.target.value;
    var canManage = this.props.canManageOrganization;
    var container = document.getElementById("active-alarms-list");

    this.setState({status: status})

    $.ajax({
      data: {status: status},
      url: "/events",
      success: (response) => { ReactDOM.render(<ActiveAlarmsList activeAlarms={response} canManageOrganization={canManage} timeZone={timeZone} />, container) },
      error: (error) => { console.log(error) }
    })
  }

  handleChecked(alarmId) {
    console.log('Handling Checked', alarmId)
    var selected = this.state.selected
    selected.push(alarmId)
    this.setState({selected: selected})

  }

  handleUnchecked(alarmId) {
    console.log('Handling Unchecked', alarmId)
    var selected = this.state.selected

    selected = selected.filter(id => id != alarmId)
    this.setState({selected: selected})
  }


  clearSelected() {
    console.log('Clearing selected', this.state.selected)
    if(this.props.activeAlarms.length == 0 || this.state.selected.length == 0) {
      return
    }
    var orgId = this.props.activeAlarms[0].organization_id
    $.ajax({
      url: "organizations/" + orgId + "/active_alarms/clear_selected",
      success: (response) => { location.reload() },
      error: (error) => { console.log(error) },
      data: {selected: this.state.selected},
      method: "POST"
    })
  }

  clearAll() {
    console.log('Clearing all')
    if(this.props.activeAlarms.length == 0) {
      return
    }
    var orgId = this.props.activeAlarms[0].organization_id
    $.ajax({
      url: "organizations/" + orgId + "/active_alarms/clear_all",
      success: (response) => { location.reload() },
      error: (error) => { console.log(error) },
      method: "POST"
    })
  }

  selectAll() {
    let allIds = this.props.activeAlarms.map(x => x.id);
    this.setState({selected: allIds});
  }

  determineChecked(alarmID) {
    return this.state.selected.includes(alarmID);
  }

  render() {

    return(
      <div className="content body min-h-0">
        <div className="flex items-center gap-2">
          <div>
            Status: <select value={this.state.statusFilter} onChange={this.handleChangeStatus}>
              <option value="all">All</option>
              <option value="not_muted">Active</option>
              <option value="muted">Muted</option>
            </select>
          </div>
          {this.props.canManageOrganization ?
            <div className="flex items-center gap-2">
              <VigilusButton onClick={this.selectAll.bind(this)}>
                  Select All
              </VigilusButton>
              <VigilusButton onClick={this.clearSelected.bind(this)}>
                 Clear Selected
              </VigilusButton>
              <VigilusButton onClick={this.clearAll.bind(this)}>
                 Clear All
              </VigilusButton>
            </div>
            :
            <div></div>
          }
        </div>
        <div>
        {this.props.activeAlarms.length > 0 ? 
          <ul className="products-list product-list-in-box">
           {this.props.activeAlarms.map((activeAlarm, i) => {
              return (
                <Alarm
                  id={activeAlarm.id}
                  alarm_id={activeAlarm.alarm_id}
                  severity={activeAlarm.severity}
                  message={activeAlarm.message}
                  name={activeAlarm.name}
                  created_at={activeAlarm.created_at}
                  action={activeAlarm.action}
                  allowClear={this.props.canManageOrganization}
                  allowAcknowledge={this.props.canManageOrganization}
                  canManageOrganization={this.props.canManageOrganization}
                  acknowledged_at={activeAlarm.acknowledged}
                  notes={activeAlarm.notes}
                  active={true}
                  key={i}
                  handleChecked={this.handleChecked.bind(this)}
                  handleUnchecked={this.handleUnchecked.bind(this)}
                  timeZone={this.props.timeZone}
                  isChecked={this.determineChecked(activeAlarm.id)}
                />
            )})}
          </ul> : <div className="flex items-center py-4">No Alarms Active</div>}
        </div>
      </div>
    );
  }
}

export default ActiveAlarmsList;