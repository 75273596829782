import React from 'react';
import ReactDOM from 'react-dom';
import GenericChart from './graphs/generic_chart.jsx';
import ChartWithSummary from './graphs/chart_with_summary.jsx';
import 'daterangepicker/daterangepicker.css';
import "moment";
import moment from "moment-timezone";
import { AdjustmentsIcon } from '../packs/icons/icons'
import ToggleButton from 'react-toggle-button'
import VigilusModal from '../packs/vigilus_modal'
import { DocumentDownloadIcon } from '../packs/icons/icons'
import { Popover } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';

class VigilusChartContainer extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            data: props.data || [],
            dataSets: props.dataSets || null,
            hiddenData: new Set(),
            metaData: props.metaData,
            stats: props.stats,
            colors: this.getColors(props.data, props.dataSets, props.metaData),
            alarmThresholds: props.alarmThresholds || [],
            alarms: props.alarms || [],
            loaded: false,
            stackedMode: props.definition && props.definition.display_settings && props.definition.display_settings.stacked_mode
        }

        props.subscribe(this);
    }

    getColors(data, dataSets, metaDataProp) {
      if (!data || data.length == 0) {
        return [];
      }

      dataSets = dataSets || Object.keys(data[0]);

      if (dataSets.length == this.state.colors.length) {
        return this.state.colors;
      }

      let nextColorRGB = [3, 69, 190];
      let colors = this.state.colors || [];
      for(const [i, type] of dataSets.entries()) {
        // We only want to append new colors
        if (i >= colors.length + 1) {
          continue;
        }

        if (type === "name") {
          //colors.push({ color: "blue", fill: "blue" });
          continue;
        }

        let metaData = (metaDataProp && (metaDataProp[type] || metaDataProp['default'])) || fallbackMetaData;

        let color = metaData.color;
        let fill = metaData.fill;
        if (!color) {
          color = getRGBStringFromArrayWithA(nextColorRGB, 0.9);

          if (!fill) {
            fill = getRGBStringFromArrayWithA(lightenRGB(nextColorRGB, 50), 0.5);
          }

          nextColorRGB = getRandomRGBColorArray();
        }

        if (!fill) {
          fill = color;
        }

        colors.push({
          color: color,
          fill: fill
        });
      }

      return colors;
    }

    update() {
        this.setState({
            loading: true,
        }, () => {
            this.calculateInterval();

            this.getDataPointData().done(this.processChartData.bind(this)).fail((err) => {
                console.error("Error getting data points:", err);
                this.setState({
                    loading: false,
                    dataError: true
                })
            });
        });
    }

    processChartData(data) {
        console.log("Processing chart data: ", data);

        for (var i = 0; i < data.data.length; i++) {
            data.data[i].name = Date.parse(data.data[i].name);
        }

        let hiddenData = new Set();
        if (FeatureFlags.thresholds_in_charts && FeatureFlags.report_threshold_percentages) {
            hiddenData = new Set(data.alarm_thresholds.map(threshold => {
                return threshold.name + "-threshold%";
            }));
        }

        this.setState({
            data: this.getDataWithThresholds(data.data, data.alarm_thresholds, data.alarms),
            dataSets: data.data_sets || null,
            metaData: data.meta_data || this.state.metaData,
            stats: data.stats,
            alarmThresholds: data.alarm_thresholds || [],
            alarms: data.alarms || [],
            loading: false,
            dataError: false,
            colors: this.getColors(data.data, data.data_sets, data.meta_data || this.state.metaData),
            hiddenData: this.state.loaded ? this.state.hiddenData : hiddenData,
            loaded: true,
        });
    }

    getDataPointData() {
        let device_metric_id = this.props.device_metric_id;
        let report_id = this.props.report_id;

        let start = this.props.startDate.format("YYYY-MM-DDTHH:mm:ss");
        let end = this.props.endDate.format("YYYY-MM-DDTHH:mm:ss");
        let url;
        let instance_name = this.props.selected;

        if (device_metric_id) {
            url = "/device_metrics/" + device_metric_id + "/data_points" + "?start=" + start + "&end=" + end + "&instance=" + instance_name;
        } else {
            url = "/reports/" + report_id + "/data_points" + "?start=" + start + "&end=" + end + "&instance=" + instance_name;
        }

        if (this.props.subtype) {
            url += "&subtype=" + this.props.subtype;
        }

        return $.ajax({
            method: "GET",
            url: url,
            dataType: 'json'
        })
    }

    rangeUpdated(event, picker) {
        console.log("Range updated:", picker)
        this.setState({
            loading: true
        }, () => {
            this.update();
        });
    }

    calculateInterval() {
        clearInterval(this.chartInterval);

        let selected_duration = moment.duration(this.props.endDate.diff(this.props.startDate));
        if ((selected_duration.days() <= 1) && (this.props.startDate.unix() <= this.props.now.unix()) && (this.props.now.unix() <= this.props.endDate.unix())) {
            this.chartInterval = setInterval(this.update.bind(this), 60000);
        }
    }

    newAlarmThreshold() {
        if (this.props.application_types) {
            let metric_append = "";
            if (this.props.definition && this.props.definition.data_sources) {
                for (let i=0; i<this.props.definition.data_sources.length; i++) {
                    let current_data_source = this.props.definition.data_sources[i];
                    if (current_data_source.subtype) {
                        metric_append = "&metric="+encodeURIComponent(current_data_source.subtype);
                        break;
                    }
                }
            }

            location.assign("/alarm_thresholds?application_types="+this.props.application_types.join(",") + metric_append);
        } else {
            location.assign("/alarm_thresholds?device_metric="+this.props.device_metric_id+"&metric="+this.props.subtype);
        }
    }

    getDataWithThresholds = (data, alarmThresholds, alarms) => {
        let data_copy = JSON.parse(JSON.stringify(data));

        if (!FeatureFlags.thresholds_in_charts) {
            return data_copy;
        }

        let dataIntervalMillis = 60 * 1000;
        if (data_copy.length > 1) {
            dataIntervalMillis = (data_copy[1].name - data_copy[0].name);
        }

        for (const threshold of alarmThresholds) {
            let thresholdValue = threshold.value;

            if (threshold.subtype === "_storagePct" || threshold.subtype === "_memoryPct") {
                let max;

                for (let key in data_copy[0]) {
                    if (key.endsWith("Max") || key.startsWith("Total")) {
                        max = data_copy[0][key] || 0;
                    }
                }

                if (max && max > 0) {
                    thresholdValue = max / 100 * thresholdValue;
                } else {
                    continue; // No valid data for max storage / memory, ignore this threshold
                }

                for (let i=0; i<data_copy.length; i++) {
                    data_copy[i][threshold.name + "-configuredValue-threshold"] = threshold.value + "%";
                }
            }

            if ((threshold.subtype == "_memoryMB" || threshold.subtype == "_storageMB") && this.props.unit == "GB") {
                thresholdValue = thresholdValue / 1000;
            }

            for (let i=0; i<data_copy.length; i++) {
                data_copy[i][threshold.name + "-threshold"] = thresholdValue;

                if (FeatureFlags.report_threshold_percentages) {
                    for (const alarm of alarms) {
                        if (alarm.alarm_threshold_id != threshold.id) {
                            continue;
                        }

                        let raised = moment(alarm.raised_at);
                        let cleared = moment(alarm.cleared_at);

                        let raisedDiff = raised.diff(moment(data_copy[i].name));
                        let clearedDiff = cleared.diff(moment(data_copy[i].name));

                        let percentage = data_copy[i][threshold.name + "-threshold%"] || 0;

                        if (raisedDiff < 0 && clearedDiff > dataIntervalMillis) {
                            // raised before this interval and cleared after
                            percentage += 100;
                        } else if (raisedDiff > 0 && raisedDiff < dataIntervalMillis && clearedDiff > dataIntervalMillis) {
                            // raised during this interval and cleared after
                            percentage += Math.round((dataIntervalMillis - raisedDiff) / dataIntervalMillis * 100);
                        } else if (raisedDiff < 0 && clearedDiff > 0 && clearedDiff < dataIntervalMillis) {
                            // raised before this interval and cleared during
                            percentage += Math.round((clearedDiff / dataIntervalMillis) * 100);
                        } else if (raisedDiff > 0 && raisedDiff < dataIntervalMillis && clearedDiff < dataIntervalMillis) {
                            // raised during this interval and cleared during
                            percentage += Math.round((clearedDiff - raisedDiff) / dataIntervalMillis * 100);
                        } else {
                            percentage += 0
                        }

                        if (percentage > 100) {
                            percentage = 100;
                        }

                        data_copy[i][threshold.name + "-threshold%"] = percentage;
                    }
                }
            }
        }

        return data_copy;
    }

    export = () => {
        let data = this.getDataWithThresholds(this.state.data, this.state.alarmThresholds, this.state.alarms);
        let headers = new Set();
        let time_obj = {};

        let start = this.props.startDate.toString();
        let end = this.props.endDate.toString();

        data.forEach(data_row => {
            if (!time_obj.hasOwnProperty(data_row["name"])) {
                time_obj[data_row["name"]] = {};
            }

            for (let key in data_row) {
                if (data_row.hasOwnProperty(key) && key !== "name") {
                    headers.add(key);
                    time_obj[data_row["name"]][key] = data_row[key];
                }
            }
        })


        //let headers = Object.keys(data[0]).filter(key => key != "name")

        let headers_ordered = Array.from(headers).sort();
        let csv_string = "\"Time\",\"" + headers_ordered.join("\",\"") + "\"\n";

        csv_string += Object.keys(time_obj).sort().map(time_entry => {
            let key_values = headers_ordered.map(header => {
                if (time_obj[time_entry].hasOwnProperty(header) && time_obj[time_entry][header] !== null) {
                    return time_obj[time_entry][header].toString();
                } else {
                    return "";
                }
            });

            key_values.unshift("\"" + moment(Number(time_entry)).format("YYYY-MM-DD HH:mm:ss ZZ") + "\"");

            return key_values.join(",");
        }).join("\n");

        if (headers_ordered.length == 0) {
            csv_string = "";
        }

        // To downloadable file
        let blob = new Blob([csv_string], { type: "text/csv" });
        let a = document.createElement("a");
        a.download = this.props.data_name + "-" + start + "-" + end + ".csv";
        a.href = URL.createObjectURL(blob);
        a.dataset.downloadurl = ["text/csv", a.download, a.href].join(":");
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(function() {
            URL.revokeObjectURL(a.href);
        }, 1500);
    }

    getDataSeriesNames() {
        let dataSeriesNames = [];
        if (this.state.data && this.state.data.length > 0) {
            dataSeriesNames = Object.keys(this.state.data[0]).filter(dataName => dataName != "name");
        }
        return dataSeriesNames;
    }

    componentDidMount() {
        this._isMounted = true;

        this.calculateInterval();

        if (this.state.data && this.state.data.length > 0) {
            this.processChartData({ data: JSON.parse(this.state.data) });
        }

        this.update();
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.chartInterval);
    }

    render() {
        let showSettingsButton = this.getDataSeriesNames().length > 1;

        let containerWidth = 12;
        let body = "";
        if (this.props.data_name === "Total Memory" || this.props.title === "Memory") {
            body = <ChartWithSummary {...this.props} {...this.state} />
        } else {
            body = <GenericChart {...this.props} {...this.state}/>
        }

        return(
            <div className={"col-sm-" + containerWidth}>
                <div className="box box-primary">
                    <div className="box-header">

                        <h3 className="box-title">
                            {this.props.title}
                        </h3>

                        {showSettingsButton ?
                            <div style={{
                                display: 'inline-block',
                                position: 'absolute',
                                top: '6px',
                                marginLeft: '4px'
                            }}>
                                <VigilusModal component={
                                    <ChartSettings settings={this.state}
                                        metaData={this.state.metaData}
                                        onUpdate={(settings) => { this.setState({
                                            hiddenData: settings.hiddenData,
                                            stackedMode: settings.stackedMode
                                        }); } }/>
                                    }
                                    title={"Chart Settings"}
                                    model={{}}
                                    open={this.state.settingsOpen}
                                    size="large"
                                    onClose={() => this.setState({settingsOpen: false})}
                                />
                                <button id="status-settings-button" className="header-button"
                                    onClick={() => this.setState({settingsOpen: true})}
                                    style={{
                                        width: "41px",
                                        marginLeft: "5px",
                                        verticalAlign: "top",
                                        height: "30px",
                                        paddingTop: "5px"
                                    }}>
                                    <AdjustmentsIcon style={{height: "20px"}}/>
                                </button>
                            </div>
                        : null}

                        <div className="box-tools pull-right">

                            <button className="header-button header-button-thin"
                                style={{
                                // float: "right",
                                    marginRight: "12px"
                                }}
                                onClick={this.export.bind(this)}
                            >
                                <DocumentDownloadIcon style={{height: "20px", marginRight: "4px"}}/>
                                <span>Export</span>
                            </button>

                            {FeatureFlags.thresholds_in_charts &&
                                <button
                                    className="header-button header-button-thin"
                                    style={{marginRight: "12px"}}
                                    onClick={this.newAlarmThreshold.bind(this)}
                                >
                                    <i className="fas fa-chart-area" style={{marginRight: "6px"}}></i>
                                    Create Threshold
                                </button>
                            }

                            <ReportHelp help={this.props.definition && this.props.definition.help} title={this.props.title}/>

                            <button className="btn btn-box-tool" data-widget="collapse">
                                <i className="fa fa-minus"></i>
                            </button>
                        </div>
                    </div>
                    <div className="box-body" style={{height: '220px'}}>
                        {body}
                        {this.state.loading &&
                        <div className="loading-container">
                            {/* <div className="loading-header">Loading...</div> */}
                            <div style={{marginTop: '40px', marginLeft: '10px'}} className="loading-icon"></div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

class ChartSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = props.settings;
    }

    isHidden(name) {
        return this.state.hiddenData.has(name);
    }

    handleCheckedChangeStacked(isChecked) {
        this.setState({
            stackedMode: isChecked
        }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(this.state);
            }
        });
    }

    handleCheckedChangeHidden(name, isChecked) {
        let hiddenData = this.state.hiddenData;
        if (isChecked) {
            hiddenData.delete(name);
        } else {
            hiddenData.add(name);
        }

        this.setState({
            hiddenData: hiddenData
        }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(this.state);
            }
        });
    }

    getDataNames() {
        let dataNames = [];
        if (this.state.data && this.state.data.length > 0) {
            dataNames = Object.keys(this.state.data[0]).filter(dataName => dataName != "name");
        }

        return dataNames;
    }

    supportsStackedMode() {
        let areaLines = [];
        if (this.state.data && this.state.data.length > 0) {
            areaLines = Object.keys(this.state.data[0]).filter(dataName => {
                if (dataName == "name") {
                    return false;
                }

                let metaData = this.props.metaData[dataName] || this.props.metaData['default'] || {};
                return (metaData.type == "area")
            });
        }

        return (areaLines.length > 1);
    }

    hideAll() {
        let hidden = new Set();
        let dataNames = this.getDataNames();
        for (const name of dataNames) {
            hidden.add(name);
        }

        this.setState({
            hiddenData: hidden
        }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(this.state);
            }
        });
    }

    showAll() {
        let hidden = new Set();

        this.setState({
            hiddenData: hidden
        }, () => {
            if (this.props.onUpdate) {
                this.props.onUpdate(this.state);
            }
        });
    }

    render() {
        let dataNames = this.getDataNames();
        let supportsStackedMode = this.supportsStackedMode();

        return <div style={{
                padding: "10px 20px 10px 20px",
                margin: "auto",
                maxWidth: "600px"
            }}>

            {supportsStackedMode ?
                <div style={{
                    marginTop: "0px",
                    marginBottom: "30px",
                    columnCount: 2,
                    columnGap: '50%',
                }}>
                    <div>
                        <h2 style={{fontSize: "1em", marginTop: "0px"}}>
                            Stacked Mode:
                        </h2>
                    </div>
                    <div style={{
                        textAlign: 'center',
                        marginLeft: '10px',
                    }}>
                        <ShowDataToggle
                            id={"toggle-group-stacked"}
                            onChanged={(value) => {this.handleCheckedChangeStacked(value)}}
                            show={!!this.state.stackedMode}
                            activeLabel="ON" inactiveLabel="OFF"
                            />
                    </div>
                </div>
            : null}

            <div style={{
                marginTop: "0px",
                marginBottom: "10px",
                columnCount: 2,
                columnGap: '50%',
            }}>
                <div>
                    <h2 style={{fontSize: "1em", marginTop: "0px"}}>
                        Data Displayed:
                    </h2>
                </div>
                <div style={{
                    fontSize: "12px",
                    textAlign: 'center',
                }}>
                    <a id="expand-dashboard-tabs" onClick={() => this.showAll()}
                        style={{
                            cursor: "pointer",
                            textDecoration: "underline"

                        }}
                    >show all</a>
                    <span style={{margin: "0px 5px"}}>|</span>
                    <a id="collapse-dashboard-tabs" onClick={() => this.hideAll()}
                        style={{
                            cursor: "pointer",
                            textDecoration: "underline"
                        }}
                    >hide all</a>
                </div>
            </div>

            {dataNames.map(dataName => {
                let show = !this.isHidden(dataName);
                return <div
                    style={{padding: "10px"}}
                    key={dataName}
                >
                    <span style={{
                        width: "calc(100% - 100px)",
                       // maxWidth: "200px",
                        display: "inline-block",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        verticalAlign: "middle" // aligns to top when overflowing if this not set
                    }}>
                            {dataName}
                    </span>
                    <ShowDataToggle
                        id={"toggle-group-" + dataName}
                        onChanged={(value) => {this.handleCheckedChangeHidden(dataName, value)}}
                        show={show} />
                </div>
            }) }
        </div>
    }
}

class ShowDataToggle extends React.Component {

    render(){
        let width = "90px";
        let height = 30;
        let thumbHeight = 30 - 6;
        let activeColor = "#0073b7";

        return(<div id={this.props.id} style={{display: "inline-block"}}>
        <ToggleButton
            id={this.props.id}

            value={ this.props.show || false }

            activeLabel={this.props.activeLabel || "SHOW"}
            inactiveLabel={this.props.inactiveLabel || "HIDE"}

            colors={{
                active: {
                    base: activeColor
                }
            }}

            containerStyle={{
                display:'inline-block',
                width: width
            }}

            thumbStyle={{
                borderRadius: 20,
                width: '40px',
                height: thumbHeight,
                borderColor: 'transparent',
                boxShadow: 'none'
            }}
            trackStyle={{
                width: width,
                height: height,
                borderRadius: 20
            }}
            thumbAnimateRange={[3, 47]}

            activeLabelStyle=  {{ width:'50px', fontWeight: 'bold' }}
            inactiveLabelStyle={{ width:'50px', fontWeight: 'bold' }}

            onToggle={(value) => {
                // this.setState({
                //     show: !value
                // })

                if (this.props.onChanged) {
                    this.props.onChanged(!value);
                } else {
                    console.warn("No onChanged handler for ToggleButton, ignoring toggle.");
                }
            }}/>
        </div>)
    }
}

class ReportHelp extends React.Component {
    render() {
        if (!this.props.help) {
            return null
        }

        return <OverlayTrigger trigger="click" placement="left" overlay={
            <Popover id="popover-trigger-click-root-close" title={this.props.title + " Help"} >
                <div>{this.props.help}</div>
            </Popover>
        }>
            <div style={{
                display: "inline-block",
                fontSize: "18px",
                top: "3px",
                position: "relative",
                marginRight: "4px",
                cursor: "pointer"
            }}>
                <i className="far fa-question-circle"></i>
            </div>
        </OverlayTrigger>
    }
}

export default VigilusChartContainer

window.renderChart = function(settings){
    let container = document.getElementById(settings.containerId);
    ReactDOM.render(<VigilusChartContainer {...settings}/>, container)
}

function getRandomRGBColorArray() {
  const round = Math.round;
  const random = Math.random;

  return [round(random() * 255), round(random() * 255), round(random() * 255)];
}

function getRGBStringFromArrayWithA(rgb, a) {
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`;
}

function lightenRGB(rgb, amount) {
  return [lightenRGBValue(rgb[0], amount), lightenRGBValue(rgb[1], amount), lightenRGBValue(rgb[2], amount)];
}

function lightenRGBValue(value, amount) {
  value = value + amount;
  if (value > 255) {
    return 255;
  }

  return value;
}

function getRandomRGBColorWithA(a) {
  var rgb = getRandomRGBColorArray();
  return getRGBStringFromArrayWithA(rgb, a);
}

function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const fallbackMetaData = {
  color: "blue"
}

