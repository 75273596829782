import React from "react";
import 'react-notifications-component/dist/scss/notification.scss';
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';

export default class Notification extends ReactNotification {
	constructor(props) {
		super(props);

		window.showNotification = this.showNotification.bind(this);
	}

	showNotification(message, type) {
		store.addNotification({
			message: message,
			type: type,
			insert: "bottom",
			container: "top-center",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 5000,
				showIcon: true
			}
		});
	}

	render() {
		return ReactNotification.prototype.render.call(this);
	}
}
