import React from 'react'

export class DaySelector extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			monthDays: [],
			activeDays: props.activeDays
		}

		this.state.monthDays = this.getCalendar();
	}

	updateSelection(calendarIndex) {
		let existingDays = this.state.activeDays;
		if (existingDays.includes(calendarIndex)) {
			let index = existingDays.indexOf(calendarIndex);
			existingDays.splice(index, 1);
		} else {
			existingDays.push(calendarIndex);
		}

		this.setState({
			activeDays: existingDays
		}, () => {
			this.updateActiveCSS();
		});
	}

	updateActiveCSS() {
		this.setState({
			monthDays: this.getCalendar()
		});
		this.props.syncActiveDays(this.state.activeDays);
	}

	getCalendar() {
		let dayArray = [];
		for (let i=1; i<32; i++) {
			let classString = "calendar-day";
			if (this.state && this.state.activeDays.includes(i)) {
				classString = "calendar-day calendar-day-active";
			}

			dayArray.push(<div key={i} className={classString} onClick={this.updateSelection.bind(this, i)}>{i}</div>);
			if (i%7 == 0) {
				dayArray.push(<br key={i+10000} />);
			}
		}

		return dayArray;
	}

	render() {
		return (
			<div>
				<div className="calendar-month">
					{this.state.monthDays.map((e, i) => e)}
				</div>
			</div>
		)
	}
}

export default DaySelector;
