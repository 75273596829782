import React from "react";

export default class Card extends React.Component {
  render() {

    return (
      <div id={this.props.id}
        style={Object.assign({
          borderTop: "1px solid #f4f4f4",
          position: "relative",
          borderRadius: "3px",
          background: "#fff",
          // marginBottom: "20px",
          width: "100%",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)",
          padding:  "20px"

        }, this.props.style)}
        className={this.props.className || ""}
      >
        {this.props.children}
      </div>
    );
  }
}
