import React from 'react'
import ReactDOM from 'react-dom'
import {Modal} from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import VigilusButton from './button/vigilus-button';

export default class FormModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form_disabled: false,
            errorString: null,
            modalSize: this.props.modalSize || "large"
        }
        this.disable = this.disable.bind(this);
    }

    close(){
        this.setState({
            form_disabled: false,
            errorString: null
        });
        this.props.closeFunction();
    }

    disable() {
        this.setState({
            form_disabled: true
        });
    }

    enable() {
        this.setState({
            form_disabled: false
        });
    }

    changeSize(size) {
        this.setState({
            modalSize: size
        });
    }

    submitForm(e) {
        this.disable();

        if (this.props.async || this.props.asyncWithError) {
            e.preventDefault();
            let that = this;
            $.ajax({
                method: e.target.method,
                url: e.target.action,
                data: $(e.target).serialize() + "&__is_async=true",
                success: function(data){
                    that.setState({
                        errorString: null
                    });
                    if ("function" in data) {
                        let fn = window[data["function"]];
                        let params = window[data["params"]] || [];
                        params.unshift(that);

                        if (typeof fn === "function") {
                            fn.apply(null, params);
                        }
                    } else {
                        window.location.reload();
                    }
                },
                error: function(err){
                    if (that.props.asyncWithError) {
                        let error_array = [];
                        let error_json = err.responseJSON;
                        for (var key in error_json) {
                            if (error_json.hasOwnProperty(key)) {
                                let current_error = error_json[key];
                                if (key === "base") {
                                    error_array.push(current_error);
                                } else {
                                    let formatted_key = key.charAt(0).toUpperCase() + key.slice(1);
                                    error_array.push(formatted_key + ": " + current_error);
                                }
                            }
                        }
                        that.setState({
                            errorString: error_array.join("; "),
                            form_disabled: false
                        });
                    } else {
                        console.log("Error Submitting Form");
                        that.close();
                    }
                }
            });
        }
    }

    render(){
        let body = this.props.children;

        let formAction = "POST";
        let submitText = "Create";
        let putInput = "";
        if (this.props.status === "edit") {
            formAction = "POST";
            submitText = "Save";
            putInput = <input type="hidden" name="_method" value="PUT" />
        }

        let readOnly = this.props.status == "show";
        let title = this.props.status.charAt(0).toUpperCase() + this.props.status.slice(1) + " " + this.props.type + " " + (this.props.model && this.props.model.id || "");
        if (readOnly) {
            title = this.props.type + " " + (this.props.model && this.props.model.id || "");
        }
        if (this.props.form_title) {
            title = this.props.form_title;
        }

        let onSubmit = this.submitForm.bind(this);
        if (this.props.onSubmit) {
            onSubmit = this.props.onSubmit;
        }

        return(
            <div>
                <Modal show={this.props.open} onHide={this.close.bind(this)} bsSize={this.state.modalSize} backdrop={this.props.backdrop || true}>
                    <Modal.Header closeButton>
                        <Modal.Title className='text-4xl'>{title}</Modal.Title>
                    </Modal.Header>
                    {this.state.errorString && <div className="form_modal_errors">{this.state.errorString}</div>}
                    <form method={formAction} action={this.props.action} acceptCharset="UTF-8" onSubmit={onSubmit}>
                        <input type="hidden" name="authenticity_token" value={$('meta[name="csrf-token"]').attr('content')}/>
                            {putInput}
                        <Modal.Body>
                            {body}
                        </Modal.Body>
                        {!this.props.noFooter &&
                            <Modal.Footer className='flex items-center justify-between w-full before:content-[] after:content-[]'>
                                <Button
                                    onClick={this.close.bind(this)}
                                    className='py-3 px-6 text-xl rounded-md text-[#444] border border-[#ddd] flex items-center gap-2 text-center font-medium bg-[#f4f4f4] hover:bg-[#e7e7e7]'
                                >
                                    Close
                                </Button>

                                {!readOnly ?
                                    <input
                                        type="submit"
                                        value={this.props.form_button_label || (submitText + " " + this.props.type)}
                                        className="py-3 px-6 text-xl rounded-md text-primary flex items-center gap-2 text-center font-medium text-white bg-primary border-none hover:bg-primary-hover"
                                        disabled={this.state.form_disabled}
                                    />
                                : <div></div>}

                            </Modal.Footer>
                        }
                    </form>
                </Modal>
            </div>
        )
    }
}

window.renderForm = function(settings, open) {
    let containerId = settings.type.toLowerCase().split(' ').join('-') + '-form-modal';
    let container = document.getElementById(containerId);

    ReactDOM.render(<FormModal {...settings} open={open} />, container);
}

window.renderFormZ = function(modalType, modalAction, modalStatus, open, containerId, model, context){
    let container = document.getElementById(containerId);
    ReactDOM.render(<FormModal modalType={modalType} status={modalStatus} open={open} action={modalAction} model={model} context={context} />, container);
};
