import React from "react";

const ABBREVIATION_OFFSET = 3;
const ABBREVIATION_LENGTH = 2;

export class Pagination extends React.Component {
  render() {
    let shouldAbbreviate = this.props.totalPages > 10;
    let shouldAbbreviateStart = shouldAbbreviate && this.props.currentPage >= 5;
    let shouldAbbreviateEnd =
      shouldAbbreviate && this.props.currentPage <= this.props.totalPages - 4;
    let shouldAbbreviateBoth = shouldAbbreviateStart && shouldAbbreviateEnd;

    let nextEnabled = this.props.currentPage < this.props.totalPages;
    let prevEnabled = this.props.currentPage > 1;

    return (
      <div
        className="dataTables_paginate paging_simple_numbers px-4"
        style={this.props.style}
      >
        <ul className="pagination" style={{ margin: "5px 0px 10px 0px" }}>
          <li
            className={`paginate_button previous ${
              !prevEnabled ? "disabled" : ""
            }`}
          >
            <a
              href="#"
              tabIndex="0"
              onClick={(e) => {
                prevEnabled &&
                  this.props.onPageChange &&
                  this.props.onPageChange(this.props.currentPage - 1);
                e.preventDefault();
              }}
            >
              Previous
            </a>
          </li>
          {/* loop through pages up to this.props.totalPages */}
          {(() => {
            let pages = [];
            for (let i = 1; i <= this.props.totalPages; i++) {
              if (
                shouldAbbreviateStart &&
                !shouldAbbreviateEnd &&
                i > 2 &&
                i < this.props.totalPages - 4
              ) {
                continue;
              } else if (
                shouldAbbreviateEnd &&
                !shouldAbbreviateStart &&
                i <= this.props.totalPages - 2 &&
                i > 5
              ) {
                continue;
              } else if (
                shouldAbbreviateBoth &&
                i > this.props.currentPage + 1 &&
                i < this.props.totalPages - 1
              ) {
                continue;
              } else if (
                shouldAbbreviateBoth &&
                i < this.props.currentPage - 1 &&
                i > 2
              ) {
                continue;
              } else if (
                (shouldAbbreviateStart && i == 2) ||
                (shouldAbbreviateEnd && i == this.props.totalPages - 1)
              ) {
                pages.push(
                  <li key={i} className="paginate_button disabled">
                    <a tabIndex="0">…</a>
                  </li>
                );
              } else {
                pages.push(
                  <li
                    key={i}
                    className={`paginate_button ${
                      this.props.currentPage == i ? "active" : ""
                    }`}
                  >
                    <a
                      href="#"
                      tabIndex="0"
                      style={{
                        backgroundColor:
                          this.props.currentPage == i ? "#5F75A4" : "",
                        borderColor:
                          this.props.currentPage == i ? "#5F75A4" : "",
                      }}
                      onClick={(e) => {
                        this.props.onPageChange && this.props.onPageChange(i);
                        e.preventDefault();
                      }}
                    >
                      {i}
                    </a>
                  </li>
                );
              }
            }
            return pages;
          })()}
          <li
            className={`paginate_button next ${!nextEnabled ? "disabled" : ""}`}
          >
            <a
              href="#"
              tabIndex="0"
              onClick={(e) => {
                nextEnabled &&
                  this.props.onPageChange &&
                  this.props.onPageChange(this.props.currentPage + 1);
                e.preventDefault();
              }}
            >
              Next
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
