import React from "react";
import VigilusButton from "../../../button/vigilus-button";

const CallFlowTesterSelfServeSuccess = ({}) => {
  const redirectToDashboard = () => {
    location.replace("/application_tests");
  };
  return (
    <form
      onSubmit={redirectToDashboard}
      className="flex flex-col gap-6 items-center w-full text-center"
    >
      <div>
        Success! Your account has been created. {`We've`} added $5 in credits to
        get you started.
      </div>

      <VigilusButton
        type="button"
        className="py-3 px-4 border-none"
        onClick={redirectToDashboard}
      >
        <span className="w-full text-xl">Take me to the tests!</span>
      </VigilusButton>
    </form>
  );
};
export default CallFlowTesterSelfServeSuccess;
