import React from "react";
import PageHeader from "./page-header";

const PageLayout = ({ title, children, backButton }) => {
  return (
    <div className="flex flex-col gap-6 h-full container py-4">
      <PageHeader title={title} backButton={backButton} />
      {children}
    </div>
  );
};

export default PageLayout;
