import React from "react";

export const testerSidebarRoutes = ({
  isMasterAdmin,
  canManageOrganization,
  billingUiFeatureFlag,
  callFlowTesterOnly,
  featureFlagged,
  organizationUsersPath
}) => {
  const routes = [
    {
      label: "Tests",
      path: "/application_tests",
      icon: <i className="fa fa-flask"></i>,
      visible: true,
    },
    {
      label: "Load Testing",
      path: "",
      icon: <i className="fa fa-rocket"></i>,
      visible: isMasterAdmin,
      children: [
        {
          label: "Campaigns",
          path: "/load/campaigns",
          visible: true,
        },
        {
          label: "Campaign Results",
          path: "/load/campaign_results",
          visible: true,
        },
        {
          label: "Test Cases",
          path: "/load/test_cases",
          visible: true,
        },
        {
          label: "Source Phone Numbers",
          path: "/system_phone_numbers",
          visible: true,
        },
      ],
    },
    {
      label: "Event Rules",
      path: "/organization_event_rules",
      icon: <i className="fa fa-project-diagram"></i>,
      visible: featureFlagged && canManageOrganization,
    },
    {
      label: "Notifications",
      path: "/notification_settings",
      icon: <i className="fa fa-bell"></i>,
      visible: featureFlagged && canManageOrganization,
    },
    {
      label: "Notification Template",
      path: "/notification_templates",
      icon: <i className="fas fa-plug"></i>,
      visible: featureFlagged && canManageOrganization,
    },

    {
      label: "Maintenance Mode",
      path: "/alert_suppressions",
      icon: <i className="fa fa-pause-circle"></i>,
      visible: canManageOrganization,
    },
    {
      label: "Activity",
      path: "/events",
      icon: <i className="fa fa-exclamation-circle"></i>,
      visible: true,
    },
    {
      label: "Users",
      path: organizationUsersPath,
      icon: <i className="fa fa-user"></i>,
      visible: canManageOrganization,
    },
    {
      label: "Billing",
      path: "/billing/subscriptions",
      icon: <i className="fa fa-credit-card"></i>,
      visible: isMasterAdmin || billingUiFeatureFlag,
    },
    {
      label: "Settings",
      path: "/organization_settings",
      icon: <i className="fa fa-cogs"></i>,
      visible: isMasterAdmin,
    },
    {
      label: "Documentation",
      path: "/documentation",
      icon: <i className="fas fa-book-open"></i>,
      visible: !callFlowTesterOnly,
    },
  ];

  return routes;
};
