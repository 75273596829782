import React, { useState } from "react";
import LoginFlowWrapper from "../components/login-flow-wrapper";
import ConfirmationForm from "./confirmation-form";
import ConfirmationSuccess from "./confirmation-success";

const ConfirmationContainer = ({ authenticity_token }) => {
  const [view, setView] = useState("initial"); // initial || success || error

  const handleSuccessfulReset = () => {
    setView("success");
  };

  const handleResetView = () => {
    setView("initial");
  };

  return (
    <LoginFlowWrapper>
      {view === "initial" && (
        <ConfirmationForm
          authenticity_token={authenticity_token}
          onSuccess={handleSuccessfulReset}
        />
      )}
      {view === "success" && (
        <ConfirmationSuccess onResend={handleResetView} />
      )}
    </LoginFlowWrapper>
  );
};
export default ConfirmationContainer;
