import React from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger} from 'react-bootstrap';
import {Tooltip} from 'react-bootstrap';

export const EventDots = ({ events, size, height, width, spacing, radius, ...props }) => {

  return (
    <div style={{paddingTop: '2px'}}>
      {events.map((event, i) => {

        let className = "bg-status-tag-green"
        switch (event.status_code) {
          case "good":
            className = "bg-status-tag-green";
            break;
          case "bad":
            className = "bg-red-500";
            break;
          case "warning":
            className = "bg-yellow-400";
            break;
        }

        return (
          <EventDot key={i}
            height={height}
            width={width}
            spacing={spacing}
            radius={radius}
            className={className}
            description={event.description}
            link={event.link}
            timestamp={event.timestamp}
            timestampInWords={event.timestamp_in_words}
          />
        )
      })}
    </div>
  );
};

EventDots.propTypes = {
  /**
   * The array of events
   */
  events: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.number,
  spacing: PropTypes.number,
  radius: PropTypes.number
};

EventDots.defaultProps = {
  events: ['good', 'good', 'bad', 'good']
};

const EventDot = ({className, height, width, spacing, radius, description, link, timestamp, timestampInWords}) => {
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`event-${link}`}>
          <div>{description}</div>
          <div>{timestamp}</div>
          <div>{timestampInWords}</div>
        </Tooltip>
      }
    >
      <div style={{
          width: `${width}px`,
          height: `${height}px`,
          display: 'inline-block',
          marginRight: `${spacing}px`,
          borderRadius: `${radius}px`,
          cursor: 'pointer'
        }} className={className}
        onClick={() => {
          window.location.href = link;
        }}
      >

      </div>
    </OverlayTrigger>
  )
}

EventDot.propTypes = {
  /**
   * The color of the dots
   */
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
  spacing: PropTypes.number,
  radius: PropTypes.number,
  description: PropTypes.string,
  link: PropTypes.string,
  timestamp: PropTypes.string,
  timestampInWords: PropTypes.string
};
EventDot.defaultProps = {
  color: 'gray',
  height: 17,
  width: 6,
  spacing: 2,
  radius: 2
}


export default EventDots;
