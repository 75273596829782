import React from 'react';
import FormModal from './form_modal';
import ApplicationTestConfiguration from './application_test_configuration';
import { ScaleLoader } from "react-spinners";
import { MoveDownIcon } from '../packs/icons/icons'
import { ChatBubble } from './chat_bubbles';
import { AsYouType } from 'libphonenumber-js';
import PhoneKeypad from './phone_keypad';
import ToggleButton from 'react-toggle-button'
import './application_test_live_config.scss';

export default class ApplicationTestLiveConfig extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      to: "",
      from: "+14243721500",
      provider: "telnyx",
      transcriber: "google",

      autopilotEnabled: false,
      company: "",
      goals: [""],
      callerInfo: [{ key: "", value: "" }],

      response: "",
      responseSpeech: "",

      testState: "idle",
      startEnabled: true,
      nextStepEnabled: false,
      respondEnabled: false,
      respondWithSpeechEnabled: false,
      finishEnabled: false,

      transcripts: {},
      currentStepNumber: null,
      result: null,

      pendingDone: false,
      openConfig: false,
      config: {},
    };

    this.stepsScrolledToBottom = true;
    this.finishing = false;
    this.finishCallback = null;

    this.stepsRef = React.createRef()
  }


  handleToChange(event) {
    let phoneNumber = new AsYouType('US')
      phoneNumber.input(event.target.value.trim())
    let to = (phoneNumber.getNumber() && phoneNumber.getNumber().number) || ""
    this.setState({to});

  }

  handleFromChange(event) {
    this.setState({from: event.target.value});
  }

  handleProviderChange(event) {
    this.setState({provider: event.target.value});
  }

  handleTranscriberChange(event) {
    this.setState({transcriber: event.target.value});
  }

  handleResponseChange(event) {
    this.setState({response: event.target.value});
  }

  handleResponseSpeechChange(event) {
    this.setState({responseSpeech: event.target.value});
  }

  handleAutopilotToggle() {
    this.setState({autopilotEnabled: !this.state.autopilotEnabled});
  }

  handleCompanyChange(event) {
    this.setState({company: event.target.value});
  }

  handleGoalChange(index, event) {
    let goals = this.state.goals;
    goals[index] = event.target.value;
    this.setState({goals: goals});
  }

  handleGoalAdd() {
    let goals = this.state.goals;
    goals.push("");
    this.setState({goals: goals});
  }

  handleGoalDelete(index) {
    let goals = this.state.goals;
    goals.splice(index, 1);
    this.setState({goals: goals});
  }

  handleCallerInfoKeyChange(index, event) {
    let callerInfo = this.state.callerInfo;
    callerInfo[index].key = event.target.value;
    this.setState({callerInfo: callerInfo});
  }

  handleCallerInfoValueChange(index, event) {
    let callerInfo = this.state.callerInfo;
    callerInfo[index].value = event.target.value;
    this.setState({callerInfo: callerInfo});
  }

  handleCallerInfoAdd() {
    let callerInfo = this.state.callerInfo;
    callerInfo.push({ key: "", value: "" });
    this.setState({callerInfo: callerInfo});
  }

  handleCallerInfoDelete(index) {
    let callerInfo = this.state.callerInfo;
    callerInfo.splice(index, 1);
    this.setState({callerInfo: callerInfo});
  }

  connect(cb) {
    if (this.socket) {
      this.socket.close();
    }

    this.socket = new WebSocket(((window.location.protocol === "https:") ? "wss://" : "ws://") + this.props.call_flow_tester_host + "/ws");
    this.socket.onerror = (error) => {
      console.log("WebSocket error: " + error);
      this.setState({
        testState: "error"
      });
      alert("Error connecting to call flow tester service. Please try again later. Contact support if you continue to experience issues.");
      this.resetControls();
    };

    this.socket.binaryType = 'arraybuffer';
    this.socket.onopen = (e) => {
        console.log("Connected to server");
        this.setState({startEnabled: true});

        // Get token from Rails and pass to the Call Flow Tester for auth
        $.ajax({
          url: `/application_tests/${this.props.application_test_id}/token`,
          type: "GET",
          dataType: "json",
          success: (data) => {
            let token = data.token;

            this.socket.send(JSON.stringify({
              type: "auth",
              token: token
            }));
          },
          error: (xhr, status, err) => {
            console.log("Error getting token: " + err);
            this.setState({
              testState: "error"
            });
            this.resetControls();

            // This should be returned on insufficient balance
            if (xhr.status == 422) {
              let error = xhr.responseJSON.error;
              alert("Error: " + error);
            } else {
              alert("Error authenticating. Please try again later. Contact support if you continue to experience issues.");
            }
          }
        });
    };

    this.player = null;

    this.socket.onmessage = (e) => {
      // check if e.data is a string
      if (typeof e.data === "string") {
        let data = JSON.parse(e.data);

        switch (data.type) {
          case "authorized":
            if (this.state.testState !== "connecting") {
              console.log("Not in connecting state, ignoring auth message");
              return;
            }

            console.log("Authorized");
            this.setState({testState: "idle"});
            if (cb) {
              cb();
            }
            break;
          case "error":
            //if (data.error == "invalid_token") {
              this.setState({testState: "error"});
              alert("Error connecting to call flow tester service. Please try again later. Contact support if you continue to experience issues.")
            //}

            break;
          case "dialing":
            console.log("Dialing");
            this.setState({testState: "dialing"});
            break;
          case "ringing":
            console.log("Ringing");
            this.setState({testState: "ringing"});
            break;
          case "answered":
            console.log("Answered");
            transcripts = {
              1: {
                text: "",
                response: ""
              }

            }
            this.setState({
              testState: "active",
              currentStepNumber: 1,
              transcripts: transcripts
            }, () => {
              this.scrollStepsToBottom();
            });
            break;
          case "transcript_updated":
            console.log(`Received transcript update: '${data.text}' currentStep: ${this.state.currentStepNumber} receivedStep: ${data.step}`);
            if (this.finishing || this.state.testState !== "active") {
              return;
            }

            let receivedStepNumber = data.step;
            let transcripts = this.state.transcripts;
            let stepAdded = false;
            if (!transcripts[receivedStepNumber]) {
              transcripts[receivedStepNumber] = {};
              stepAdded = true;
            }
            transcripts[receivedStepNumber].text = data.text;
            transcripts[receivedStepNumber].response = transcripts[receivedStepNumber].response || "";

            let currentStepNumber = this.state.currentStepNumber;
            if (receivedStepNumber > this.state.currentStepNumber) {
              currentStepNumber = receivedStepNumber;
            }
            let currentStepReceivedText = transcripts[currentStepNumber].text !== "";

            this.setState({
              transcripts: transcripts,
              currentStepNumber: currentStepNumber,
              respondEnabled: currentStepReceivedText,
              respondWithSpeechEnabled: currentStepReceivedText,
              nextStepEnabled: currentStepReceivedText
            }, () => {
              // if (stepAdded) {
                this.scrollStepsToBottom();
              // }
            });

            break;
          case "autopilot_response":
            console.log(`Received autopilot response:`, data, `currentStep: ${this.state.currentStepNumber}`);

            // check if data.response matches either {PRESS:X} or {SAY:X} where X can be any digits
            // also capture the value of X
            let matchPressDigits = data.response.match(/\{(?:PRESS):([\s\d\#\*]+)\}/);
            let matchSayDigit = data.response.match(/\{(?:SAY):\s*([\d])\s*\}/);
            let matchSayMultipleDigits = data.response.match(/\{(?:SAY):([\s\d\#\*]+)\}/);

            // check if data.response matches {SAY:X} where X can be any text
            // also capture the value of X
            let matchSay = data.response.match(/\{SAY:([^\}]+)\}/);

            if (matchPressDigits || matchSayMultipleDigits) {
              let digits = (matchPressDigits || matchSayMultipleDigits)[1].trim();

              if (this.state.respondEnabled) {
                console.log(`Saving autopilot response digits: ${digits}`);
                // this.respondWith(digits);

                this.recordResponse("dtmf", digits);
              }
            } else if (matchSay) {
              let sayText = matchSay[1].trim();

              if (this.state.respondEnabled) {
                console.log(`Saving autopilot response text: ${sayText}`);
                // this.respondWithSpeechFinish({
                //   type: "voice",
                //   value: sayText
                // });

                this.recordResponse("voice", sayText);
              }
            } else if (data.response == "{HANGUP}" || data.response == "{GOAL}" || data.response == "[END]" || data.response == "{END}") {
              console.log(`Received autopilot response with hangup or goal`);
              this.finish(true);
            }

            break;
          case "done":
            console.log("Test done", data.result_code);

            this.resetControls();

            if (!this.finishing) {
              this.convertToConfig();
            }
            this.finishing = false;

            try {
              this.socket && this.socket.close();
            } catch (e) {
              console.log("Error closing socket: " + e);
            }

            this.setState({
              result: data.result_code,
              pendingDone: false,
              testState: "idle"
            }, () => {
              this.scrollStepsToBottom();
              this.finishCallback && this.finishCallback();
            });

            break;
          default:
            console.log("unknown message type: " + data.type);
        }

        return;
      }

      let data = new Uint8Array(e.data);
      this.player && this.player.feed(data);

    };
  }

  start() {
    console.log("Clicked start");

    let transcripts = {};
    let currentStepNumber = null;
    let result = null;

    this.setState({
      transcripts: transcripts,
      currentStepNumber: currentStepNumber,
      result: result,
      startEnabled: false,
      testState: "connecting"
    }, () => {
      this.connect(() => {
        this.startTest();
      });
    });
  }

  startTest() {
    console.log("Starting live interactive test config");

    const testData = {
      "to": this.state.to,
      "from": this.state.from,
      "provider": this.state.provider,
      "transcriber": this.state.transcriber,
      "noAnswerTimeoutMilliseconds": 30 * 1000,

      "autopilotEnabled": this.state.autopilotEnabled,
      "autopilotCompany": this.state.company,
      "autopilotGoals": this.state.goals,
      "autopilotCallerInfo": this.state.callerInfo
    }

    if (!this.player) {
      this.player = new PCMPlayer({
        encoding: '16bitInt',
        channels: 2,
        sampleRate: 8000,
        delay: testData.transcriber === "google" ? 0.050 : 0.400
      });
    }

    this.socket.send(JSON.stringify({
      "type": "test",
      "test": testData
    }));

    this.setState({
      respondEnabled: false,
      respondWithSpeechEnabled: false,
      nextStepEnabled: false,
      finishEnabled: true,
      testState: "dialing"
    });
  }

  nextStep() {
    console.log("Clicked next step");
    if (!this.state.currentStepNumber) {
      return;
    }

    this.setState({
      respondEnabled: false,
      respondWithSpeechEnabled: false,
      nextStepEnabled: false
    }, () => {
      this.nextStepContinue();
    });


  }

  nextStepContinue() {
    console.log("Switching to next step");
    let transcripts = this.state.transcripts;
    transcripts[this.state.currentStepNumber] = transcripts[this.state.currentStepNumber] || {};
    transcripts[this.state.currentStepNumber].response = "";

    this.socket.send(JSON.stringify({
      "type": "respond",
      "response": {}
    }));

    transcripts[this.state.currentStepNumber + 1] = transcripts[this.state.currentStepNumber + 1] || {};
    transcripts[this.state.currentStepNumber + 1].text = "";
    transcripts[this.state.currentStepNumber + 1].response = "";

    this.setState({
      transcripts: transcripts,
      currentStepNumber: this.state.currentStepNumber + 1,
      respondEnabled: false,
      respondWithSpeechEnabled: false,
      nextStepEnabled: false
    }, () => {
      this.scrollStepsToBottom();
    });
  }

  respond() {
    if (!this.state.currentStepNumber) {
      return;
    }

    this.respondWith(this.state.response);

  }

  respondWith(response) {
    if (!this.state.currentStepNumber) {
      return;
    }

    this.setState({
      respondEnabled: false,
      respondWithSpeechEnabled: false,
      nextStepEnabled: false
    }, () => {
      this.respondContinue(response);
    });

  }

  respondContinue(responseValue) {
    let response = {};
    if (responseValue) {
      response.type = "dtmf";
      response.value = responseValue;
    }

    let transcripts = this.state.transcripts;
    transcripts[this.state.currentStepNumber] = transcripts[this.state.currentStepNumber] || {};
    transcripts[this.state.currentStepNumber].response = responseValue || "";
    transcripts[this.state.currentStepNumber].responseType = "dtmf";

    this.socket.send(JSON.stringify({
      "type": "respond",
      "response": response
    }));

    transcripts[this.state.currentStepNumber + 1] = transcripts[this.state.currentStepNumber + 1] || {};
    transcripts[this.state.currentStepNumber + 1].text = "";
    transcripts[this.state.currentStepNumber + 1].response = "";

    this.setState({
      transcripts: transcripts,
      currentStepNumber: this.state.currentStepNumber + 1,
      response: "",
      respondEnabled: false,
      respondWithSpeechEnabled: false,
      nextStepEnabled: false
    }, () => {
      this.scrollStepsToBottom();
    });
  }

  recordResponse(type, value) {
    let transcripts = this.state.transcripts;
    transcripts[this.state.currentStepNumber] = transcripts[this.state.currentStepNumber] || {};
    transcripts[this.state.currentStepNumber].response = value || "";
    transcripts[this.state.currentStepNumber].responseType = type;

    transcripts[this.state.currentStepNumber + 1] = transcripts[this.state.currentStepNumber + 1] || {};
    transcripts[this.state.currentStepNumber + 1].text = "";
    transcripts[this.state.currentStepNumber + 1].response = "";

    this.setState({
      transcripts: transcripts,
      currentStepNumber: this.state.currentStepNumber + 1,
      response: "",
      respondEnabled: false,
      respondWithSpeechEnabled: false,
      nextStepEnabled: false
    }, () => {
      this.scrollStepsToBottom();
    });
  }

  respondWithSpeech() {
    if (!this.state.currentStepNumber) {
      return;
    }

    this.setState({
      respondEnabled: false,
      respondWithSpeechEnabled: false,
      nextStepEnabled: false
    }, () => {
      this.respondWithSpeechContinue();
    });

  }

  respondWithSpeechContinue() {
    let responseValue = this.state.responseSpeech;
    let response = {};
    if (responseValue) {
      response.type = "voice";
      response.value = responseValue;
    }

    this.respondWithSpeechFinish(response);
  }

  respondWithSpeechFinish(response) {
    let transcripts = this.state.transcripts;
    transcripts[this.state.currentStepNumber] = transcripts[this.state.currentStepNumber] || {};
    transcripts[this.state.currentStepNumber].response = response.value || "";
    transcripts[this.state.currentStepNumber].responseType = "voice";

    this.socket.send(JSON.stringify({
      "type": "respond",
      "response": response
    }));

    transcripts[this.state.currentStepNumber + 1] = transcripts[this.state.currentStepNumber + 1] || {};
    transcripts[this.state.currentStepNumber + 1].text = "";
    transcripts[this.state.currentStepNumber + 1].response = "";

    this.setState({
      transcripts: transcripts,
      currentStepNumber: this.state.currentStepNumber + 1,
      responseSpeech: "",
      respondEnabled: false,
      respondWithSpeechEnabled: false,
      nextStepEnabled: false
    }, () => {
      this.scrollStepsToBottom();
    });
  }

  close(cb) {
    console.log("Modal closed");
    if (!this.state.finishEnabled && !this.finishing && !this.finishCallback) {
      console.log("OK to close");
      cb && cb();
    }

    console.log("Not ready to close, setting callback");
    this.finishCallback = cb;

    if (this.finishing) {
      // already waiting for test to finish
      return;
    }

    this.finish(false);
  }

  finish(generateConfig) {
    console.log("Finishing test, with config:", !!generateConfig);

    this.resetControls();

    this.finishing = true;
    this.socket && this.socket.send(JSON.stringify({
      "type": "finish"
    }));

    this.setState({
      pendingDone: true,
      testState: "hanging_up"
    }, () => {
      if (!generateConfig) {
        return;
      }

      this.convertToConfig();
    });
  }

  convertToConfig() {
    let transcripts = this.state.transcripts;
    if (transcripts[this.state.currentStepNumber] && !transcripts[this.state.currentStepNumber].text) {
      delete transcripts[this.state.currentStepNumber];
    }

    if (Object.keys(transcripts).length === 0) {
      this.setState({
        transcripts: transcripts,
        currentStepNumber: null
      });

      return;
    }

    let config = {
      name: "New Test",
      description: "",
      steps: []
    };

    for (let stepNumber in transcripts) {
      let step = transcripts[stepNumber];
      config.steps.push({
        match: step.text,
        match_language: "en-US",
        response: step.response,
        response_type: step.responseType || "dtmf"
      });
    }

    this.setState({
      transcripts: transcripts,
      config: config,
      openConfig: true,
      // transcripts: {},
      currentStepNumber: null
    });
  }

  getConfigFromStep(step) {
    return {
      match: step.text,
      match_language: "en-US",
      response: step.response,
      response_type: step.responseType
    }
  }

  closeModal() {
    this.setState({
        openConfig: false
    })
  }

  handleStepsScroll() {
    if (!this.stepsRef.current) { return; }

    // Detect if scrolled to bottom of the steps transcript section
    // We use this to disable auto-scrolling to bottom when the user scrolls up
    let scrollTop = this.stepsRef.current.scrollTop;
    let scrollHeight = this.stepsRef.current.scrollHeight;
    let clientHeight = this.stepsRef.current.clientHeight;
    this.stepsScrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
  }

  scrollStepsToBottom(force) {
    if (this.stepsRef.current && (this.stepsScrolledToBottom || force === true)) {
      this.stepsRef.current.scroll({ top: this.stepsRef.current.scrollHeight, behavior: 'smooth' });
    }
  }

  resetControls() {
    this.setState({
      startEnabled: true,
      nextStepEnabled: false,
      respondEnabled: false,
      respondWithSpeechEnabled: false,
      finishEnabled: false
    });
  }

  getControlStates() {
    if (this.state.autopilotEnabled) {
      switch (this.state.testState) {
        case "idle":
        case "error":
          return {
            // visible: {
            //   to: true,
            //   start: true
            // },
            visible: {
              to: true,
              autopilotSettings: true,
              finish: true
            },
            enabled: {
              to: true,
              autopilotSettings: true,
              start: true
            }
          };
        case "connecting":
          return {
            // visible: {
            //   to: true,
            //   start: true
            // },
            visible: {
              to: true,
              autopilotSettings: true,
              finish: true
            },
            enabled: {}
          };
        case "dialing":
        case "ringing":
          return {
            // visible: {
            //   to: true,
            //   finish: true
            // },
            visible: {
              to: true,
              autopilotSettings: true,
              finish: true
            },
            enabled: {
              finish: true
            }
          };
        case "active":
          return {
            visible: {
              to: true,
              autopilotSettings: true,
              finish: true
            },
            enabled: {
              finish: this.state.finishEnabled
            }
          };
        case "hanging_up":
          return {
            // visible: {
            //   to: true,
            //   finish: true
            // },
            visible: {
              to: true,
              autopilotSettings: true,
              finish: true
            },
            enabled: {
            }
          };
        default:
          return {
            // visible: {
            //   to: true,
            //   start: true
            // },
            visible: {
              to: true,
              autopilotSettings: true,
              finish: true
            },
            enabled: {
              to: true,
              start: true,
              autopilotSettings: true,
            }
          };
      }
    }


    switch (this.state.testState) {
      case "idle":
      case "error":
        return {
          // visible: {
          //   to: true,
          //   start: true
          // },
          visible: {
            to: true,
            nextStep: true,
            keypad: true,
            respond: true,
            respondWithSpeech: true,
            finish: true
          },
          enabled: {
            to: true,
            start: true
          }
        };
      case "connecting":
        return {
          // visible: {
          //   to: true,
          //   start: true
          // },
          visible: {
            to: true,
            nextStep: true,
            keypad: true,
            respond: true,
            respondWithSpeech: true,
            finish: true
          },
          enabled: {}
        };
      case "dialing":
      case "ringing":
        return {
          // visible: {
          //   to: true,
          //   finish: true
          // },
          visible: {
            to: true,
            nextStep: true,
            keypad: true,
            respond: true,
            respondWithSpeech: true,
            finish: true
          },
          enabled: {
            finish: true
          }
        };
      case "active":
        return {
          visible: {
            to: true,
            nextStep: true,
            keypad: true,
            respond: true,
            respondWithSpeech: true,
            finish: true
          },
          enabled: {
            nextStep: this.state.nextStepEnabled,
            respond: this.state.respondEnabled,
            respondWithSpeech: this.state.respondWithSpeechEnabled,
            finish: this.state.finishEnabled
          }
        };
      case "hanging_up":
        return {
          // visible: {
          //   to: true,
          //   finish: true
          // },
          visible: {
            to: true,
            nextStep: true,
            keypad: true,
            respond: true,
            respondWithSpeech: true,
            finish: true
          },
          enabled: {
          }
        };
      default:
        return {
          // visible: {
          //   to: true,
          //   start: true
          // },
          visible: {
            to: true,
            nextStep: true,
            keypad: true,
            respond: true,
            respondWithSpeech: true,
            finish: true
          },
          enabled: {
            to: true,
            start: true
          }
        };
    }
  }

  getPendingStatusDisplay(testState) {
    switch (testState) {
      case "idle":
        if (this.state.result) {
          return "Test completed";
        } else if (Object.keys(this.state.transcripts).length === 0) {
          return "Waiting for test to connect";
        }
        return null;
      case "connecting":
      case "dialing":
        return "Dialing ...";
      case "ringing":
        return "Ringing ...";
      case "active":
        return "Connected";
      // case "error":
      //   return "Error running test";
      // case "hanging_up":
      //   return null;
      default:
        return null;
    }
  }

  getResultDisplay(result) {
    if (!result) {
      return null;
    }

    switch (result) {
      case "normal":
        if (Object.keys(this.state.transcripts).length === 0) {
          return "Hung up, no steps executed.";
        } else {
          return "Hung up"
        }
      case "call_hangup":
        if (Object.keys(this.state.transcripts).length === 0) {
          return "Hung up, no steps executed.";
        } else {
          return "IVR hung up";
        }
      case "test_step_timeout":
      case "test_timeout":
        return "Timeout";
      case "test_failed_dialing":
        return "Failed dialing";
      case "failed_dialing_invalid":
        return "Invalid number";
      case "failed_dialing_busy":
        return "Busy";
      case "carrier_internal_error":
        return "Error dialing";
      default:
        return "Error";
    }
  }

  render() {
    let transcripts = this.state.transcripts;
    let controlStates = this.getControlStates();
    let isTestActive = this.state.testState === "active";
    let statusDisplay = this.getPendingStatusDisplay(this.state.testState);
    let resultDisplay = this.getResultDisplay(this.state.result);
    let showStatusDisplay = (!resultDisplay && !isTestActive && statusDisplay !== null);
    return (

      <div>
          <FormModal
              status="new"
              type="Test Case"
              title="New Test Case"
              action={`/application_tests/${this.props.application_test_id}/test_cases`}
              open={this.state.openConfig && !this.state.pendingDone}
              backdrop="static"
              closeFunction={this.closeModal.bind(this)}
              model={this.state.config}
          >
            <ApplicationTestConfiguration {...this.props} model={this.state.config} />
          </FormModal>

        <div style={{
          display: "inline-block",
          width: "calc(100% - 350px)",
          verticalAlign: "top",
          padding: "5px 40px 0px 20px"
        }}>
            {/* <div style={{marginBottom: "10px"}}>Status: {this.state.testState}</div> */}

            <div className={`test-live-config__pending-status${showStatusDisplay ? "" : " no-height"}`}>
              <div className={`test-live-config__pending-status__text${showStatusDisplay ? "" : " no-height"}`}>
                {/* We use previous status here so that the 0 height css animation works correctly when hiding the display */}
                {statusDisplay}
              </div>
            </div>

            <div style={{
                height: showStatusDisplay ? "calc(100vh - 240px)" : "calc(100vh - 210px)",
                overflowX: "hidden",
                overflowY: "scroll",
              }} ref={this.stepsRef}
              onScroll={this.handleStepsScroll.bind(this)}
            >
                {Object.keys(transcripts).map((stepNumber) => {
                  return <ApplicationTestLiveConfigStep key={stepNumber}
                            {...this.props}
                            {...this.state}
                            stepNumber={stepNumber}
                            step={this.getConfigFromStep(transcripts[stepNumber])}
                          />
                })}

                {resultDisplay &&
                  <div className={`test-live-config__result`}>
                    <div className={`test-live-config__result__text`}>
                      {resultDisplay}
                    </div>
                  </div>
                }
            </div>
        </div>


        <div className="test-live-config__controls">
          {/* <div style={{marginBottom: "10px"}}>Status: {this.state.testState}</div> */}
          <ApplicationTestLiveConfigControls {...this.state} {...this.props} parent={this} controlStates={controlStates} />
        </div>
      </div>
    )
  }
}

class ApplicationTestLiveConfigControls extends React.Component {

  constructor(props) {
    super(props);

    let parent = this.props.parent;

    this.handleToChange = parent.handleToChange.bind(parent);
    this.handleFromChange = parent.handleFromChange.bind(parent);
    this.handleResponseChange = parent.handleResponseChange.bind(parent);
    this.handleResponseSpeechChange = parent.handleResponseSpeechChange.bind(parent);
    this.handleTranscriberChange = parent.handleTranscriberChange.bind(parent);
    this.handleProviderChange = parent.handleProviderChange.bind(parent);
    this.handleAutopilotToggle = parent.handleAutopilotToggle.bind(parent);
    this.handleGoalChange = parent.handleGoalChange.bind(parent);
    this.handleGoalAdd = parent.handleGoalAdd.bind(parent);
    this.handleGoalDelete = parent.handleGoalDelete.bind(parent);
    this.handleCompanyChange = parent.handleCompanyChange.bind(parent);
    this.handleCallerInfoKeyChange = parent.handleCallerInfoKeyChange.bind(parent);
    this.handleCallerInfoValueChange = parent.handleCallerInfoValueChange.bind(parent);
    this.handleCallerInfoAdd = parent.handleCallerInfoAdd.bind(parent);
    this.handleCallerInfoDelete = parent.handleCallerInfoDelete.bind(parent);

    this.start = parent.start.bind(parent);
    this.nextStep = parent.nextStep.bind(parent);
    this.respond = parent.respond.bind(parent);
    this.respondWithSpeech = parent.respondWithSpeech.bind(parent);
    this.finish = parent.finish.bind(parent);

  }

  render() {
    let controlState = this.props.controlStates;

    return (<div>
      {controlState.visible.to &&
        <div className="settings-section form-inline">
          <div className="row" style={{padding: "0px 15px"}}>

            {this.props.autopilot_enabled &&
              <div style={{marginBottom: "5px"}}>
                <ToggleButton
                  value={ this.props.autopilotEnabled || false }
                  activeLabel={''}
                  inactiveLabel={''}

                  colors={{
                      active: {
                          base: "#0073b7"
                      }
                  }}

                  containerStyle={{
                      width: "30px",
                      marginRight: "6px",
                      display: "inline-block",
                      verticalAlign: "text-top"
                  }}

                  thumbStyle={{
                      borderRadius: 20,
                      width: 15,
                      height: 16 - 4,
                      borderColor: 'transparent',
                      boxShadow: 'none'
                  }}
                  trackStyle={{
                      width: 30,
                      height: 16,
                      borderRadius: 20
                  }}
                  thumbAnimateRange={[2, 13]}
                  activeLabelStyle=  {{ width:'20px', fontWeight: 'bold' }}
                  inactiveLabelStyle={{ width:'20px', fontWeight: 'bold' }}
                  onToggle={this.handleAutopilotToggle.bind(this)}
                />
                <span>Autopilot Mode <span style={{
                  verticalAlign: "super",
                  fontSize: "10px",
                  color: "#666"
                }}><i className="fa fa-flask"></i> <span style={{ fontSize: "12px"}}>BETA</span></span></span>
              </div>
            }

            <div className="form-group" style={{display: "flex"}}>
              <label htmlFor="to" className="test-live-config__label--input">
                To
              </label>
              <input
                  placeholder="XXXXXXXXXX"
                  className="form-control test-live-config__input" style={{
                    flexGrow: 1, width: "100%", borderRadius: "0px", borderRight: "none", borderLeftColor: "#efefef"
                  }}
                  type="text" id="to" name="to" value={this.props.to} disabled={!controlState.enabled.to}
                  onChange={this.handleToChange} />
              <button type="button"
                  id="start" disabled={!controlState.enabled.start} onClick={this.start}
                  className="test-live-config__button test-live-config__button--input test-live-config__start-button" >

                <i className="fa fa-play" style={{marginRight: "8px", fontSize: "11px"}}></i>
                <span>Start</span>
              </button>
            </div>
            {/* <label htmlFor="from">From:</label>
            <input type="text" id="from" name="from" value="+14243721500" readOnly /> */}
          </div>

          {/* <div>
            <label htmlFor="provider">Provider:</label>
            <select id="provider" name="provider" value={this.props.provider}
                    onChange={this.handleProviderChange}>
                <option value="twilio">Twilio</option>
                <option value="telnyx">Telnyx</option>
            </select>

            <label htmlFor="transcriber">Transcriber:</label>
            <select id="transcriber" name="transcriber" value={this.props.transcriber}
                    onChange={this.handleTranscriberChange}>
                <option value="assemblyai">AssemblyAI</option>
                <option value="google">Google</option>
            </select>
          </div> */}

        </div>
      }

      {/* {controlState.visible.start &&
        <div className="controls-section">
          <button type="button" id="start" disabled={!controlState.enabled.start} onClick={this.start}
                  className="test-live-config__button" style={{width: "100%"}}>
            Start
          </button>
        </div>
      } */}



      {controlState.visible.autopilotSettings &&
        <div>
          <div className="controls-section">
            <label htmlFor="company">Company</label>
            <input type="text" placeholder="The caller is calling ..." id="company" value={this.props.company} disabled={!controlState.enabled.autopilotSettings}
                onChange={this.handleCompanyChange}
                className="form-control test-live-config__input--no-button" style={{width: "calc(100%)", display: "inline-block"}} />
          </div>

          <div className="controls-section">
            <label htmlFor="goal">Goals</label>

            {this.props.goals.map((goal, index) => {
              return <div>
                <input key={index} type="text" placeholder="The caller's goal is to ..." id="goal" value={goal} disabled={!controlState.enabled.autopilotSettings}
                  onChange={(event) => this.handleGoalChange(index, event)}
                  className="form-control test-live-config__input--no-button" style={{width: "calc(100% - 20px)", display: "inline-block"}} />

                {controlState.enabled.autopilotSettings &&
                  <i className="fa fa-trash" onClick={() => { this.handleGoalDelete(index) }} style={{
                      marginLeft: "4px",
                      fontSize: "10px",
                      color: "#444",
                      cursor: "pointer"
                  }}></i>
                }
              </div>
            })}

            {controlState.enabled.autopilotSettings &&
              <button type="button" id="add-goal" onClick={this.handleGoalAdd}
                      className="test-live-config__button" style={{width: "100%", marginTop: "5px", padding: "6px"}}>

                + Add Goal
              </button>
            }
          </div>

          <label>Caller Info</label>
        </div>
      }
      {controlState.visible.autopilotSettings && this.props.callerInfo.map((info, index) => {
          return (
            <div className="controls-section" key={`info-${index}`}>
              <input type="text" placeholder="Account Number" id={`info-key-${index}`} value={info.key} disabled={!controlState.enabled.autopilotSettings}
                  onChange={event => this.handleCallerInfoKeyChange(index, event)}
                  className="form-control test-live-config__input--no-button" style={{width: "calc(50% - 12px)", display: "inline-block"}} />

              {" : "}
              <input type="text" placeholder="1234567890" id={`info-value-${index}`} value={info.value} disabled={!controlState.enabled.autopilotSettings}
                  onChange={event => this.handleCallerInfoValueChange(index, event)}
                  className="form-control test-live-config__input--no-button" style={{width: "calc(50% - 12px)", display: "inline-block"}} />

              {controlState.enabled.autopilotSettings &&
                <i className="fa fa-trash" onClick={() => { this.handleCallerInfoDelete(index) }} style={{
                    marginLeft: "4px",
                    fontSize: "10px",
                    color: "#444",
                    cursor: "pointer"
                }}></i>
              }
            </div>
          )
        })
      }

      {controlState.enabled.autopilotSettings &&
        <button type="button" id="add-caller-info" onClick={this.handleCallerInfoAdd}
                className="test-live-config__button" style={{width: "100%", marginTop: "5px", padding: "6px"}}>

          + Add Info
        </button>
      }

      {controlState.visible.nextStep &&
        <div className="controls-section">
            <button type="button" id="continue" disabled={!this.props.nextStepEnabled} onClick={this.nextStep}
                    className="test-live-config__button" style={{width: "100%", marginTop: "5px"}}>
              <MoveDownIcon style={{
                width: "22px",
                marginTop: "-1px",
                marginBottom: "-7px",
                marginRight: "7px",
                transform: "scaleX(-1)"
              }} />
              Next Step
            </button>
        </div>
      }


      {controlState.visible.keypad &&
        <PhoneKeypad onPress={this.props.parent.respondWith.bind(this.props.parent)} disabled={!this.props.respondEnabled} />
      }
      <div>
      {controlState.visible.respond &&
        <div className="controls-section">
          <input type="text" placeholder="Enter digits to press ..." id="response" value={this.props.response} onChange={this.handleResponseChange}
                  className="form-control test-live-config__input" style={{width: "calc(100% - 60px)", display: "inline-block"}} />
          <button type="button" id="respond" disabled={!this.props.respondEnabled} onClick={this.respond}
                  className="test-live-config__button test-live-config__button--input" style={{width: "60px"}}>
            Press
          </button>
        </div>
      }
      {controlState.visible.respondWithSpeech &&
        <div className="controls-section">
          <input type="text" placeholder="Enter text to speak ..." id="response-tts" value={this.props.responseSpeech} onChange={this.handleResponseSpeechChange}
                  className="form-control test-live-config__input" style={{width: "calc(100% - 60px)", display: "inline-block"}} />
          <button type="button" id="respond-tts" disabled={!this.props.respondWithSpeechEnabled} onClick={this.respondWithSpeech}
                  className="test-live-config__button test-live-config__button--input" style={{width: "60px"}}>
            Say
          </button>
        </div>
      }



      {controlState.visible.finish &&
        <div className="controls-section">
            <button type="button" id="finish" disabled={!this.props.finishEnabled} onClick={() => this.finish(true)}
                    className="test-live-config__button test-live-config__finish-button" style={{width: "100%", marginTop: "10px"}}>
              <i className="fa fa-stop" style={{marginRight: "8px", fontSize: "12px"}}></i>
              Hang Up
            </button>
        </div>
      }
      </div>

    </div>)
  }
}
class ApplicationTestLiveConfigStep extends React.Component {

  constructor(props) {
    super(props);

    this.textareaRef = React.createRef();
  }

  render() {
    let stepNumber = this.props.stepNumber;
    let step = this.props.step;
    let currentStepNumber = this.props.currentStepNumber || Object.keys(this.props.transcripts).length;
    let isLastInSeries = (stepNumber == currentStepNumber) || step.response;
    let isCurrent = (stepNumber == this.props.currentStepNumber);

    return (
      <div className="row">
        <div className="col-sm-12" >

          <ChatBubble side="left" last={isLastInSeries}>
            <div>
              {step.match ?
                <div>
                  <span style={{marginRight: isCurrent ? "10px" : "inherit" }}>
                    {step.match}
                  </span>
                  {isCurrent &&
                    <ScaleLoader height={7} color="rgba(0,0,0,0.65)" cssOverride={{
                      height: "15px", display: "inline-block"
                    }}/>
                  }
                </div>
              : this.props.testState == "active" &&
                <ScaleLoader height={7} color="rgba(0,0,0,0.65)" cssOverride={{
                  height: "16px"
                }}/>
              }
            </div>
          </ChatBubble>
        </div>

        {step.response &&
          <div className="col-sm-12" style={{margin: "3px 0px"}}>

            <ChatBubble side="right" style={
              this.props.autopilotEnabled ? {
                width: "calc(100% - 40px)",
                display: "inline-block"
              } : {}
            }>
              <div className="test-live-config__chat-bubble__heading">
                {step.response_type == "dtmf" ?
                  <div>
                    <span>press</span>
                  </div>
                : <div>
                    <span>say</span>
                  </div>
                }
              </div>

              <div className='test-live-config__chat-bubble__content'>
                {step.response_type == "dtmf" ?
                  step.response.split("").map((digit, index) => {
                    return <span key={index} className="test-live-config__chat-bubble__digit">
                      {digit}
                    </span>
                  })
                  : <div>
                    {step.response}
                  </div>
                }
              </div>
            </ChatBubble>

            {this.props.autopilotEnabled &&
              <span style={{
                margin: "0px 4px 0px 4px",
                fontSize: "30px",
                verticalAlign: "text-top"
              }}>🤖</span>
            }

          </div>
        }
      </div>
    );
  }
}
