import React from "react";
import {
  ApplicationTestScheduleFormModalButton,
  ApplicationTestScheduleNewFormModalButton,
} from "../../../../application_test_schedule";
import "moment";
import moment from "moment-timezone";
import SimpleTable from "../../../../table/simple-table";
import VigilusButton from "../../../../button/vigilus-button";

export default class CallFlowTesterSchedules extends React.Component {
  constructor(props) {
    super(props);

    let org_tz = props.timezone || "America/Denver";
    moment.tz.setDefault(org_tz);
  }

  componentDidMount() {
    return $("#application-test-schedules-table").DataTable({
      order: [[0, "asc"]],
      processing: false,
      paging: false,
      pageLength: 100,
      ordering: true,
      searching: false,
      autoWidth: false,
      columnDefs: [
        {
          targets: 3,
          orderable: false,
        },
      ],
    });
  }

  runSchedule(scheduleId) {
    console.log("s");
    $.ajax({
      method: "POST",
      url: `/application_tests/${this.props.application_test_id}/schedules/${scheduleId}/run`,
      dataType: "json",
      contentType: "application/json",
      success: (data) => {
        console.info("Started test schedule:", data);
        alert(data.result);
      },
      error: (req, status, err) => {
        console.error(
          "Error running test schedule:",
          status,
          req && req.status,
          err
        );
      },
    });
  }

  render() {
    let allTestCases =
      this.props.application_test_configuration?.test_definition || [];

    let rows = [];

    this.props.application_test_schedules.forEach((schedule, i) => {
      let frequencyString = "";
      if (schedule.frequency_quantity == "1") {
        frequencyString = `Every ${schedule.frequency_period}`;
      } else {
        frequencyString = `Every ${schedule.frequency_quantity} ${schedule.frequency_period}s`;
      }
      let weekArr = [];
      {
        [
          "sunday",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
        ].map((key, index) => {
          if (schedule[key]) {
            let day = key.charAt(0).toUpperCase() + key.slice(1);

            weekArr.push(day.slice(0, 2));
          }
        });
      }

      let selectedTestCaseNames = schedule.test_cases
        .map((testCaseId) => {
          let testCase = allTestCases.find((tc) => tc.id == testCaseId);
          return testCase?.name;
        })
        .filter((name) => name); // Filters out nulls

      let testCaseDisplay = "";
      if (selectedTestCaseNames.length > 0) {
        testCaseDisplay = selectedTestCaseNames.join(", ");
      } else {
        testCaseDisplay = <i>Any</i>;
      }

      rows.push(
        <tr key={schedule.id}>
          <td className="p-4">{frequencyString}</td>
          <td className="p-4">{schedule.enabled ? "Enabled" : "Disabled"}</td>
          <td className="p-4 break-after-auto max-w-[150px]">
            {(schedule.at && schedule.at.replace(/\*\*/g, "").replace(/,/g, ", ")) || "-"}
          </td>
          <td className="p-4">{schedule.endpoint}</td>
          <td className="p-4">{testCaseDisplay}</td>
          <td className="p-4">
            {schedule.all_day
              ? "All Day"
              : `${moment(schedule.time_starts_at)
                  .tz("UTC")
                  .format("h:mm A")} - ${moment(schedule.time_ends_at)
                  .tz("UTC")
                  .format("h:mm A")}`}
          </td>
          <td className="p-4">{weekArr.join(", ")}</td>
          <td className="p-4 flex items-center gap-2">
            {this.props.readonly ? null : (
              <VigilusButton
                secondary
                onClick={this.runSchedule.bind(this, schedule.id)}
              >
                Run Now
              </VigilusButton>
            )}

            {!this.props.readonly && (
              <ApplicationTestScheduleFormModalButton
                status={this.props.readonly ? "show" : "edit"}
                model={schedule}
                secondary={true}
                {...this.props}
              />
            )}

            {this.props.readonly ? null : (
              <a // TODO this should be using VigilusButton
                className="bg-transparent rounded-md p-2 h-full text-red-700 hover:text-red-700 hover:bg-red-100 border border-[#b91c1c] text-lg"
                data-confirm="Are you sure you want to delete this schedule?"
                rel="nofollow"
                data-method="delete"
                href={"/application_test_schedules/" + schedule.id}
              >
                Delete
              </a>
            )}
          </td>
        </tr>
      );
    });

    return (
      <div className="flex flex-col gap-4 py-4">
        {!this.props.readonly ? (
          <div className="flex items-center justify-end px-8">
            <ApplicationTestScheduleNewFormModalButton
              status="new"
              {...this.props}
            />
          </div>
        ) : null}
        <SimpleTable
          id="application-test-schedules-table"
          headers={[
            "Frequency",
            "Enabled",
            "At",
            "To",
            "Test Cases",
            "Start Time / End Time",
            "Days Scheduled",
            "Actions",
          ]}
        >
          {rows}
        </SimpleTable>
      </div>
    );
  }
}
