import React from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

export class AssignCredentialFormModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: true,
            name: props.model ? props.model.name : "",
            
            installedApplications: [],
            installedApplicationsLoading: true,

            deviceHostnames: [],
            deviceHostnamesLoading: true,

            applicationTypes: [],
            applicationTypesLoading: true,

            pluginTypes: [],
            pluginTypesLoading: true,
        }
    }

    componentDidMount() {
        this.getApplicationTypes.bind(this)();
        this.getInstances.bind(this)();
        this.getDeviceHostnames.bind(this)();
        this.getPluginTypes.bind(this)();
    }

    getApplicationTypes() {
        return $.ajax({
            method: 'GET',
            url: '/application_types',
            dataType: 'json',
            success: (data) => {
                let applicationTypeList = [];
                data.forEach(appType => {
                    applicationTypeList.push(appType.name);
                });
                this.setState({
                    applicationTypes: applicationTypeList,
                    applicationTypesLoading: false
                });
            },
            error: (err) => {
                console.log("Failed to retrieve application types:", err);
            }
        });
    }

    getInstances() {
        return $.ajax({
            method: 'GET',
            url: '/installed_applications',
            dataType: 'json',
            success: (data) => {
                let instanceList = new Set();
                data.forEach(app => {
                    instanceList.add(app.display_name);
                });
                this.setState({
                    installedApplications: Array.from(instanceList),
                    installedApplicationsLoading: false
                });
            },
            error: (err) => {
                console.error("Failed to retrieve instances:", err);
            }
        });
    }

    getDeviceHostnames() {
        return $.ajax({
            method: 'GET',
            url: '/devices',
            dataType: 'json',
            success: (data) => {
                let hostnameList = [];
                data.forEach(device => {
                    hostnameList.push(device.host_name);
                });
                hostnameList = hostnameList.sort();
                this.setState({
                    deviceHostnames: hostnameList,
                    deviceHostnamesLoading: false
                });
            },
            error: (err) => {
                console.error("Failed to retrieve devices:", err);
            }
        });
    }

    getPluginTypes() {
        return $.ajax({
            method: "GET",
            url: "/credentials/plugin_types",
            success: (data) => {
                this.setState({
                    pluginTypes: data["plugin_types"],
                    pluginTypesLoading: false
                });
            }
        });
    }

    handleApplicationTypeChange(applicationType) {
        this.setState({applicationType});
    }

    handleInstanceChange(instance) {
        this.setState({instance});
    }

    handleHostnameChange(hostname) {
        this.setState({hostname});
    }

    handleTypeChange(type) {
        this.setState({type});
    }

    handlePluginChange(plugin) {
        this.setState({plugin});
    }

    launchSync(event) {
        console.log("Launching sync");
        $.ajax({
            method: 'GET',
            url: "//" + this.props.engineUrl + "/v1" + this.props.action + "/launch_sync",
            success: () => {console.log("Launched sync")}
        });
    }

    launchRestart(event) {
        console.log("Launching restart");
        $.ajax({
            method: 'GET',
            url: "//" + this.props.engineUrl + "/v1" + this.props.action + "/restart_collector",
            success: () => {console.log("Launched restart")}
        });
    }

    render() {
        // let siteOptions = this.state.sites.map(function(site, index){
        //     return(
        //         <option key={index} value={site.id}>{site.name}</option>
        //     )
        // });
        return (
            <div>
                <label htmlFor="name">Credential</label>
                <br/>
                <input type="text"
                        name="credential[name]"
                        className="form-control"
                        readOnly={true}
                        value={this.props.name}
                        />
                <br/>

                <div>
                    <label htmlFor="credential[application]">Application Type</label>
                    <br/>
                    <Select name="credential[application]"
                            isLoading={this.state.applicationTypesLoading}
                            isDisabled={this.state.applicationTypesLoading}
                            onChange={this.handleApplicationTypeChange.bind(this)}
                            value={this.state.applicationType}
                            defaultValue={{label: "Any", value: ""}}
                            components={{ IndicatorSeparator: null }} // don't show border next to dropdown arrow
                            options={[{label: "Any", value: ""}].concat(this.state.applicationTypes.map(appType => {
                                return {label: appType, value: appType}
                            }))}
                    />
                    <br/>
                </div>

                <div>
                    <label htmlFor="credential[instance]">Instance</label>
                    <br/>
                    <Select name="credential[instance]"
                            isLoading={this.state.installedApplicationsLoading}
                            isDisabled={this.state.installedApplicationsLoading}
                            onChange={this.handleInstanceChange.bind(this)}
                            value={this.state.instance}
                            defaultValue={{label: "Any", value: ""}}
                            components={{ IndicatorSeparator: null }} // don't show border next to dropdown arrow
                            options={[{label: "Any", value: ""}].concat(this.state.installedApplications.map((app) => {
                                return { label: app, value: app }
                            }))}
                    />
                    <br/>
                </div>

                <div>
                    <label htmlFor="credential[hostname]">Hostname</label>
                    <br/>
                    <Select name="credential[hostname]"
                            isLoading={this.state.deviceHostnamesLoading}
                            isDisabled={this.state.deviceHostnamesLoading}
                            onChange={this.handleHostnameChange.bind(this)}
                            value={this.state.hostname}
                            defaultValue={{label: "Any", value: ""}}
                            components={{ IndicatorSeparator: null }} // don't show border next to dropdown arrow
                            options={
                                [{value: "", label: "Any"}].concat(
                                    this.state.deviceHostnames.map((hostname, index) => {
                                        return {value: hostname, label: hostname}                                                
                                    })
                                )                                    
                            }
                    />
                    <br/>
                </div>

                <div>
                    <label htmlFor="type">Type</label>
                    <br/>
                    <Creatable
                        required
                        name="credential[type]"
                        value={this.state.type}
                        onChange={this.handleTypeChange.bind(this)}
                        defaultValue={{label: "Any", value: ""}}
                        options={[
                            { value: "", label: "Any" },
                            { value: "diag", label: "CCE Diagnostic Portico" },
                            { value: "config", label: "CCE Configuration API" },
                            { value: "admin", label: "Web Administrator" },
                            { value: "wsmadmin", label: "CVP wsmadmin API" },
                            { value: "ssh", label: "SSH / CLI" },
                            { value: "soap", label: "SOAP API" },
                            { value: "sql", label: "SQL Server" },
                            { value: "serviceability", label: "SocialMiner Serviceability API" }

                        ]}
                        components={{ IndicatorSeparator: null }} // don't show border next to dropdown arrow
                        classNamePrefix="Select-Component"
                        formatCreateLabel={(text) => `Custom "${text}"`}
                        styles={{
                            clearIndicator: provided => ({
                                ...provided,
                                padding: "5px 0px",
                                width: "16px"
                            }),
                            dropdownIndicator: provided => ({
                                ...provided,
                                padding: "5px 4px 5px 0px"
                            })
                        }}
                    />
                    <br />
                </div>

                <div>
                    <label htmlFor="plugin">Plugin</label>
                    <br />
                    <Select 
                        name="credential[plugin]"
                        isLoading={this.state.pluginTypesLoading}
                        isDisabled={this.state.pluginTypesLoading}
                        onChange={this.handlePluginChange.bind(this)}
                        value={this.state.plugin}
                        defaultValue={{label: "Any", value: ""}}
                        components={{ IndicatorSeparator: null }} // don't show border next to dropdown arrow
                        options={[{label: "Any", value: ""}].concat(this.state.pluginTypes.map((app) => {
                            return { label: app, value: app }
                        }))}
                    />
                    <br />
                </div>
            </div>
        )
    }
}

export default AssignCredentialFormModal;
