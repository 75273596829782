import React, { useState } from "react";
import { useForm } from "react-hook-form";
import LoginFormWrapper from "../components/login-flow-wrapper";
import FormLabel from "../components/form-label";
import LoginFlowLinks from "../components/login-flow-links";
import LoginFlowCard from "../components/login-flow-card";
import VigilusButton from "../../button/vigilus-button";

const ForgotPasswordForm = ({ authenticity_token, onSuccess }) => {
  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = handleSubmit(async (data) => {
    $.ajax({
      method: "POST",
      url: "/users/password",
      data: {
        "user[email]": data.email.toLowerCase(),
        authenticity_token: authenticity_token,
        commit: "Send me reset password instructions",
      },
      success: function () {
        onSuccess();
      },
      error: function (err) {
        console.log("error: ", err.responseText);
        setError(err.responseText);
      },
    });
  });

  return (
    <LoginFormWrapper>
      <LoginFlowCard error={error}>
        <div className="text-4xl font-medium text-gray-500">
          Forgot your password?
        </div>
        <form onSubmit={onSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-0">
            <FormLabel label="Email" />
            <input
              type="email"
              name="email"
              className="form-control rounded-md"
              placeholder="Email"
              {...register("email")}
            />
          </div>

          <VigilusButton type="submit" className="py-3 px-4 border-none">
            <span className="w-full text-xl">
              Send me password reset instructions
            </span>
          </VigilusButton>

          <LoginFlowLinks currentView="new-password" />
        </form>
      </LoginFlowCard>
    </LoginFormWrapper>
  );
};
export default ForgotPasswordForm;
