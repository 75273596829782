import React, { useRef } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import VigilusChartContainer from './vigilus_chart_container';
import DatePickerManager from './date_picker_manager';
import { DocumentDownloadIcon } from '../packs/icons/icons'
import "moment";
import moment from "moment-timezone";

class ReportTabs extends React.Component {

    constructor(props) {
        super(props);

        let now = moment();

        this.state = {
            now: now,
            startDate: now.clone().startOf('day'),
            endDate: now.clone().endOf('day'),
            showThresholdIndicators: true,
            tabIndex: this.updateTabIndex()
        }

        this.reports = [];

        this.updateTabIndex = this.updateTabIndex.bind(this)
    }

    updateTabIndex() {
        let urlInfo = window.location.href;
        let totalTabs = this.props.chartGroups;
        let currIndex;
        for (let i = 0; i < totalTabs.length; i++) {
            if (totalTabs[i].name.indexOf(' ') >= 0) {
                urlInfo = urlInfo.replaceAll("%20", " ")
            }
            if (urlInfo.includes(totalTabs[i].name)) {
                currIndex = i
            }
        }
        if (currIndex === undefined) {
            currIndex = 0;
        }

        return currIndex;
    }

    setTabIndex(index) {
        this.setState({
            tabIndex: index
        });
    }

    syncState(childState) {
        this.setState({
            now: childState.now,
            startDate: childState.startDate,
            endDate: childState.endDate
        });
    }

    callUpdate(childState) {
        this.setState({
            now: childState.now,
            startDate: childState.startDate,
            endDate: childState.endDate
        }, () => {
            let reports_proxy = [];
            for (let i=0; i<this.reports.length; i++) {
                if (this.reports[i]._isMounted) {
                    this.reports[i].update();
                    reports_proxy.push(this.reports[i]);
                }
            }

            this.reports = reports_proxy;
        });
    }

    subscribeToUpdate(containerObj) {
        this.reports.push(containerObj);
    }

    render() {
        var tabs = []
        var tabPanels = []
        $.each(this.props.chartGroups, (i, group) => {
            tabs.push(
                <Tab key={i} style={{cursor: "pointer"}}>
                    <a href={"#" + group.name}>{group.name}</a>
                </Tab>
            )

            var charts = group.charts.map(chart => {
                return <VigilusChartContainer subscribe={this.subscribeToUpdate.bind(this)} key={group.name + "_" + chart.report_id} application_types={group.application_types} definition={group.definition} {...chart} {...this.state} />
            });

            tabPanels.push(
                <TabPanel key={i} style={{marginTop: '20px'}}>
                    {charts}
                </TabPanel>
            )
        });

        return (
            <div>
                <DatePickerManager callUpdate={this.callUpdate.bind(this)} syncState={this.syncState.bind(this)} />
                
                <button className="header-button header-button-thin"
                    style={{
                        float: "right",
                        marginRight: "12px"
                    }}
                    onClick={() => window.exportDeviceData(this)}
                >
                    <DocumentDownloadIcon style={{height: "20px", marginRight: "4px"}}/>
                    <span>Export</span>
                </button>

                {FeatureFlags.thresholds_in_charts &&
                    <label style={{ marginLeft: "5px", float: "right", marginTop: "5px", marginRight: "20px" }}>
                        <input
                            type="checkbox"
                            style={{ marginRight: "5px", verticalAlign: "top" }}
                            checked={this.state.showThresholdIndicators}
                            onChange={event => {
                                console.log(event.target.checked);
                                this.setState({
                                    showThresholdIndicators: event.target.checked
                                });
                            }}
                        />
                        Show Threshold Indicators
                    </label>
                }
                
                <Tabs selectedIndex={this.state.tabIndex} onSelect={this.setTabIndex.bind(this)}>
                    <TabList>
                        {tabs}
                    </TabList>
                    {tabPanels}
                </Tabs>
            </div>
        )
    }
}

export default ReportTabs;
