import React from 'react'
import NotesModal from './notes_modal.jsx'
import "moment";
import moment from "moment-timezone";

class Alarm extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showNotes: false,
      isMuting: false
    };
    this.handleCheckedChange = this.handleCheckedChange.bind(this);
  }

  handleCheckedChange(event) {
    const target = event.target;
    const value = target.checked;

    if(value) {
      this.props.handleChecked(this.props.id)
    } else {
      this.props.handleUnchecked(this.props.id)
    }
  }

  toggleMute() {
    
    if(this.props.acknowledged_at) {
      let path = "/active_alarms/" + this.props.id + "/unacknowledge"
      $.ajax({
        url: path,
        success: (response) => { location.reload() },
        error: (error) => { console.log(error) },
        method: "POST"
      })
    } else {
      this.setState({isMuting: true})
    }

  }

  clearAlarm(event) {
    let button = event.target;
    if (button.disabled === false) {
      button.disabled = true;
      $.ajax({
        method: "GET",
        url: "/active_alarms/" + this.props.id + "/clear",
        success: (response) => { location.reload(); },
        error: (error) => {
          button.disabled = "";
          console.log(error);
        }
      });
    }
  }

  showNotes() {
    this.setState({showNotes: true})
  }


  handleClose() {
    this.setState({showNotes: false, isMuting: false})
  }


  handleSaveNotes(notes) {
    console.log('Handling save notes', notes)

    let path = "/active_alarms/" + this.props.id + "/notes"
    $.ajax({
      data: {notes: notes},
      url: path,
      success: (response) => {location.reload() },
      error: (error) => { console.log(error) },
      method: "POST"
    })
  }

  handleSubmitMuteWithNotes(notes) {
    console.log('Handling submit mute with notes', notes)

    let path = "/active_alarms/" + this.props.id + "/acknowledge"
    $.ajax({
      data: {notes: notes},
      url: path,
      success: (response) => { location.reload() },
      error: (error) => { console.log(error) },
      method: "POST"
    })
  }


  render() {
    let iconColor = "#f39c12"
    if (["error", "critical", "alert", "emergency"].indexOf(this.props.severity) > -1) {
      iconColor = "#dd4b39"
    }

    let acknowledgeButton
    let mutedNotice
    let buttonName    = "Mute"
    let iconClassName = "alert-box-button-icon icon fa fa-eye-slash"

    if(this.props.acknowledged_at) {
      buttonName    = "Unmute"
      iconClassName = "alert-box-button-icon icon fa fa-eye"
    }
    if (this.props.allowAcknowledge && this.props.active) {
      acknowledgeButton =
        <a className="btn btn-md alert-box-button"
          rel="nofollow"
          onClick={this.toggleMute.bind(this)}>
          <i className={iconClassName}></i>{buttonName}
        </a>
    }

    if (this.props.acknowledged_at && this.props.active && !this.props.canManageOrganization) {
      mutedNotice = <span className="muted-text-only"><i className="alert-box-button-icon icon fa fa-eye-slash"></i>Muted</span>
    }

    let clearButton
    if (this.props.allowClear && this.props.active) {
      clearButton = 
        <button
          className="btn btn-md alert-box-button"
          onClick={this.clearAlarm.bind(this)}
        >
          <i className="alert-box-button-icon icon fa fa-ban"></i>Clear
        </button>
    }

    let notesButton
    if(this.props.acknowledged_at) {
      notesButton = <a style={{float: "right", marginRight: "10px", marginTop: "12px", cursor: "pointer"}} 
          rel="nofollow"
          onClick={this.showNotes.bind(this)}>
          Notes
        </a>
    }

    let submitMethod = this.handleSaveNotes.bind(this)
    if(this.state.isMuting) {
      submitMethod = this.handleSubmitMuteWithNotes.bind(this)
    }

    return(
      <li className="alert-box">
        <NotesModal id={this.props.id} isMuting={this.state.isMuting} submitMethod={submitMethod} handleClose={this.handleClose.bind(this)} show={this.state.showNotes || this.state.isMuting} notes={this.props.notes} canManageOrganization={this.props.canManageOrganization}/>
        <div className="alert-box-buttons-large">
          {clearButton}
          {acknowledgeButton}
          {mutedNotice}
          {notesButton}
        </div>
        <div className="alert-box-icon">
          {this.props.allowClear ? 
            <input
              name="isChecked"
              type="checkbox"
              checked={this.props.isChecked}
              onChange={this.handleCheckedChange}
              style={{marginTop: "55%", marginLeft: "5px"}} 
            />
            :
            <span></span>
          }
          <i className="icon fa fa-exclamation-circle alarm" style={{color: iconColor}}></i>
        </div>
        <div className="alert-box-info" style={{marginLeft: "70px"}}>
            <div><strong>{moment(this.props.created_at).tz(this.props.timeZone).format('MMM DD')}</strong> {moment(this.props.created_at).tz(this.props.timeZone).format('h:mm:ss A z')} (<a href={"/alarms/" + this.props.alarm_id}>Details</a>)</div>
            <div className="breakSpaces"><strong>{this.props.message}</strong></div>
            <div>{this.props.name}</div>
            <div style={{marginTop: "10px"}}><strong>Recommended Action:</strong> {this.props.action || "None"}</div>
        </div>
        <div className="alert-box-buttons-small">
          {clearButton}
          {acknowledgeButton}
          {notesButton}
        </div>
      </li>
    );
  }
}

export default Alarm;
