import React, { useState } from "react";
import { useForm } from "react-hook-form";
import SelfServeFlowWrapper from "./components/layout/self-serve-flow-wrapper";
import SelfServeFlowCard from "./components/layout/self-serve-flow-card";
import CallFlowTesterSelfServeSignupForm from "./components/signup-form/signup-form";
import VigilusButton from "../button/vigilus-button";
import AddPaymentMethodForm from "./components/stripe/add-payment-method-form";
import CallFlowTesterSelfServeSuccess from "./components/success/success";
import CallFlowTesterSelfServeDisclaimer from "./components/disclaimer/disclaimer";
import FormStepper from "./components/form-stepper/form-stepper";
import CallFlowTesterSelfServeConfirm from "./components/confirm/confirm";

const CallFlowTesterSelfServeSignup = ({ authenticity_token }) => {
  const urlParamStep = window.location.search.split("&")[0].replace("?", "");
  const [signupStep, setSignupStep] = useState(urlParamStep || "initial"); // initial | form | disclaimer | stripe

  const steps = [
    {
      label: "The Basics",
      value: "form",
    },
    {
      label: "Disclaimer",
      value: "disclaimer",
    },
    {
      label: "Verification",
      value: "stripe",
    },
    {
      label: "Success",
      value: "success",
    },
  ];

  const currentStepIndex =
    steps.findIndex((step) => step.value === signupStep) + 1;

  const [error, setError] = useState(null);
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
    watch,
  } = useForm();

  const isNextDisabled =
    !watch("firstName") ||
    !watch("lastName") ||
    !watch("email") ||
    !watch("orgName");

  return (
    <SelfServeFlowWrapper>
      {signupStep !== "initial" && (
        <FormStepper steps={steps} currentStepIndex={currentStepIndex} />
      )}

      <SelfServeFlowCard>
        {signupStep === "initial" && (
          <div className="flex flex-col gap-6 items-center w-full text-center">
            <div>
              <p>
                Vigilus makes it easy to proactively test and ensure your IVR
                system delivers a seamless and consistent customer experience,
                detecting issues before they impact your&nbsp;customers.
              </p>
              <p>Try the demo and get $5 in credits to start.</p>
            </div>
            <VigilusButton
              type="button"
              className="py-3 px-4 border-none w-full"
              onClick={() => setSignupStep("form")}
            >
              <span className="w-full text-xl">Get Started</span>
            </VigilusButton>
          </div>
        )}
        {signupStep === "form" && (
          <CallFlowTesterSelfServeSignupForm
            register={register}
            isNextDisabled={isNextDisabled}
            onSubmit={() => setSignupStep("disclaimer")}
          />
        )}
        {signupStep === "disclaimer" && (
          <CallFlowTesterSelfServeDisclaimer
            onSuccess={() => setSignupStep("stripe")}
          />
        )}
        {signupStep === "stripe" && (
          <AddPaymentMethodForm
            authenticity_token={authenticity_token}
            customer={watch()}
            onSuccess={() => setSignupStep("confirm")} 
          />
        )}
        {signupStep === "confirm" && (
          <CallFlowTesterSelfServeConfirm
            authenticity_token={authenticity_token}
            customer={watch()}
            onSuccess={() => setSignupStep("success")}
          />
        )}
        {signupStep === "success" && <CallFlowTesterSelfServeSuccess />}
      </SelfServeFlowCard>
    </SelfServeFlowWrapper>
  );
};
export default CallFlowTesterSelfServeSignup;
