import React from 'react';
import "moment";
import moment from "moment-timezone";
import DateRangePicker from 'react-daterangepicker-cloverhound';
import { CalendarIcon } from '../packs/icons/icons'

class DatePickerManager extends React.Component {

	constructor(props) {
		super(props);

		let now = moment();

		this.state = {
			now: now,
			startDate: now.clone().startOf('day'),
            endDate: now.clone().endOf('day')
		}

		props.syncState(this.state);
	}

	rangeUpdated(event, picker) {
		let start = picker.startDate.clone();
        let end = picker.endDate.clone();

	    this.setState({
		    startDate: start,
            endDate: end,
        }, () => {
        	this.props.callUpdate(this.state);
        });
	}

	render() {
		let startDate = this.state.startDate;
	    let endDate = this.state.endDate;
        let now = this.state.now;
        let dateRangeFormat = "MM/DD/YYYY h:mm A z";

		return (
			<DateRangePicker
              //onEvent={this.rangeUpdated.bind(this)}
              onApply={this.rangeUpdated.bind(this)}
              startDate={startDate}
              endDate={endDate}

              ranges={{
                  'Today': [now.clone().startOf('day'), now.clone().endOf('day')],
                  'Yesterday': [now.clone().subtract(1, 'days').startOf('day'), now.clone().startOf('day')],
                  'Last 7 Days': [now.clone().subtract(6, 'days').startOf('day'), now.clone().endOf('day')],
                  'Last 30 Days': [now.clone().subtract(29, 'days').startOf('day'), now.clone().endOf('day'),],
                  'This Month': [now.clone().startOf('month'), now.clone().endOf('month')],
                  'Last Month': [now.clone().subtract(1, 'month').startOf('month'), now.clone().subtract(1, 'month').endOf('month')]
                 // 'Last 5 Minutes': [now.clone().subtract(5, 'minutes'), now],
                 // 'Last Hour': [now.clone().subtract(1, 'hours'), now],
                 // 'Today': [now.clone().startOf('day'), now.clone().endOf('day')],
                 // 'Yesterday': [now.clone().subtract(1, 'days').startOf('day'), now.clone().subtract(1, 'days').endOf('day')]
              }}

              timePicker={true}
              maxSpan={{ months: 3 }}

              opens="center"

              locale={{
                  format: "MM/DD/YYYY h:mm A",
                  "separator": " - ",
                  "applyLabel": "Apply",
                  "cancelLabel": "Cancel",
                  "fromLabel": "From",
                  "toLabel": "To",
                  "customRangeLabel": "Custom",
                  "weekLabel": "W",
                  "daysOfWeek": [
                      "Su",
                      "Mo",
                      "Tu",
                      "We",
                      "Th",
                      "Fr",
                      "Sa"
                  ],
                  "monthNames": [
                      "January",
                      "February",
                      "March",
                      "April",
                      "May",
                      "June",
                      "July",
                      "August",
                      "September",
                      "October",
                      "November",
                      "December"
                  ],
                  "firstDay": 0
              }}
          	>
              <button
                data-toggle="tooltip"
                className="header-button header-button-thin"
              >
                <CalendarIcon style={{height: "20px"}}/>
                <span style={{marginLeft: "5px"}}>
                    {startDate.format(dateRangeFormat) +
                    " - " +
                    endDate.format(dateRangeFormat)}
                </span>
              </button>

          	</DateRangePicker>
		)
	}
}

export default DatePickerManager
