import React from "react";

const PageHeader = ({ title, backButton }) => {
  return (
    <section className="content-header">
      <div className="flex flex-col gap-2">
        <h1 className="text-5xl m-0">{title}</h1>
        {backButton && (
          <div className="flex">
            <a
              href={backButton.path}
              className="flex items-center gap-2 text-[1rem] text-gray-500 hover:text-gray-500 hover:underline"
            >
              <i className="fas fa-arrow-left"></i>
              {backButton.text}
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default PageHeader;
