import React from 'react';
import "moment";
import moment from "moment-timezone";
import { ComposedChart,
          Bar,
          Line,
          Area,
          XAxis,
          Tooltip,
          CartesianGrid,
          YAxis
      } from 'recharts';
import sizeMe from 'react-sizeme';

const CHART_LEGEND_PADDING = 60;
window.moment = moment;

const labelFormatter = (tick) => moment(tick).format('MM/DD/YYYY HH:mm');
const itemSorter = (x, y) => (y && x && y.value-x.value) || 0;
const tickFormatter = (tick) => moment(tick).format('MM/DD HH:mm');
const tooltipFormatter = (value, name, props) => {
    if (props.payload[props.dataKey + "-configuredValue"]) {
      return props.payload[props.dataKey + "-configuredValue"] + " / " + toFixedIfNecessary(value, 2);
    }

    return toFixedIfNecessary(value, 2);
}

function toFixedIfNecessary( value, dp ){
  return +parseFloat(value).toFixed( dp );
}

function byteFormatter(number) {
  if(number >= 1000000000){
    return (number/1000000000).toString() + ' GB';
  }else if(number >= 1000000){
    return (number/1000000).toString() + ' MB';
  }else if(number >= 1000){
    return (number/1000).toString() + ' KB';
  }else{
    return number.toString() + ' B';
  }
}

function secondsFormatter(number) {
  if(number >= 86400){
    return (number/86400).toFixed(2).toString() + ' d';
  }else if(number >= 3600){
    return (number/3600).toFixed(2).toString() + ' h';
  }else if(number >= 60){
    return (number/60).toFixed(2).toString() + ' m';
  }else if (number >= 1){
    return number.toFixed(2).toString() + ' s';
  }else{
    return (number*1000).toFixed(0).toString() + ' ms';
  }
}

class GenericChart extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      legendWidth: 0
    };
  }

  getSubtypeModified() {
    let subtype_modified = this.props.subtype || this.props.data_name;
    if (subtype_modified === "CPUTotal" || subtype_modified === "CPU Performance") {
      subtype_modified = "_cpuUsage";
    } else if (subtype_modified.includes("InstallMax") || subtype_modified === "Hard Disk Capacity") {
      subtype_modified = ["_storagePct", "_storageMB"];
    } else if (subtype_modified.includes("MemoryMax") || subtype_modified === "Total Memory") {
      subtype_modified = ["_memoryPct", "_memoryMB"];
    }

    return subtype_modified;
  }

  // Get a ref to the custom legend element and dynamically adjust the size of the chart
  // to make room for the legend. The out-of-box recharts legend does this, but our custom
  // version needs tooltip data in it too so we have to create it as a special custom tooltip
  // instead of the built-in <Legend />
  getLegendRef() {
    if (!this.ref) {
      this.ref = React.createRef();
      this.startLegendWidthInterval();
    }

    return this.ref;
  }

  startLegendWidthInterval() {
    if (this.getLegendWidthInterval) {
      return;
    }

    this.getLegendWidthInterval = setInterval(() => {
      if (!this.ref?.current) {
        return;
      }

      if (this.ref.current.getBoundingClientRect().width == this.state.legendWidth) {
        return;
      }

      console.log("Setting legend width:", this.ref.current.getBoundingClientRect().width + 30);
      clearInterval(this.getLegendWidthInterval);
      this.setState({ legendWidth: this.ref.current.getBoundingClientRect().width + 30 });
    }, 100);
  }


  render() {
    if (this.props.data.length == 0) {
      return emptyPanel(this.props.loading ? "" : "No data found for this metric.", this.props);
    }

    if (this.props.dataError) {
      return emptyPanel("Error retrieving data.", this.props);
    }

    let unitFormatter = null;
    let unit = this.props.unit;
    if (unit == "B") {
      unit = "";
      unitFormatter = byteFormatter;
    } else if (unit == "s") {
      unit = "";
      unitFormatter = secondsFormatter;
    }

    let dataSets = this.props.dataSets || Object.keys(this.props.data[0]);
    // Will hold an array of data items and their associated color to pass to our custom tooltip
    let dataInfo = [];

    let chartDisplays = dataSets.map((type, index) => {
      if (type === "name") { return; }
      if (type.endsWith("-threshold")) { return; }
      if (type.endsWith("-threshold%")) { return; }

      if (this.props.hiddenData.has(type)) {
        return;
      }

      let metaData = this.props.metaData[type] || this.props.metaData['default'] || {};

      let colorInfo;
      if (index < this.props.colors.length) {
        colorInfo = this.props.colors[index];
      } else {
        colorInfo = this.props.colors[this.props.colors.length - 1];
      }
      colorInfo = colorInfo || {};

      let color = colorInfo["color"];
      let fill = colorInfo["fill"];

      let dashArray = metaData.dashArray || "";
      let strokeWidth = metaData.width || 1;

      let name = type;

      dataInfo.push({
        name: name,
        color: color
      });

      let connectNulls = metaData.connect_nulls === false ? false : true;
      let stackId = this.props.stackedMode ? "1" : null;

      switch (metaData.type) {
        case "area":
          return <Area
                strokeDasharray={dashArray}
                strokeWidth={strokeWidth}
                name={name}
                animationDuration={400}
                connectNulls={connectNulls}
                key={index}
                type="step"
                dataKey={type}
                stroke={color}
                fill={fill}
                unit={unit}
                stackId={stackId}
                dot={(props) => {
                  // Renders a dot mimicking the active dot if an entry is selected
                  if (props?.payload?.name == this.state.selectedLabel) {
                    return <circle style={{fillOpacity: 90}} {...props} r={5} stroke={"#fff"} fill={color} strokeWidth={2} />;
                  }

                  return null;
                }}

              />;
        case "bar":
          return <Bar
                name={name}
                animationDuration={400}
                key={index}
                dataKey={type}
                fill={color}
                unit={unit}
                stackId={stackId}
              />;
        default:
          return <Line
                  strokeDasharray={dashArray}
                  strokeWidth={strokeWidth}
                  name={name} animationDuration={400}
                  connectNulls={connectNulls}
                  key={index}
                  type="step"
                  dataKey={type}
                  unit={unit}
                  stroke={color}
                  dot={false}
                />;
      }
    });

    let maxData = "auto"
    if(this.props.data_max){
      maxData = this.props.data_max
    }

    let syncId = null;
    if (this.props.sync === true) {
      syncId = "sync";
    }

    let data_copy = JSON.parse(JSON.stringify(this.props.data));

    if (FeatureFlags.thresholds_in_charts && this.props.showThresholdIndicators && this.props.alarmThresholds) {
      for (const threshold of this.props.alarmThresholds) {
        let thresholdUnit = this.props.unit;

        let stroke = "orange";
        if (threshold.severity == "error") {
          stroke = "#da6657";
        }

        if (!this.props.hiddenData.has(threshold.name + "-threshold")) {
          chartDisplays.push(
            <Line strokeDasharray={"5 5"}
                  strokeWidth={1} name={threshold.name + " Threshold"}
                  animationDuration={400}
                  connectNulls={true}
                  key={threshold.id}
                  type="step"
                  dataKey={threshold.name + "-threshold"}
                  unit={thresholdUnit}
                  stroke={stroke}
                  dot={false}
            />
          );
        }

        if (!this.props.hiddenData.has(threshold.name + "-threshold%")) {
          maxData = 100;
          chartDisplays.push(
            <Area //strokeDasharray={"5 5"}
                  strokeWidth={1} name={threshold.name + " Threshold %"}
                  animationDuration={400}
                  connectNulls={true}
                  key={threshold.id + "%"}
                  type="step"
                  dataKey={threshold.name + "-threshold%"}
                  unit="%"
                  stroke={stroke}
                  fill={stroke}
                  dot={false}
            />
          );
        }
      }

    }

    let legendWidth = this.state.legendWidth;
    let showLegend = false;
    let chartWidth = this.props.size.width;
    // When the list of data items gets too long, the default tooltip is too awkward to be
    // usable. If that's the case, we'll statically put the tooltip items to the right of the
    // chart, and customize it to act both as a tooltip and a legend
    if (dataSets.length > 11) {
      showLegend = true;
      // Legend should never take up more than 30% of the chart width
      if (legendWidth / this.props.size.width > 0.30) {
        legendWidth = 0.30 * this.props.size.width;
      }
      chartWidth = this.props.size.width - legendWidth - CHART_LEGEND_PADDING
    }

    // Properties to apply custom tooltip props if we are showing it as a static legend
    let customTooltipProps = showLegend ? {
      wrapperStyle: {
        height: '183px',
        visibility: legendWidth > 0 ? "visible" : "hidden"
      },
      position: { x: this.props.size.width - legendWidth - CHART_LEGEND_PADDING + 10, y: 0 }, // move to the right a bit
      content: <TooltipContent dataInfo={dataInfo} myRef={this.getLegendRef.apply(this)} width={legendWidth} selectedLabel={this.state.selectedLabel} selectedPayload={this.state.selectedPayload} />
    } : {}

    return(
      <div>
        <ComposedChart width={chartWidth} height={200} syncId={syncId}
          data={data_copy}
          barGap={0}
          barCategoryGap={-0.2}
          onClick={showLegend && ((props) => {
            if (props?.activeLabel && this.state.selectedLabel != props?.activeLabel) {
              // console.log("Label selected", props?.activeLabel);
              this.setState({ selectedLabel: props?.activeLabel, selectedPayload: props?.activePayload });
            } else if (props?.activeLabel == this.state.selectedLabel) {
              // console.log("Label deselected", props?.activeLabel);
              this.setState({ selectedLabel: null, selectedPayload: null });
            }
          })}
        >

          <CartesianGrid  vertical={false} stroke="#F1F1F1" strokeDasharray="100 0"/>

          <YAxis tickLine={{ stroke: "#DDD" }} axisLine={{ stroke: "#BBB" }} domain={[0, maxData]} tickFormatter={unitFormatter} unit={unit}/>
          <XAxis tickLine={{ stroke: "#DDD" }} axisLine={{ stroke: "#BBB" }} dataKey="name" scale="time" type="number" tickFormatter={tickFormatter} domain={['dataMin', 'dataMax']} />
          {chartDisplays}
          <Tooltip labelFormatter={labelFormatter} itemSorter={itemSorter} formatter={unitFormatter || tooltipFormatter}
            {...customTooltipProps}
          />

        </ComposedChart>
      </div>
    )
  }
}

function emptyPanel(message, props){
  return(
    <div>
        <div style={{height: "200px"}}>
          <p>{message}</p>
        </div>
    </div>
  )
}

export default sizeMe()(GenericChart)

class TooltipContent extends React.Component {
  render() {
    let active = false;
    // props.payload is automatically injected by Recharts and contains the list
    // of data and values to be displayed by the tooltip. We use this to add values
    // to any items that have it. Otherwise we just display the name as a legend.
    let payload = this.props.payload || [];
    if (payload.length > 0) {
      active = true;
    }

    let finalLabel = this.props.labelFormatter(this.props.label, this.props.payload);
    if (!active && this.props.selectedLabel){
      finalLabel = this.props.labelFormatter(this.props.selectedLabel, this.props.selectedPayload);
      payload = this.props.selectedPayload || [];
    }

    let values = {};
    for (const entry of payload) {
      values[entry.name] = {
        value: entry.value,
        color: entry.color
      }
    }

    return (<div ref={this.props.myRef} style={{backgroundColor: "#fff", padding: this.props.width ? "inherit" : "10px"}}>
      <div style={{height: '20px'}}>{finalLabel}</div>
      <div style={{
        width: this.props.width ? this.props.width + 50 : 'auto',
        height: '157px',
        overflow: 'auto',
        textOverflow: 'clip',
        whiteSpace: 'nowrap',
        pointerEvents: 'auto',
      }}>
      {this.props.dataInfo.map((data, i) => {
        let name = data.name;
        let entry = values[name];

          return (
            <TooltipItem key={`tooltip-item-${i}`} name={name} entry={entry} color={data.color} />
          );

        })
      }
      </div>
    </div>)
  }
}
class TooltipItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
  }

  handleClick() {
    // future use
    // this.setState({
    //   show: !this.state.show
    // });
  }

  render() {
    const finalItemStyle = {
      display: 'block',
      paddingTop: 4,
      paddingBottom: 4,
      color: this.props.color || '#000'
    };

    if (!this.state.show) {
      finalItemStyle.color = 'grey';
    }

    return <li className="recharts-tooltip-item" style={finalItemStyle}
      onClick={this.handleClick.bind(this)}
    >
      {this.props.name ? <span className="recharts-tooltip-item-name">{this.props.name}</span> : null}
      {this.props.entry ? <span className="recharts-tooltip-item-value">{" : " + this.props.entry.value}</span> : null}
      <span className="recharts-tooltip-item-unit">{this.props.entry && this.props.entry.unit || ''}</span>
    </li>
  }
}
