
// Loops over the key/value pairs, passes them to the callback, and returns the results as an array.
export const objectMap = (obj, fn) => {
  return Object.entries(obj).map(
    ([k, v], i) => fn(k, v, i)
  )
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
