import React from "react";

const SimpleTable = ({ id, headers, children }) => {
  return (
    <div className="overflow-x-auto">
      <table id={id} className="rounded-lg">
        <thead>
          <tr className="bg-[#F5F6FB]">
            {headers.map((header, index) => (
              <th
                key={index}
                className="p-4"
                style={{
                  borderBottom: "1px solid #083B87",
                  borderRadius:
                    index === 0
                      ? "5px 0 0 0"
                      : index === headers.length - 1
                      ? "0 5px 0 0"
                      : "0",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default SimpleTable;
