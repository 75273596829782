import React from "react";
import './chat_bubbles.scss';

export class ChatBubble extends React.Component {
  render() {
    let side = this.props.side || 'left';

    return (
      <div
        className={`chat-bubble__container chat-bubble__container--${side} ${this.props.last ? 'chat-bubble__container--last' : ''}`}
        style={this.props.style || {}}
      >
        <div className={`chat-bubble chat-bubble--${side}`}>
          <div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
