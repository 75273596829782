import React from 'react';
import { capitalize } from '../utils';
import AddPaymentMethodForm from './billing_payment_method_form';
import FormModalButton from './form_modal_button';
import "./billing_subscriptions.scss";

export default class BillingPaymentMethodsList extends React.Component {

  render() {
    return (
      <div>
        <div style={{ marginBottom: "20px" }}>
          <h4>Payment Methods</h4>
        </div>

        <div style={{ marginBottom: "30px" }}>
          {this.props.payment_methods == null || this.props.payment_methods.length == 0 ?
            <p>No payment methods saved</p>
            :
            <PaymentMethodsList {...this.props} />
          }
        </div>

        <FormModalButton
              className="payment-methods__add-button"
              type="Payment Methods"
              status="new"
              label="Add Payment Method"
              form_title="Add Payment Method"
              action={`/billing/subscriptions/${this.props.subscription.id}}`}
              modalSize="medium"
              noFooter={true}
            >
              <AddPaymentMethodForm {...this.props} />
            </FormModalButton>
      </div>
    );
  }
}

class PaymentMethodsList extends React.Component {
  render() {
    return (
      <div className="" style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px"
      }}>
        {this.props.payment_methods.map((payment_method, index) => {
          return (
            <div key={payment_method.id} style={{
              display: "flex",
              // gap: "50px",
              alignItems: "center",
              padding: "20px",
              border: "1px solid #ecf0f5",
              borderRadius: "5px"
            }}>
              <div style={{ flexBasis: "200px" }}>
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <span style={{ alignSelf: "center" }}>
                    <img src={payment_method.icon_path}
                      alt={payment_method.brand_label}
                      style={{ width: "40px" }}
                    />
                  </span>
                  <span style={{ fontSize: "1.2rem" }}>{payment_method.brand_label}</span>
                </div>
                <div>
                  <span style={{ fontSize: "2rem" }}>···· ···· ···· {payment_method.payment_details.last4}</span>
                </div>


              </div>
              <div style={{ flexBasis: "50px" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontSize: "1.1rem" }}>Expires</span>
                  <span style={{ fontSize: "1.5rem" }}>{payment_method.payment_details.exp_month}/{payment_method.payment_details.exp_year.toString().slice(-2)}</span>
                </div>
              </div>
              {!this.props.readOnly &&
                <div style={{ flexGrow: 1, textAlign: "end" }}>
                  <span>
                    <a href={`/billing/payment_methods/${payment_method.id}`}
                      data-confirm={`Are you sure you want to remove the payment method "${capitalize(payment_method.payment_details.brand)} ${payment_method.payment_details.last4}"?`}
                      data-method="delete"
                      rel="nofollow"
                    >
                      Remove
                    </a>
                  </span>

                </div>
              }
            </div>
          );
        })}
      </div>
    );
  }
}

