import React from "react";

const StatusCard = ({ icon, count, status }) => {
  function getIconColor() {
    if (count === 0 || count === undefined) return "text-gray-500";
    switch (status) {
      case "Passing":
        return "text-green-500";
      case "Failed":
        return "text-red-500";
      default:
        return "text-primary";
    }
  }

  const countAsPossiblyUndefined = count > 0 ? count : 0;

  return (
    <div
      className="flex flex-col gap-4 items-center justify-center rounded-md py-12 text-2xl font-medium bg-white"
      style={{ border: `1px solid #d2d6de` }}
    >
      <div
        className={`text-4xl ${getIconColor(status, countAsPossiblyUndefined)}`}
      >
        {icon}
      </div>
      <div>
        {countAsPossiblyUndefined} {status}
      </div>
    </div>
  );
};
export default StatusCard;
