import React from "react";
import {
  ApplicationTestConfigNewFormDropdownButton,
  ApplicationTestConfigFormModalButton,
  ApplicationTestConfigNewFormModalButton,
  ApplicationTestConfigCopyFormModalButton,
  ApplicationTestConfigUploadModalButton,
} from "./call-flow-tester/individual-test/tabs/configuration/application_test_config_modal_button";
import UploadTestFormModal from "../packs/modals/upload_test_form_modal";

export default class ApplicationTestConfigurationList extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    return $("#application-test-configs-table").DataTable({
      order: [[0, "asc"]],
      processing: false,
      paging: true,
      pageLength: 100,
      ordering: true,
      searching: false,
      autoWidth: false,
      columnDefs: [
        {
          targets: 2,
          orderable: false,
        },
      ],
    });
  }

  render() {
    let testCaseCopy;
    let rows = [];
    this.props.application_test_configuration &&
      this.props.application_test_configuration.test_definition.forEach(
        (testCase, i) => {
          testCaseCopy = Object.assign({}, testCase);
          testCaseCopy.name = "";
          testCaseCopy.id = "";
          rows.push(
            <tr key={testCase.id}>
              <td>{testCase.name}</td>
              <td>{testCase.description}</td>
              <td style={{ minWidth: "250px" }}>
                <ApplicationTestConfigFormModalButton
                  status={this.props.readonly ? "show" : "edit"}
                  model={testCase}
                  {...this.props}
                />
                <ApplicationTestConfigCopyFormModalButton
                  status="copy"
                  model={testCaseCopy}
                  {...this.props}
                />
                <a
                  className="btn btn-sm btn-info"
                  data-method="get"
                  style={{ marginLeft: "5px" }}
                  href={`/application_tests/${this.props.application_test_id}/application_test_configuration/${this.props.application_test_configuration.id}/test_cases/${testCase.id}`}
                >
                  <i
                    className="fas fa-file-export"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Export
                </a>

                {this.props.readonly ? null : (
                  <a
                    className="btn btn-sm btn-danger"
                    style={{ marginLeft: "5px" }}
                    data-confirm="Are you sure you want to delete this test case?"
                    rel="nofollow"
                    data-method="delete"
                    href={`/application_tests/${this.props.application_test_id}/test_cases/${testCase.id}`}
                  >
                    <i className="fa fa-trash"></i>
                  </a>
                )}
              </td>
            </tr>
          );
        }
      );

    return (
      <div>
        <section
          className="content-header"
          style={{ paddingTop: "0px", paddingBottom: "10px" }}
        >
          <h2
            style={{ display: "inline-block", fontSize: "20px", margin: "0px" }}
          >
            Test Cases
          </h2>
          {!this.props.readonly ? (
            <div
              style={{
                display: "inline-block",
                marginLeft: "10px",
                verticalAlign: "super",
              }}
            >
              {this.props.live_config_enabled ? (
                <ApplicationTestConfigNewFormDropdownButton {...this.props} />
              ) : (
                <ApplicationTestConfigNewFormModalButton
                  status="new"
                  {...this.props}
                />
              )}
            </div>
          ) : null}
          <div
            style={{
              display: "inline-block",
              marginLeft: "5px",
              verticalAlign: "super",
              position: "relative",
              top: "1px",
            }}
          >
            <UploadTestFormModal
              url={`/application_tests/${application_test_id}/upload`}
              {...this.props}
            />
          </div>
        </section>
        <div className="box box-body">
          <div className="table-responsive">
            <div>
              <table
                id="application-test-configs-table"
                className="table no-margin table-hover"
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
