import React from 'react';
import { DropDownArrowIcon } from '../packs/icons/icons';
import VigilusButton from './button/vigilus-button';

export default class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  itemClicked(index) {
    this.setState({ open: false });
    this.props.onItemClicked && this.props.onItemClicked(index);
  }

  render() {
    return (
      <div
        onClick={() => this.setState({ open: !this.state.open })}
        onBlur={() => this.setState({ open: false })}
      >
        <VigilusButton>
          {this.props.button}
          <DropDownArrowIcon />
        </VigilusButton>

        {this.state.open &&
          <div className="c-dropdown-menu__menu">
            {this.props.children.map((child, index) => {
              return (
                <div key={index} className="c-dropdown-menu__item"
                  onMouseDown={() => {
                    this.setState({ open: false });
                    // console.log("clicked on", index);
                    this.itemClicked(index);
                  }}
                >
                  {child}
                </div>
              );
            })}
          </div>
        }


      </div>
    );
  }
}
