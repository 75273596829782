import React from "react";
import { Pagination } from "./pagination";

export class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOn: this.props.sortOn || 0,
      sortDir: this.props.sortDir || "asc",
      currentPage: this.props.currentPage || 1,
      rows: [],
      emptyElement: null,
    };
  }

  // We use this to make sure that paging is reset when the number of rows changes
  static getDerivedStateFromProps(props, state) {
    let rowElements = [];
    let rows = [];
    let emptyElement = null;

    for (let child of props.children) {
      if (!child) {
        continue;
      }

      if (child.type == TableBody) {
        for (let subchild of child.props.children) {
          if (subchild.type == TableRow) {
            rowElements.push(subchild);
          }
        }
      } else if (child.type == TableRow) {
        rowElements.push(child);
      } else if (Array.isArray(child)) {
        for (let subchild of child) {
          if (subchild.type == TableRow) {
            rowElements.push(subchild);
          }
        }
      } else if (child.type == TableEmpty) {
        emptyElement = child;
      }
    }

    // console.warn("rowElements", rowElements);
    for (let rowElement of rowElements) {
      const cellValues = getCellValuesFromRowElement(rowElement);
      const sortValues = getCellSortValuesFromRowElement(rowElement);

      rows.push({
        element: rowElement,
        cellValues: cellValues,
        sortValues: sortValues
      });
    }

    if (!props.ajax) {
      rows = rows.sort((a, b) => {
        let sortOn = state.sortOn;
        let sortDir = state.sortDir;

        let aValue = a.sortValues[sortOn] || "";
        let bValue = b.sortValues[sortOn] || "";
        if (aValue.toLowerCase) {
          aValue = aValue.toLowerCase();
        }
        if (bValue.toLowerCase) {
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortDir == "asc" ? -1 : 1;
        } else if (aValue > bValue) {
          return sortDir == "asc" ? 1 : -1;
        } else {
          return 0;
        }
      });
    }

    if (state.rows.length != rows.length) {
      return {
        rows: rows,
        emptyElement: emptyElement,
        currentPage: 1,
      };
    } else {
      return {
        rows: rows,
        emptyElement: emptyElement,
      };
    }
  }

  updateSort(sortOn, sortDir) {
    if (this.props.ajax === true) {
      this.props.onSort && this.props.onSort(sortOn, sortDir);
    } else {
      this.setState({sortOn: sortOn, sortDir: sortDir});
    }
  }

  handlePageChange(page) {
    if (this.props.ajax === true) {
      this.props.onPageChange && this.props.onPageChange(page);
    } else {
      this.setState({currentPage: page});
    }
  }

  render() {
    // let rowElements = [];
    // let rows = [];
    let emptyElement = this.state.emptyElement;

    let rows = this.state.rows;

    let pageCount = Math.ceil(rows.length / (this.props.pageSize || 10));
    let currentPage = this.state.currentPage;
    if (this.props.ajax === true) {
      currentPage = this.props.currentPage;
      pageCount = this.props.pageCount;
    } else if (currentPage > pageCount) {
      currentPage = pageCount;
    }

    let pageRows = rows;

    if (!this.props.ajax) {
      let pageStart = (currentPage - 1) * (this.props.pageSize || 10);
      let pageEnd = pageStart + (this.props.pageSize || 10);
      pageRows = rows.slice(pageStart, pageEnd);
    }

    let sortOn = this.state.sortOn;
    let sortDir = this.state.sortDir;
    if (this.props.ajax === true) {
      sortOn = this.props.sortOn;
      sortDir = this.props.sortDir;
    }

    return (
      <div>
        <table className="table no-margin data-table dataTable no-footer rounded-lg">
          {this.props.children.map((child, i) => {
            if (React.isValidElement(child) && child.type != TableRow && child.type != TableEmpty) {
              return React.cloneElement(child, { ...this.state, key: i, sortOn, sortDir, updateSort: this.updateSort.bind(this) });
            }
            return null;
          })}
          <tbody>
            {(() => {
              if (this.props.loading) {
                // Show loading message
                return <TableEmpty>Loading...</TableEmpty>;
              } else if (emptyElement && pageRows.length == 0) {
                // Show passed in empty element
                return React.cloneElement(emptyElement, { ...this.state });
              } else if (!emptyElement && pageRows.length == 0) {
                // Show default empty message if empty element isn't passed in
                return <TableEmpty>None</TableEmpty>;
              } else {
                // Else show rows
                return pageRows.map((row, i) => {
                  return React.cloneElement(row.element, {
                    ...this.state,
                    key: i,
                    updateSort: this.updateSort.bind(this),
                  });
                });
              }
            })()}
          </tbody>
        </table>

        <Pagination currentPage={currentPage} totalPages={pageCount} onPageChange={this.handlePageChange.bind(this)} style={{textAlign: "right"}} />
      </div>
    );
  }
}

function getCellSortValuesFromRowElement(rowElement) {
  let cellValues = [];
  for (let child of rowElement.props.children) {
    if (child.type == TableCell) {
      cellValues.push(getSortValueOfCellElement(child));
    }
  }
  return cellValues;
}

function getCellValuesFromRowElement(rowElement) {
  let cellValues = [];
  for (let child of rowElement.props.children) {
    if (child.type == TableCell) {
      cellValues.push(getValueOfCellElement(child));
    }
  }
  return cellValues;
}

function getSortValueOfCellElement(cellElement) {
  console.log("cellElement", cellElement.props)
  if (typeof cellElement == "string") {
    return cellElement;
  } else if (typeof cellElement == "number") {
    return cellElement;
  } else if (cellElement instanceof Date) {
    return cellElement.toISOString();
  } else if (React.isValidElement(cellElement) && cellElement.props.sortFn) {
    return getSortValueOfCellElement(cellElement.props.sortFn());
  } else if ( // children is an array
    React.isValidElement(cellElement) &&
    Array.isArray(cellElement.props.children)
  ) {
    let childValues = cellElement.props.children.map((child) => {
      return getValueOfCellElement(child);
    });
    return childValues.join(" ");
  } else if (React.isValidElement(cellElement) && cellElement.props.sortFn) {
    console.log("cellElement.props.sortFn", cellElement.props.sortFn())
    return cellElement.props.sortFn();
  } else if (
    React.isValidElement(cellElement) &&
    React.isValidElement(cellElement.props.children)
  ) {
    return getValueOfCellElement(cellElement.props.children);
  } else if (React.isValidElement(cellElement) && cellElement.props.children) {
    return cellElement.props.children;
  } else {
    return cellElement.value;
  }
}

function getValueOfCellElement(cellElement) {
  console.log("cellElement", cellElement.props)
  if (typeof cellElement == "string") {
    return cellElement;
  } else if (typeof cellElement == "number") {
    return cellElement.toString();
  } else if ( // children is an array
    React.isValidElement(cellElement) &&
    Array.isArray(cellElement.props.children)
  ) {
    let childValues = cellElement.props.children.map((child) => {
      return getValueOfCellElement(child);
    });
    return childValues.join(" ");
  } else if (
    React.isValidElement(cellElement) &&
    React.isValidElement(cellElement.props.children)
  ) {
    return getValueOfCellElement(cellElement.props.children);
  } else if (React.isValidElement(cellElement) && cellElement.props.children) {
    return cellElement.props.children;
  } else {
    return cellElement.value;
  }
}

export class TableHead extends React.Component {
  render() {
    let columnIndex = 0;
    let nonChildProps = Object.assign({}, this.props);
    delete nonChildProps.children;

    return (
      <thead>
        <tr className="bg-[#F5F6FB]">
          {this.props.children.map((child, i) => {
            if (React.isValidElement(child) && child.type == TableHeadCell) {
              let element = React.cloneElement(child, {
                key: i,
                index: columnIndex,
                ...nonChildProps,
              });
              columnIndex++;
              return element;
            }
            return child;
          })}
        </tr>
      </thead>
    );
  }
}

export class TableBody extends React.Component {
  render() {
    let nonChildProps = Object.assign({}, this.props);
    delete nonChildProps.children;

    return (
      <tbody>
        {this.props.children.map((child) => {
          if (React.isValidElement(child) && child.type == TableRow) {
            return React.cloneElement(child, { ...nonChildProps });
          }
          return child;
        })}
      </tbody>
    );
  }
}

export class TableRow extends React.Component {
  render() {
    let columnIndex = 0;

    return (
      <tr>
        {this.props.children.map((child, i) => {
          if (React.isValidElement(child) && child.type == TableCell) {
            let element = React.cloneElement(child, {
              key: i,
              index: columnIndex,
            });
            columnIndex++;
            return element;
          }
          return child;
        })}
      </tr>
    );
  }
}

export class TableCell extends React.Component {
  render() {
    return (
      <td style={this.props.style} className={this.props.className}>
        {this.props.children}
      </td>
    );
  }
}

export class TableHeadCell extends React.Component {
  toggleSort() {
    let sortDir = this.props.sortDir;
    if (this.props.sortOn == this.props.index) {
      sortDir = sortDir == "asc" ? "desc" : "asc";
    }
    this.props.updateSort(this.props.index, sortDir);
  }


  render() {
    let className = "sorting";
    if (this.props.sortOn == this.props.index) {
      className = "sorting_" + this.props.sortDir;
    }

    if (this.props.sortable === false) {
      className = "";
    }

    className += " p-4" + " " + this.props.className;

    return (
      <th
        style={{ whiteSpace: "nowrap", ...this.props.style }}
        className={className}
        onClick={() => {
          if (this.props.sortable === false) {
            return;
          }
          this.toggleSort();
        }}
      >
        {this.props.children}
      </th>
    );
  }
}

export class TableEmpty extends React.Component {
  render() {
    return (
      <tr>
        <td
          style={this.props.style}
          className="p-4"
          colSpan="100%"
        >
          {this.props.children}
        </td>
      </tr>
    );
  }
}
