import React, { useState, useEffect } from "react";
import PageLayout from "../../../layouts/page-layout";
import CallFlowTesterConfiguration from "./tabs/configuration/call-flow-tester-configuration";
import CallFlowTesterSchedules from "./tabs/schedules/call-flow-tester-schedules";
import CallFlowTesterTestRuns from "./tabs/test-runs/call-flow-tester-test-runs";
import StatusTag from "../components/status-tag";

const getInitialTabFromUrl = () => {
  const hashValue = location.hash;
  const initialTab = hashValue.startsWith("#")
    ? hashValue.substring(1)
    : "tab_history";
  return initialTab;
};

const CallFlowTesterIndividualTest = ({
  applicationTest,
  backButtonPath,
  lastCompleted,
  testStatus,
  readOnly,
  call_flow_tester_host,
  live_config_enabled,
  autopilot_enabled,
  application_test_schedules,
  application_test_configuration,
  application_test_id,
  timezone,
  allow_per_minute_scheduling,
  readOnlySchedule,
  callFlowTesterOnly
}) => {
  const [activeTab, setActiveTab] = useState(getInitialTabFromUrl());

  const getNewPath = (tab) => {
    if (!tab) return window.location.pathname;
    const newTab = tab;

    // remove query and date range when switching between tabs
    const newUrl = `${window.location.pathname}?search=#${newTab}`;

    return newUrl;
  };

  const handleChangeTab = (tab) => {
    window.history.replaceState({ tab: tab }, "", getNewPath(tab));
    setActiveTab(tab);
  };

  const handleUpdateUrlFromSearchAndDateRange = (updatedUrl) => {
    window.history.replaceState({}, "", updatedUrl + "#" + activeTab);
  };

  useEffect(() => {
    const testElement = document.getElementById("data-table");
    if (testElement) {
      if (activeTab === "tab_history") {
        testElement.classList.remove("hidden");
      } else {
        testElement.classList.add("hidden");
      }
    }
  }, [activeTab]);

  useEffect(() => {
    // just in case a user arrives on page with a query but no #tab
    const hashValue = location.hash;
    if (activeTab && !hashValue) {
      const newUrl = `${location.pathname}${location.search}${activeTab}`;
      window.history.replaceState({}, "", newUrl);
    }
  }, []);

  return (
    <PageLayout
      title={`Test ${applicationTest.id}: ${applicationTest.name}`}
      backButton={{
        path: backButtonPath,
        text: "Back to Application Tests",
      }}
    >
      <div className="flex items-center gap-2 text-gray-500 text-[1.1rem] -mt-4">
        <div>
          <strong>Last test completed at: </strong>
          {lastCompleted}
        </div>
        <StatusTag status={testStatus} />
      </div>
      <div className="bg-white rounded-md flex flex-col gap-4 border border-border">
        <div className="flex items-center gap-2 border-bottom border-border">
          <a
            href="#tab_history"
            data-toggle="tab"
            onClick={() => handleChangeTab("tab_history")}
            className={`text-2xl font-medium px-4 py-4 hover:text-purple ${
              activeTab === "tab_history"
                ? "text-purple"
                : "text-gray-700"
            }`}
            style={{
              borderBottom:
                activeTab === "tab_history"
                  ? "4px solid #6553AD"
                  : "4px solid transparent",
              color: activeTab === "tab_history" && "#6553AD",
            }}
          >
            Test Runs
          </a>

          <a
            href="#tab_configuration"
            data-toggle="tab"
            onClick={() => handleChangeTab("tab_configuration")}
            className={`text-2xl font-medium p-4 hover:text-purple ${
              activeTab === "tab_configuration"
                ? "text-purple"
                : "text-gray-700"
            }`}
            style={{
              borderBottom:
                activeTab === "tab_configuration"
                  ? "4px solid #6553AD"
                  : "4px solid transparent",
              color: activeTab === "tab_configuration" && "#6553AD",
            }}
          >
            Configuration
          </a>

          <a
            href="#tab_schedules"
            data-toggle="tab"
            onClick={() => handleChangeTab("tab_schedules")}
            className={`text-2xl font-medium p-4 hover:text-purple ${
              activeTab === "tab_schedules"
                ? "text-purple"
                : "text-gray-700"
            }`}
            style={{
              borderBottom:
                activeTab === "tab_schedules"
                  ? "4px solid #6553AD"
                  : "4px solid transparent",
              color: activeTab === "tab_schedules" && "#6553AD",
            }}
          >
            Schedules
          </a>
        </div>
        <div>
          {activeTab === "tab_history" && (
            <div className="tab-pane" id="tab_configuration">
              <CallFlowTesterTestRuns
                id={"application_test_runs_list"}
                application_test_id={applicationTest.id}
                readOnly={readOnly}
                live_config_enabled={live_config_enabled}
                autopilot_enabled={autopilot_enabled}
                call_flow_tester_host={call_flow_tester_host}
                timezone={timezone}
                allow_per_minute_scheduling={allow_per_minute_scheduling}
                onUpdateUrl={(updatedUrl) =>
                  handleUpdateUrlFromSearchAndDateRange(updatedUrl)
                }
              />
            </div>
          )}
          {activeTab === "tab_configuration" && (
            <div className="tab-pane" id="tab_configuration">
              <CallFlowTesterConfiguration
                id={"application_test_config_list"}
                application_test_configuration={application_test_configuration}
                application_test_id={applicationTest.id}
                readOnly={readOnly}
                live_config_enabled={live_config_enabled}
                autopilot_enabled={autopilot_enabled}
                call_flow_tester_host={call_flow_tester_host}
                onUpdateUrl={(updatedUrl) =>
                  handleUpdateUrlFromSearchAndDateRange(updatedUrl)
                }
              />
            </div>
          )}
          {activeTab === "tab_schedules" && (
            <div className="tab-pane " id="tab_schedules">
              <CallFlowTesterSchedules
                id="application_test_schedule_list"
                application_test_schedules={application_test_schedules}
                application_test_configuration={application_test_configuration}
                application_test_id={application_test_id}
                timezone={timezone}
                allow_per_minute_scheduling={allow_per_minute_scheduling}
                readonly={readOnlySchedule}
              />
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default CallFlowTesterIndividualTest;
