import React from 'react';
import "moment";
import moment from "moment-timezone";

export default class BillingTransactionsList extends React.Component {

  render() {
    return (
      <div>
        <div style={{ marginBottom: "20px" }}>
          <h4>Recent Transactions</h4>
        </div>

        <div style={{
            marginBottom: "10px",
            marginLeft: "-20px",
            marginRight: "-20px",
            minWidth: "calc(100% + 40px)",
            overflow: "scroll"
          }}>
          {this.props.recent_transactions == null || this.props.recent_transactions.length == 0 ?
            <p>No transactions yet</p>
            :
            <BillingTransactions {...this.props} />
          }
        </div>

      </div>
    );
  }
}

class BillingTransactions extends React.Component {
  render() {
    return (
      <table className="table table-striped" style={{
        marginBottom: "0px"
      }}>
        <thead>
          <tr>
            <th style={{paddingLeft: "20px"}}>Date</th>
            <th>Type</th>
            <th>Method</th>
            <th>Amount</th>
            <th style={{paddingRight: "20px"}}>Minutes</th>
          </tr>
        </thead>
        <tbody>
          {this.props.recent_transactions.map((transaction) => {
            return (
              <tr key={transaction.id}>
                <td style={{paddingLeft: "20px"}}>{moment(transaction.created_at).tz(this.props.timeZone).format("MMM Do, YYYY")}</td>
                <td>{transaction.transaction_type}</td>
                {transaction.payment_method_label ?
                  <td style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px"
                  }}>
                    <span>{transaction.payment_method_label}</span>
                    {/* <span>
                      <img src={transaction.payment_method.icon_path}
                        alt={transaction.payment_method.brand_label}
                        style={{ width: "32px" }}
                      />
                    </span>
                    <span>{transaction.payment_method.payment_details?.last4}</span> */}
                  </td>
                :
                  <td></td>
                }
                <td>${transaction.amount.toFixed(2)}</td>
                <td style={{paddingRight: "20px"}}>{transaction.minutes}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

