import React from 'react'
import ReactDOM from 'react-dom'
import Notification from './notification'

export default class UserControls extends React.Component {
    constructor(props) {
        super(props);

        this.handleSuccessfulUserEdit = this.handleSuccessfulUserEdit.bind(this)
    }

    handleSuccessfulUserEdit () {
        window.showNotification("User updated", "success")
        this.props.updateTables()
    }

    render() {
        return (
            <span>
                <a
                    style={{cursor: "pointer"}}
                    id={`edit-user-${this.props.user.id}`}
                    onClick={() => window.renderForm({
                        type: "User",
                        status: "edit",
                        async: true,
                        action: this.props.action,
                        containerId: `edit-${this.props.user.id}-user-modal`,
                        model: this.props.user,
                        master_admin: this.props.master_admin,
                        isOrgUser: this.props.isOrgUser,
                        successCallback: this.handleSuccessfulUserEdit,
                    }, true)}
                >Edit</a>
                {!this.props.user.master_admin &&
                    <React.Fragment>
                        <span style={{color: "lightgray"}}> | </span>
                        <a
                            style={{cursor: "pointer"}}
                            onClick={() => { window.confirm("Are you sure?") &&
                            this.props.deleteUser()}}>Delete</a>
                    </React.Fragment>}
            </span>
        )
    }
}