import React from "react";
import FormLabel from "../../../form-label/form-label";
import VigilusButton from "../../../button/vigilus-button";

const CallFlowTesterSelfServeSignupForm = ({
  register,
  isNextDisabled,
  onSubmit,
}) => {
  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-4">
      <div className="flex flex-col">
        <FormLabel label="First Name" />
        <input
          type="text"
          name="firstName"
          className="form-control rounded-md"
          required
          {...register("firstName")}
        />
      </div>

      <div className="flex flex-col">
        <FormLabel label="Last Name" />
        <input
          type="text"
          name="lastName"
          className="form-control rounded-md"
          required
          {...register("lastName")}
        />
      </div>

      <div className="flex flex-col">
        <FormLabel label="Email" />
        <input
          type="email"
          name="email"
          className="form-control rounded-md"
          required
          {...register("email")}
        />
      </div>

      <div className="flex flex-col">
        <FormLabel label="Organization Name" />
        <input
          type="text"
          name="orgName"
          className="form-control rounded-md"
          required
          {...register("orgName")}
        />
      </div>

      <VigilusButton
        type="button"
        className="py-3 px-4 border-none"
        onClick={onSubmit}
        disabled={isNextDisabled}
      >
        <span className="w-full text-xl">Next</span>
      </VigilusButton>
    </form>
  );
};
export default CallFlowTesterSelfServeSignupForm;
