import React from "react";
import VigilusButton from "../../../button/vigilus-button";

const CallFlowTesterSelfServeDisclaimer = ({ onSuccess }) => {
  return (
    <form
      onSubmit={onSuccess}
      className="flex flex-col gap-6 items-center w-full text-center"
    >
      <p>
        To start using the Vigilus demo, please enter your credit card
        information on the next page. Your card will not be charged - this is
        just to verify your identity. After completing signup, you'll get $5
        worth of minutes credited to your account. We will never charge your
        card automatically. You can always choose to buy more minutes later if
        you need them.
      </p>

      <VigilusButton
        type="button"
        className="py-3 px-4 border-none w-full"
        onClick={onSuccess}
      >
        <span className="w-full text-xl">Next</span>
      </VigilusButton>
    </form>
  );
};
export default CallFlowTesterSelfServeDisclaimer;
