import React from "react";

export const trunkReportOnlySidebarRoutes = () => {
  const routes = [
    {
      label: "Trunk Monitoring",
      path: "/trunk_monitoring",
      icon: <i className="fas fa-microchip"></i>,
      visible: true,
    },
    {
      label: "Trunk Summary",
      path: "/trunk_summary",
      icon: <i className="fas fa-table"></i>,
      visible: true,
    },
  ];

  return routes;
};
