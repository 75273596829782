import React from "react";
import VigilusButton from "../../components/button/vigilus-button";

export class UploadTestFormModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
    };
    this.dropRef = React.createRef();
    this.uploadFile = this.uploadFile.bind(this);
  }

  handleFileInput(e) {
    e.preventDefault();
    let file = e.target.files[0];
    if (file) {
      this.uploadFile(file);
    }
  }

  uploadFile(file) {
    this.setState({
      processing: true,
    });

    let that = this;
    let formData = new FormData();

    formData.append("file", file, file.name);
    formData.append("upload_file", true);

    $.ajax({
      type: "POST",
      url: this.props.url,
      async: true,
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      timeout: 60000,
      success: function (data) {
        console.log(data);
        that.setState({
          processing: false,
        });
        location.reload();
      },
      error: function (error) {
        console.log(error);
      },
    });
  }

  render() {
    let style = {
      file_input: {
        width: "0.1px",
        height: "0.1px",
        opacity: "0",
        overflow: "hidden",
        position: "absolute",
        zIndex: "-1",
      },
      file_label: {
        cursor: "pointer",
        marginBottom: "0px",
      },
      file_label_span: {
        size: "20%",
        display: "inline-block",
        background: "#00C0EF",
        border: "1px solid #00acd6",
        borderRadius: "3px",
        padding: "6px 8px",
        outline: "none",
        color: "white",
        cursor: "pointer",
        fontWeight: "400",
        fontSize: "9pt",
      },
    };
    return (
      <div>
        <div ref={this.dropRef}>
          <input
            style={style.file_input}
            onInput={this.handleFileInput.bind(this)}
            type="file"
            name="file"
            id="file"
            accept=".json"
          />
          <label htmlFor="file" className="mb-0">
            <span className="cursor-pointer font-medium p-2 text-lg rounded-md border border-button text-primary flex items-center gap-2 text-white bg-button hover:bg-button-600 active:outline active:outline-offset-1 active:outline-2 active:outline-button">
              Upload Test Case
            </span>
          </label>
        </div>
      </div>
    );
  }
}
export default UploadTestFormModal;
