import React from "react";

export const mainSidebarRoutes = ({
  isMasterAdmin,
  testsEnabled,
  organizationUsersPath,
  isProduction,
  featureFlagged,
  loggingEnabled,
  cceConfigLogEnabled,
  hasTrunkMonitoringEnabled,
  cdrLogsEnabled,
  canManageOrganization,
  hasIntegrationenEnabled,
  billingUiFeatureFlag,
  callFlowTesterOnly,
  credentialsUiFeatureFlag,
}) => {
  const routes = [
    {
      label: "Dashboard",
      path: "/",
      icon: <i className="fa fa-columns"></i>,
      visible: true,
    },
    {
      label: "Trunk Monitoring",
      path: "/trunk_monitoring",
      icon: <i className="fas fa-microchip"></i>,
      visible: hasTrunkMonitoringEnabled,
    },
    {
      label: "Users",
      path: organizationUsersPath,
      icon: <i className="fa fa-user"></i>,
      visible: canManageOrganization,
    },
    {
      label: "Applications",
      path: "",
      icon: <i className="fa fa-window-restore"></i>,
      visible: true,
      children: [
        {
          label: "Environments",
          icon: <i className="fa fa-cubes"></i>,
          path: "/application_environments",
          visible: true,
        },
        {
          label: "CCX",
          icon: <i className="fa fa-arrow-right"></i>,
          path: "/installed_applications?application_type=CCX",
          visible: true,
        },
        {
          label: "CCE",
          icon: <i className="fa fa-random"></i>,
          path: "/installed_applications?application_type=CCE",
          visible: true,
        },
        {
          label: "IVR",
          icon: <i className="fa fa-sitemap"></i>,
          path: "/installed_applications?application_type=IVR",
          visible: true,
        },
        {
          label: "CUCM",

          icon: <i className="fa fa-phone"></i>,
          path: "/installed_applications?application_type=CUCM",
          visible: true,
        },
        {
          label: "Expressway",
          icon: <i className="fa fa-arrows-alt-h"></i>,
          path: "/installed_applications?application_type=Expressway",
          visible: true,
        },
        {
          label: "VVB",
          icon: <i className="fa fa-phone-volume"></i>,
          path: "/installed_applications?application_type=VVB",
          visible: true,
        },
        {
          label: "Unity",
          icon: <i className="fa fa-voicemail"></i>,
          path: "/installed_applications?application_type=Unity",
          visible: true,
        },

        {
          label: "CUBE",
          icon: <i className="fa fa-cube"></i>,
          path: "/installed_applications?application_type=CUBE",
          visible: true,
        },
        {
          label: "ISR",
          icon: <i className="fas fa-ethernet"></i>,
          path: "/installed_applications?application_type=ISR",
          visible: true,
        },
      ],
    },
    {
      label: "Tests",
      path: "/application_tests",
      icon: <i className="fa fa-flask"></i>,
      visible: isMasterAdmin || testsEnabled || !isProduction,
    },
    {
      label: "Load Testing",
      path: "",
      icon: <i className="fa fa-rocket"></i>,
      visible: isMasterAdmin || !isProduction,
      children: [
        {
          label: "Campaigns",
          path: "/load/campaigns",
          visible: true,
        },
        {
          label: "Campaign Results",
          path: "/load/campaign_results",
          visible: true,
        },
        {
          label: "Test Cases",
          path: "/load/test_cases",
          visible: true,
        },
        {
          label: "Source Phone Numbers",
          path: "/system_phone_numbers",
          visible: true,
        },
      ],
    },
    {
      label: "Replay",
      path: "/replay/sessions",
      icon: <i className="fa fa-play-circle"></i>,
      visible: isMasterAdmin || !isProduction,
    },
    {
      label: "Inventory",
      path: "#",
      icon: <i className="fa fa-edit"></i>,
      visible: true,
      children: [
        {
          label: "Sites",
          path: "/sites",
          icon: <i className="fa fa-map-marker"></i>,
          visible: true,
        },
        {
          label: "Devices",
          path: "/devices",
          icon: <i className="fa fa-server"></i>,
          visible: true,
        },
        {
          label: "Device Groups",
          path: "/device_groups",
          icon: <i className="fa fa-layer-group"></i>,
          visible: true,
        },
      ],
    },
    {
      label: "Monitoring",
      path: "#",
      icon: <i className="fa fa-eye"></i>,
      visible: true,
      children: [
        {
          label: "Collectors",
          path: "/collection_devices",
          icon: <i className="fa fa-rss"></i>,
          visible: true,
        },
        {
          label: "Credentials",
          path: "/credentials",
          icon: <i className="fa fa-passport"></i>,
          visible: credentialsUiFeatureFlag && canManageOrganization,
        },
        {
          label: "Device Queries",
          path: "/device_metrics",
          icon: <i className="fa fa-chart-line"></i>,
          visible: canManageOrganization,
        },
        {
          label: "Metrics",
          path: "/metrics",
          icon: <i className="fa fa-question"></i>,
          visible: isMasterAdmin,
        },
        {
          label: "System Event Rules",
          path: "/event_transform_rules",
          icon: <i className="fa fa-project-diagram"></i>,
          visible: isMasterAdmin,
        },
        {
          label: "Event Rules",
          path: "/organization_event_rules",
          icon: <i className="fa fa-project-diagram"></i>,
          visible: featureFlagged && canManageOrganization,
        },
        {
          label: "Notifications",
          path: "/notification_settings",
          icon: <i className="fa fa-bell"></i>,
          visible: featureFlagged && canManageOrganization,
        },
        {
          label: "Maintenance Mode",
          path: "/alert_suppressions",
          icon: <i className="fa fa-pause-circle"></i>,
          visible: canManageOrganization,
        },
        {
          label: "Thresholds",
          path: "/alarm_thresholds",
          icon: <i className="fas fa-exclamation-circle"></i>,
          visible: canManageOrganization,
        },
      ],
    },
    {
      label: "Activity",
      path: "/events",
      icon: <i className="fa fa-exclamation-circle"></i>,
      visible: true,
    },
    {
      label: "Reports",
      path: "/reports",
      icon: <i className="fa fa-chart-bar"></i>,
      visible: true,
    },
    {
      label: "Logs",
      path: "",
      icon: <i className="fa fa-file-alt"></i>,
      visible:
        loggingEnabled || cceConfigLogEnabled || hasTrunkMonitoringEnabled,
      children: [
        {
          label: "Search",
          path: "/logs",
          icon: <i className="fa fa-search"></i>,
          visible: loggingEnabled,
        },
        {
          label: "Config Log",
          path: "/config_log_events",
          icon: <i className="fa fa-exchange-alt"></i>,
          visible: cceConfigLogEnabled,
        },
        {
          label: "CDR/CMR",
          path: "/cdr_logs",
          icon: <i className="fa fa-phone"></i>,
          visible: cdrLogsEnabled,
        },
        {
          label: "Scheduled Export",
          path: "/scheduled_bulk_searches",
          icon: <i className="fas fa-clock"></i>,
          visible: cdrLogsEnabled,
        },
        {
          label: "Exports",
          path: "/exports",
          icon: <i className="fas fa-cloud-upload-alt"></i>,
          visible: cdrLogsEnabled,
        },
        {
          label: "Trunk Summary",
          path: "/trunk_summary",
          icon: <i className="fas fa-table"></i>,
          visible: hasTrunkMonitoringEnabled,
        },
      ],
    },
    {
      label: "Integrations",
      path: "/service_now",
      icon: <i className="fas fa-plug"></i>,
      visible: isMasterAdmin && hasIntegrationenEnabled,
      children: [
        {
          label: "ServiceNow",
          path: "/service_now",
          icon: <i className="fas fa-snowflake"></i>,
          visible: true,
        },
      ],
    },
    {
      label: "Billing",
      path: "/billing/subscriptions",
      icon: <i className="fa fa-credit-card"></i>,
      visible: isMasterAdmin || billingUiFeatureFlag,
    },

    {
      label: "Settings",
      path: "/organization_settings",
      icon: <i className="fa fa-cogs"></i>,
      visible: isMasterAdmin,
    },
    {
      label: "Documentation",
      path: "/documentation",
      icon: <i className="fas fa-book-open"></i>,
      visible: !callFlowTesterOnly,
    },
  ];

  return routes;
};
