import React, { useState } from "react";
import ForgotPasswordForm from "./forgot-password-form";
import ForgotPasswordSuccess from "./forgot-password-success";
import LoginFlowWrapper from "../components/login-flow-wrapper";

const ForgotPasswordContainer = ({ authenticity_token }) => {
  const [view, setView] = useState("initial"); // initial || success || error

  const handleSuccessfulReset = () => {
    setView("success");
  };

  const handleResetView = () => {
    setView("initial");
  };

  return (
    <LoginFlowWrapper>
      {view === "initial" && (
        <ForgotPasswordForm
          authenticity_token={authenticity_token}
          onSuccess={handleSuccessfulReset}
        />
      )}
      {view === "success" && (
        <ForgotPasswordSuccess onResend={handleResetView} />
      )}
    </LoginFlowWrapper>
  );
};
export default ForgotPasswordContainer;
