import React from 'react';
import GenericChart from './generic_chart.jsx';

class ChartWithSummary extends React.Component{

  render(){
    return(
              <div className="row">
                <div className="col-sm-9">
                  <GenericChart {...this.props} />
                </div>
                <div className="col-sm-3">
                  <div className="description-block margin-bottom">
                    <div className="box-pane-right bg-blue">
                      <i className="ion ion-arrow-up-a" style={{fontSize: '15px', marginTop: '20px', marginBottom: '20px', marginRight: '8px'}}></i>
                      <span className="description-header" style={{marginRight: '4px'}}>
                        Max: 
                      </span>
                      <span className="description-text">
                        {this.props.stats.max} {this.props.unit}
                      </span>
                      <hr style={{margin: '0px 0px'}}/>
                      <i className="ion ion-arrow-swap" style={{fontSize: '15px', marginTop: '20px', marginBottom: '20px', marginRight: '8px'}}></i>
                      <span className="description-header" style={{marginRight: '4px'}}>
                        Avg: 
                      </span>
                      <span className="description-text">
                        {this.props.stats.avg} {this.props.unit}
                      </span>
                      <hr style={{margin: '0px 0px'}}/>
                      <i className="ion ion-arrow-down-a" style={{fontSize: '15px', marginTop: '20px', marginBottom: '20px', marginRight: '8px'}}></i>
                      <span className="description-header" style={{marginRight: '4px'}}>
                        Min: 
                      </span>
                      <span className="description-text">
                        {this.props.stats.min} {this.props.unit}
                      </span>
                      
                    </div>
                  </div>
                </div>
              </div>
    )
  }
}

export default ChartWithSummary
