import React from 'react'
import { FormContext } from './form_modal_button';
import Select from 'react-select';
import 'daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-daterangepicker-cloverhound';
import "moment";
import moment from "moment-timezone";
import AddPaymentMethodForm from './billing_payment_method_form';
import "./billing_subscriptions_form_modal.scss";


export default class PurchaseMinutesFormModal extends React.Component {
  static contextType = FormContext;

  constructor(props) {
    super(props);
    this.state = {
      paymentMethods: this.props.payment_methods || [],
      paymentMethodId: this.props.payment_methods?.length > 0 && this.props.payment_methods[0].id || 0,
      minutes: 1000,
    }
  }

  componentDidMount() {
    this.toggleForm();
  }

  validateForm() {
    if (!this.state.paymentMethodId || this.state.paymentMethodId == 0) {
      return false;
    }

    if (!this.state.minutes) {
      return false;
    }

    if (this.state.minutes < 5) {
      return false;
    }

    return true;
  }

  toggleForm() {
    if (this.validateForm()) {
      this.context && this.context.enable();
    } else {
      this.context && this.context.disable();
    }
    if (this.state.paymentMethodId == 0) {
      this.context && this.context.changeSize("medium");
    } else {
      this.context && this.context.changeSize("small");
    }
  }

  paymentMethodAdded(paymentMethod) {
    if (paymentMethod.error) {
      return;
    }

    this.setState({
      paymentMethods: [...this.state.paymentMethods, paymentMethod],
      paymentMethodId: paymentMethod.id
    }, () => {
      this.toggleForm();
    });
  }

  render() {

    let pricePerMinute = this.props.voice_pstn_plan_product.price + this.props.transcription_plan_product.price;

    return (
      <div>
        <label htmlFor="minutes">Minutes</label>
        <input type="number"
              name="minutes"
              required={true}
              min="1"
              className="form-control"
              value={this.state.minutes}
              onChange={(event) => {
                this.setState({
                  minutes: Number(event.target.value)
                }, () => {
                  this.toggleForm();
                });
              }}
        />

        <br/>

        <React.Fragment>
          <label htmlFor="payment_method_id">Payment Method</label>
          <select name="payment_method_id" className="form-control"
                  value={this.state.paymentMethodId}
                  onChange={(event) => {
                    this.setState({
                      paymentMethodId: event.target.value
                    }, () => {
                      this.toggleForm();
                    });
                  }}
          >
            {this.state.paymentMethods.map((paymentMethod, index) => {
              return (
                <option key={paymentMethod.id} value={paymentMethod.id}>{paymentMethod.brand_label} ···· ···· ···· {paymentMethod.payment_details.last4}</option>
              );
            })}
            <option key={0} value={0}>Add Payment Method</option>
          </select>

          <br/>
        </React.Fragment>

        {this.state.paymentMethodId == 0 &&
          <AddPaymentMethodForm {...this.props} onAdded={this.paymentMethodAdded.bind(this)} />
        }

        <div>
          <span>Price per minute: </span>
          <span>${pricePerMinute.toFixed(2)}</span>
        </div>

        <div style={{fontSize: "2rem"}}>
          <strong>Total: </strong>
          <span>${(pricePerMinute * this.state.minutes).toFixed(2)}</span>
        </div>

        {this.props.payment_methods.length == 0 &&
          <div>
            <br/>

            <a href={`/billing/accounts/checkout`}
              data-method="post"
              rel="nofollow"
              style={{ cursor: "pointer" }}
            >
              Add Payment Method
            </a>
          </div>
        }

      </div>
    );

  }

}

